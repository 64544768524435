import { useTranslation } from "react-i18next";
import { Button, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

export function BrowserUnsupported(): React.JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid container direction={"column"} spacing={2} padding={2} alignItems={"center"}>
      <Grid item>
        <Typography variant={"h4"}>{t("browser.notSupported.text")}</Typography>
      </Grid>
      <Grid item>
        <Typography>{t("browser.notSupported.recommend.text")}</Typography>
      </Grid>
      <Grid container item spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <Button variant={"outlined"} href={"https://www.google.com/chrome/browser/"}>
            Chrome
          </Button>
        </Grid>
        <Grid item>
          <Button variant={"outlined"} href={"https://www.mozilla.org/firefox/new"}>
            Firefox
          </Button>
        </Grid>
      </Grid>
      <Grid container item spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <Button variant={"outlined"} href={"https://www.apple.com/safari/"}>
            Safari
          </Button>
        </Grid>
        <Grid item>
          <Button variant={"outlined"} href={"https://www.microsoft.com/en-us/windows/microsoft-edge"}>
            Edge
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>{t("browser.notSupported.select.text")}</Typography>
      </Grid>
    </Grid>
  );
}
