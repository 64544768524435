import React from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../common/utilities/GeneralHelpers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";

export function Payment(): React.JSX.Element {
  const { t } = useTranslation();
  const failureMessage = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.payment?.transactions?.[0].failureMessage);
  const paymentError = useSelector((state: ApplicationState) => state.olb?.paymentError);
  const firstSource = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.sources?.[0]);
  const deposit = firstSource?.scheduledEventsProposed?.resourceEvents?.[0].salesInformation?.deposit;
  const price = firstSource?.scheduledEventsProposed?.resourceEvents?.[0].salesInformation?.price;
  const currency = deposit?.currency;
  const amount = deposit?.amount;
  const priceAmount = price?.amount;
  const balanceAmount = priceAmount - amount;
  const errorInternal = failureMessage || paymentError;
  return (
    <Grid container direction={"column"} spacing={2} padding={2}>
      {errorInternal && (
        <Grid item>
          <Alert severity="error">{errorInternal}</Alert>
        </Grid>
      )}
      <Grid item>
        <Typography variant={"h6"}>{t("booking.payment.depositRequired.label")}</Typography>
      </Grid>
      <Grid item>
        <Typography component={"p"}>
          {t("booking.payment.amount.label")}
          {": "}
          <Box sx={{ fontWeight: "bold" }} component={"span"}>
            {formatCurrency(amount, currency)}
          </Box>
        </Typography>
      </Grid>
      {priceAmount && (
        <Grid item>
          <Typography component={"p"}>{t("booking.payment.deposit.balance.label", { balance: formatCurrency(balanceAmount, currency) })}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
