import { delay, isNil } from "lodash-es";
import { doPerspectiveCall } from "../../common/state/CommonActions";
import { _findCardWithType } from "../../common/state/CommonReducerHelpers";

const stepIdsToWatch = ["45", "13", "35", "42", "46", "47"];
const interval = 4000;
const maxNumberOfTries = 45;

class StepWatcher {
  numberOfTries: number;
  runner?: number;
  setIsWatching;

  constructor() {
    this.numberOfTries = 0;
    this.runner = undefined;
  }

  startWatchingStepId = function (this: StepWatcher, currentStepId: string, setIsWatching) {
    if (stepIdsToWatch.indexOf(currentStepId) >= 0) {
      this.setIsWatching = setIsWatching;
      this.numberOfTries = 0;
      this._stopRunner(this.runner);
      const promise = new Promise((resolve, reject) => {
        this.setIsWatching(true);
        this._checkCurrentStep(currentStepId, resolve, reject);
      });
      promise.then(
        () => {
          return true;
        },
        () => {
          this.setIsWatching(false);
        },
      );
      return promise;
    } else {
      this._stopRunner(this.runner);
      return Promise.reject();
    }
  };

  _checkCurrentStep = function (this: StepWatcher, currentStepId: string, resolve, reject) {
    if (this.numberOfTries <= maxNumberOfTries) {
      this.runner = delay(() => {
        this._callPerspective().then(
          (stepId) => {
            if (stepId) {
              if (currentStepId !== stepId) {
                resolve();
              } else {
                this.numberOfTries++;
                this._checkCurrentStep(currentStepId, resolve, reject);
              }
            } else {
              this.numberOfTries++;
              this._checkCurrentStep(currentStepId, resolve, reject);
            }
          },
          () => {
            this.numberOfTries++;
            this._checkCurrentStep(currentStepId, resolve, reject);
          },
        );
      }, interval);
    } else {
      reject();
    }
  };

  _callPerspective = function (this: StepWatcher) {
    return doPerspectiveCall("3").then(
      (perspective) => {
        if (!isNil(perspective)) {
          // @ts-ignore
          const stepCard = _findCardWithType(perspective.stacks, "com_threepointdata_bosv31_workflow_instance_Step");
          if (!isNil(stepCard)) {
            return stepCard.identifier;
          }
        }
      },
      () => {
        return null;
      },
    );
  };

  _stopRunner = function (runnerObj) {
    if (runnerObj) {
      clearTimeout(runnerObj);
      runnerObj = null;
    }
  };
}

export default new StepWatcher();
