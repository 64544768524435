import { ApplicationState } from "../../common/state/model/ApplicationState";
import { getUseDateFromLink } from "./OlbSelectors";
import { isNil } from "lodash-es";
import { loadEvents, setIsLoadingEvents, unloadEvents, updateStartTime, updateStartTimeForShapeChange } from "./OlbActions";

export function loadEventsForShapeChangeRecallTreatmentPlan() {
  return async (dispatch, getState: () => ApplicationState) => {
    const appointmentDocument = getState().olb?.appointmentData?.appointmentDocument;
    const useDateFromLink = getUseDateFromLink(getState());
    const appointmentStartDate = getState().olb?.eventSelectionData?.startDate;

    dispatch(setIsLoadingEvents(true));
    const appointmentStartTime = appointmentDocument?.startTime;

    // Honor startDate if specified
    let startTime = isNil(appointmentStartTime) ? new Date() : appointmentStartTime;
    if (useDateFromLink && !isNil(appointmentStartDate)) {
      const now = new Date();
      startTime = appointmentStartDate.getTime() < now.getTime() ? now : appointmentStartDate;
    }

    dispatch(updateStartTime(startTime));
    dispatch(unloadEvents());

    await dispatch(loadEventsForNextRangeRecallTreatmentPlan());
    return await dispatch(updateStartTimeForShapeChange());
  };
}

export function loadEventsForNextRangeRecallTreatmentPlan() {
  return async (dispatch, getState: () => ApplicationState) => {
    const eventRequest = getState().olb?.eventRequest;
    const patientDocuments = getState().olb?.patientInfoData?.patientDocuments;
    const appointmentDocument = getState().olb?.appointmentData?.appointmentDocument;

    if (isNil(eventRequest) || isNil(patientDocuments) || isNil(appointmentDocument)) {
      return Promise.resolve([]);
    }

    dispatch(setIsLoadingEvents(true));
    const params = {
      patientType: eventRequest.patientType,
      practiceId: eventRequest.practiceId,
      firstEventForProvider: false,
    };

    const properties = appointmentDocument?.properties;
    let index = 1;
    while (properties[`ap${index}`] !== undefined) {
      const ap = properties[`ap${index}`];
      const apProps = ap.split("|");
      const patient = patientDocuments.find((patientDocument) => patientDocument.externalPatientId === apProps[0]);
      const internalPatientId = patient?.internalPatientId;
      params[`ap${index}`] = `${internalPatientId}|${ap}`;
      index++;
    }

    return dispatch(loadEvents(params)).then(
      () => {
        dispatch(setIsLoadingEvents(false));
      },
      () => {
        dispatch(unloadEvents());
        dispatch(setIsLoadingEvents(false));
        //TODO: JS send the error to a logging service);
      },
    );
  };
}
