const STARTS_WITH = "DEMO";
const URL_PARAM = "d";
const URL_PARAM_VALUE = "true";

export default function isCMSActive() {
  let result = false;
  const params = convertURLParamsToObject(window.location.search);
  if (params.get(URL_PARAM) === URL_PARAM_VALUE) {
    if ((window as any).ThreePointData.practiceId) {
        result = (window as any).ThreePointData.practiceId.startsWith( STARTS_WITH);
    }
  }
  return result;
}

function convertURLParamsToObject(url) {
  const params = url
    .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
    .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
    .map(x => x.split("=")); // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
    return new Map(params.map((param) => {return [param[0], param[1]]})); // {"a" => "b454","c" => "dhjjh","f" => "g6hksdfjlksd"}
}
