import { Countries } from "./Countries";

export const TimezoneCountryCodeMap = {
  "Pacific/Fiji": Countries[0],
  "Europe/Paris": Countries[13],
  "Europe/Dublin": Countries[10],
  "Europe/London": Countries[6],
  "Europe/Amsterdam": Countries[11],
  "Pacific/Auckland": Countries[1],
  "Australia/Perth": Countries[3],
  "Australia/Darwin": Countries[3],
  "Australia/Adelaide": Countries[3],
  "Australia/Melbourne": Countries[3],
  "Australia/Brisbane": Countries[3],
  "Australia/Hobart": Countries[3],
  "Australia/Eucla": Countries[3],
  "Etc/UTC": Countries[6],
  "America/Denver": Countries[12],
  "Asia/Singapore": Countries[42],
  "Asia/Hong_Kong": Countries[43],
};
