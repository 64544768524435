export default class HenrySchein {
  showMedicalInformation() {
    const code: string = (window as any).ThreePointData?.countryCode;
    if ((window as any).ThreePointData?.countryCode) {
      return code !== "US";
    }
    return false;
  }

  showMedicalInformationForTreatmentPlan(allowHealthInformationOnTreatment: boolean = false) {
    return this.showMedicalInformation() && allowHealthInformationOnTreatment;
  }
}
