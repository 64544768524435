import React from "react";
import { Grid } from "@mui/material";
import { AppointmentCards } from "./AppointmentCards";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { PatientInformationLabelIfNeeded } from "./PatientInformationLabelIfNeeded";

export function EventList({
  appointmentDocument,
  showMedicalInformation,
  showMarketingConsent = false,
  showTermsOfUse = false,
}: {
  appointmentDocument: any;
  showMedicalInformation: boolean;
  showMarketingConsent?: boolean;
  showTermsOfUse?: boolean;
}): React.JSX.Element {
  return (
    <Grid item xs={12}>
      {appointmentDocument && (
        <>
          <PatientInformationLabelIfNeeded />
          <AppointmentCards
            showMedicalInformation={showMedicalInformation}
            showAddToCalendar={false}
            appointmentNotConfirmed={false}
            readonly={false}
            showMarketingConsent={showMarketingConsent}
            showTermsOfUse={showTermsOfUse}
          />
        </>
      )}
    </Grid>
  );
}
