import ProductConfiguration from "./model/ProductConfiguration";
import EventRequest from "./model/EventRequest";
import ProviderData from "../../common/state/model/ProviderData";
import Reason from "./model/Reason";
import StepData from "./model/StepData";
import AppointmentData from "./model/AppointmentData";
import PatientInfoData from "./model/PatientInfoData";
import CancellationPolicyData from "./model/CancellationPolicyData";
import ComplaintsPolicyData from "./model/ComplaintsPolicyData";
import TermsOfUseData from "./model/TermsOfUseData";
import NhsFeeData from "./model/NhsFeeData";
import EventSelectionData from "./model/EventSelectionData";
import PageData from "./model/PageData";
import MarketingConsentData from "./model/MarketingConsentData";
import EventData from "./model/EventData";

export enum ContentType {
  PlainText = "PLAIN_TEXT",
  Markdown = "MARKDOWN",
}

export default interface OlbState {
  productConfiguration?: ProductConfiguration;
  providerData?: ProviderData;
  stepData?: StepData;
  termsOfUseData?: TermsOfUseData;
  appointmentData?: AppointmentData;
  patientInfoData?: PatientInfoData;
  cancellationPolicyData?: CancellationPolicyData;
  complaintsPolicyData?: ComplaintsPolicyData;
  marketingConsentData?: MarketingConsentData;
  pageData?: PageData;
  eventRequest?: EventRequest;
  nhsFeeData?: NhsFeeData;
  reasons?: Reason[];
  eventSelectionData?: EventSelectionData;
  eventData: EventData;
  paymentError?: string;
}
