import React from "react";
import { SelectATimeForRecallTreatmentPlan } from "../common/SelectATimeForRecallTreatmentPlan";
import { BookingCard } from "../common/BookingCard";

export const Step34: React.FunctionComponent<any> = () => {
  return (
    <BookingCard>
      <SelectATimeForRecallTreatmentPlan />
    </BookingCard>
  );
};
