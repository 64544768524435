import { Reducer } from "react";
import Action from "../models/Action";
import { CommonActionTypes } from "./CommonActionTypes";
import SessionData from "./model/SessionData";
import { _findCardWithType } from "./CommonReducerHelpers";
import { forEach, isEmpty, isNil, set } from "lodash-es";
import { SoAdminActionTypes } from "../../soadmin/state/SoAdminActionTypes";
import SessionState from "./model/SessionState";
import { TimezoneCountryCodeMap } from "../../onlinebooking/constants/TimezoneCountryCodeMap";

// Initialize countryCode
if ((window as any).ThreePointData.countryCode === undefined || (window as any).ThreePointData.countryCode === null) {
  const countryCode = TimezoneCountryCodeMap[(window as any).ThreePointData.timezone];
  if (countryCode) {
    (window as any).ThreePointData.countryCode = countryCode.code;
  } else {
    (window as any).ThreePointData.countryCode = "GB";
  }
}

export const sessionReducer: Reducer<SessionState, Action> = (
  state: SessionState = { timezone: (window as any).ThreePointData.timeZone, sessionData: {} },
  action: Action = {} as Action,
): SessionState => {
  const result: SessionState = { ...state };
  switch (action.type) {
    case CommonActionTypes.RECEIVE_PERSPECTIVE: {
      result.sessionData = _reduceSession(action.state.stacks);
      break;
    }
    case SoAdminActionTypes.UPDATE_SESSION: {
      result.sessionData = { ...result.sessionData };
      result.sessionData = isNil(result.sessionData) ? {} : result.sessionData;

      forEach(action.params, (value, key) => {
        set(result.sessionData, key, value);
        if ("password" === key || "username" === key) {
          _populateActionWithValue(result.sessionData.iEntityCard?.actions, key, value);
        }
      });
      break;
    }
  }
  return result;
};

function _reduceSession(stacks): SessionData {
  const result: SessionData = {
    iEntityCard: null,
    entityUserCard: null,
    isAuthenticated: false,
  };
  result.iEntityCard = _findCardWithType(stacks, "com_threepointdata_bosv31_entity_IEntity");
  result.entityUserCard = _findCardWithType(stacks, "com_threepointdata_dashboard_data_User");
  if (!isNil(result.entityUserCard)) {
    result.isAuthenticated = true;
  }

  return result;
}

function _populateActionWithValue(actions, field, value) {
  if (!isEmpty(actions)) {
    let actionValueObject = actions[0].requirementsMet;
    if (isEmpty(actionValueObject)) {
      actionValueObject = {};
    }
    actionValueObject[field] = value;
    set(actions[0], "requirementsMet", actionValueObject);
  }
}
