import React from "react";
import { AppointmentCards } from "../../AppointmentCards";
import { CancellationComplaints } from "../../CancellationComplaints";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { AppointmentCardStatePrimary } from "../../AppointmentCardStatePrimary";
import { Box } from "@mui/material";
import { isCallCenterActive } from "../../../../common/utilities/CallCenter";

export function BookAppointment({
  currentStepId,
  showMedicalInformation = false,
}: {
  currentStepId: string;
  showMedicalInformation?: boolean;
}): React.JSX.Element {
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const callCenterActive = isCallCenterActive();
  return (
    <Box>
      <AppointmentCardStatePrimary currentStepId={currentStepId} />
      {appointmentDocument && (
        <AppointmentCards
          showAddToCalendar={!callCenterActive}
          showMedicalInformation={showMedicalInformation}
          appointmentNotConfirmed={true}
          readonly={true}
        />
      )}
      <CancellationComplaints />
    </Box>
  );
}
