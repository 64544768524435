import { capitalize, isEmpty, isNil, merge, startsWith } from "lodash-es";
import { removeFromDom } from "./utils";

class MultipleGoogleTagManager {
  dataLayer = "";

  init(config: any = {}) {
    const { providers } = config;
    const initDataLayer = [
      {
        "gtm.whitelist": ["google", "hjtc", "flc", "fls", "asp"],
      },
    ];
    const dataLayer = !isNil(config.datalayer) ? config.dataLayer : "dataLayer";

    (window as any).dataLayer = initDataLayer;
    this.dataLayer = dataLayer;

    if (!isEmpty(providers)) {
      providers.forEach((trackerId) => {
        if (startsWith(trackerId, "GTM")) {
          /* jshint ignore:start */
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s);
            const dl = l != "dataLayer" ? "&l=" + l : "";
            (j as HTMLScriptElement).async = true;
            (j as HTMLScriptElement).src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            if (!isNil(f.parentNode)) {
              f.parentNode.insertBefore(j, f);
            }
          })(window, document, "script", this.dataLayer, trackerId);
          /* jshint ignore:end */
        }
      });
    }
  }

  trackEvent(this: MultipleGoogleTagManager, options: any = {}) {
    const compactedOptions = options || {};
    const gtmEvent = { event: compactedOptions["event"] || "event" };

    delete compactedOptions["event"];

    for (const key in compactedOptions) {
      if (gtmEvent.event === "gtm.pageError") {
        gtmEvent[`gtm.${key}`] = compactedOptions[key];
      } else {
        const capitalizedKey = capitalize(key);
        gtmEvent[`event${capitalizedKey}`] = compactedOptions[key];
      }
    }

    if (!isNil((window as any).dataLayer)) {
      (window as any).dataLayer.push(gtmEvent);
    }

    return gtmEvent;
  }

  trackPage(this: MultipleGoogleTagManager, options: any = {}) {
    const compactedOptions = options  || {};
    const sendEvent = {
      event: compactedOptions["event"] || "pageview",
    };

    const pageEvent = merge(sendEvent, compactedOptions);

    if (!isNil((window as any).dataLayer)) {
      (window as any).dataLayer.push(pageEvent);
    }

    return pageEvent;
  }

  willDestroy(this: MultipleGoogleTagManager) {
    removeFromDom(`script[src*="gtm.js"]`);
    if (!isNil((window as any).dataLayer)) {
      delete (window as any).dataLayer;
    }
  }
}

export default MultipleGoogleTagManager;
