import React from "react";

export function GoogleMap({
  address1,
  address2,
  address3,
  postcode,
}: {
  address1: string;
  address2: string;
  address3: string;
  postcode: string;
}): React.JSX.Element {
  let q = "";
  if (address1) {
    q += `${address1} `;
  }
  if (address2) {
    q += `${address2} `;
  }
  if (address3) {
    q += `${address3} `;
  }
  if (postcode) {
    q += `${postcode}`;
  }
  const output = "output=embed";
  const path = "https://maps.google.com/maps" + `?q=${encodeURIComponent(q)}&${output}`;

  return <iframe src={path}></iframe>;
}
