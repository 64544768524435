import React from "react";
import { Card, CardContent, CardHeader, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function MinisiteCard(props) {
  const theme = useTheme();
  return (
    <Card sx={{ margin: 3 }}>
      <CardHeader
        sx={{
          bgcolor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          border: "1px solid #e3e3e3",
          fontSize: 10,
        }}
        title={props.cardHeader}
      />
      <CardContent>{React.Children.toArray(props.children)}</CardContent>
      {props.isSaving && <LinearProgress />}
    </Card>
  );
}
