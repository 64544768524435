import { isEmpty } from "lodash-es";

export const professionalTitleAndQualifications = (provider: any) => {
  let descriptions: string[] = [provider?.professionalTitle, provider?.qualifications];
  descriptions = descriptions.filter(Boolean);
  return descriptions.join(" - ");
};

export const professionalTitleWithDefault = (provider: any) => {
  const defaultStr = "&nbsp;";
  const str = professionalTitleAndQualifications(provider);
  return isEmpty(str) ? str : defaultStr;
};

export const providerImageURLWithDefault = (provider) => {
  return !isEmpty(provider?.providerImageURL) ? provider.providerImageURL : "";
};
