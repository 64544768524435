import React, { useEffect, useState } from "react";
import { faGear, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stepWatcher from "../utilities/StepWatcher";
import { reload } from "../../common/state/CommonActions";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { PatientValidationChannels } from "./PatientValidationChannels";

export function PatientValidation({ currentStepId }: { currentStepId: string }): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isWatching, setIsWatching] = useState(true);
  // Only want this to run once.  Not sure how to satisfy "React Hook useEffect has missing dependencies: 'currentStepId' and 'dispatch'. Either include them or remove the dependency array"
  useEffect(() => {
    stepWatcher.startWatchingStepId(currentStepId, setIsWatching).then(() => {
      dispatch(reload("3"));
    });
  }, [dispatch, currentStepId]);
  return (
    <>
      <Grid container spacing={1} className={"margin-left-10"}>
        <Grid item>
          <FontAwesomeIcon icon={isWatching ? faGear : faRefresh} size={"1x"} spin={isWatching} pulse={!isWatching} />
        </Grid>
        <Grid item>{t("booking.verifyCode.wait.label")}</Grid>
        <Grid item xs={12}>
          <PatientValidationChannels alwaysShowChannels={true}></PatientValidationChannels>
        </Grid>
      </Grid>
    </>
  );
}
