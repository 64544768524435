import { Avatar, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { providerImageURLWithDefault } from "../../../common/utilities/ProviderHelpers";

export const ProviderImageInformation = ({
  primaryProvider,
  secondaryProvider,
  combinedAppointment,
}: {
  primaryProvider: any;
  secondaryProvider: any;
  combinedAppointment: boolean;
}) => {
  const showProviderImage: boolean | undefined = useSelector((state: ApplicationState) => state.common?.config?.showProviderImage);

  const primaryProviderProfileUrl: string | undefined = useMemo(() => providerImageURLWithDefault(primaryProvider), [primaryProvider]);
  const secondaryProviderProfileUrl: string | undefined = useMemo(() => providerImageURLWithDefault(secondaryProvider), [secondaryProvider]);

  if (showProviderImage) {
    return (
      <Grid container item direction={"row"} width={"auto"} data-testid={"t-appointmentsummary-providerimage"}>
        <Avatar src={primaryProviderProfileUrl} alt={primaryProvider.name} sx={{ width: 56, height: 56 }}>
          {primaryProvider?.name?.charAt(0)}
        </Avatar>

        {combinedAppointment && (
          <Avatar src={secondaryProviderProfileUrl} alt={secondaryProvider.name} sx={{ width: 56, height: 56, ml: 1 }}>
            {secondaryProvider?.name?.charAt(0)}
          </Avatar>
        )}
      </Grid>
    );
  } else {
    return <></>;
  }
};
