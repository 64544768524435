export const APPOINTMENT_SETTINGS = "appointmentSettings";
export const PAYMENT_CONFIGURATOR = "paymentConfigurator";
export const SERVICE_PLAN = "servicePlan";
export const PAGE = "page";
export const Mode = {
  CODE_VERIFICATION: 0,
  DEPOSIT: 1,
  DEPOSIT_PROCESSING: 2,
};

export const AppointmentType = {
  RECALL: "Recall",
  PLANNED: "Planned",
  NEW_PATIENT: "NewPatient",
  EXISTING_PATIENT: "ExistingPatient",
};

export const iso5218Sex = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  NOT_APPLICABLE: "NOT_APPLICABLE",
  NOT_KNOWN: "NOT_KNOWN",
};
