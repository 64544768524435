import React, { useState } from "react";
import { SoAdminCard } from "./SoAdminCard";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { saveSession, updateSession } from "../state/SoAdminActions";
import { useErrorHandler } from "react-error-boundary";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { isEmpty } from "lodash-es";

export function SignIn(): React.JSX.Element {
  const handleError = useErrorHandler();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [signInFailed, setSignInFailed] = useState(false);
  const username = useSelector((state: ApplicationState) => state.session?.sessionData?.username);
  const password = useSelector((state: ApplicationState) => state.session?.sessionData?.password);
  const disableSubmit = isSaving || isEmpty(username) || isEmpty(password);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleUsernameChanged = (event) => {
    dispatch(updateSession({ username: event.target.value }));
  };

  const handlePasswordChanged = (event) => {
    dispatch(updateSession({ password: event.target.value }));
  };

  const handleSignin = async () => {
    setIsSaving(true);
    setSignInFailed(false);
    await dispatch(saveSession()).catch((error) => {
      setIsSaving(false);
      setSignInFailed(true);
    });
    setIsSaving(false);
  };

  return (
    <SoAdminCard header={"Sign in"} isLoading={isSaving}>
      <>
        {signInFailed && <Alert severity="error">Either your Username or Password is incorrect.</Alert>}
        <Grid container direction={"column"} spacing={2} padding={2} width={{ xs: 350, sm: 500 }}>
          <Grid item>
            <TextField label={"Username"} sx={{ width: "100%" }} autoFocus onChange={handleUsernameChanged} />
          </Grid>
          <Grid item>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={handlePasswordChanged}
                endAdornment={
                  <InputAdornment position={"end"}>
                    <IconButton edge={"end"} onClick={handleClickShowPassword}>
                      {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <Grid container justifyContent={"end"} padding={2}>
          <Grid item>
            <Button variant="contained" data-testid={"t-signin-button"} onClick={handleSignin} disabled={disableSubmit}>
              Sign in
            </Button>
          </Grid>
        </Grid>
      </>
    </SoAdminCard>
  );
}
