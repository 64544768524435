import { isEmpty, isNil, isString, trim } from "lodash-es";

export function callCenterHomePath() {
  try {
    return !isNil(sessionStorage.callCenter_homePath) ? sessionStorage.callCenter_homePath : "";
  } catch (e) {
    console.log(e);
    return "";
  }
}

export function isCallCenterActive() {
  const path = callCenterHomePath();
  return isString(path) ? !!trim(path) : isEmpty(path);
}
