/**
 * New ActionTypes for CX1 React
 */
export const RECEIVE_PRODUCT_CONFIGURATION = "RECEIVE_PRODUCT_CONFIGURATION";
export const RECEIVE_REASONS = "RECEIVE_REASONS";
export const RECEIVE_EVENTS = "RECEIVE_EVENTS";
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const SELECT_EVENT = "SELECT_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const UNLOAD_EVENTS = "UNLOAD_EVENTS";
export const UPDATE_STARTTIME = "UPDATE_STARTTIME";
export const UPDATE_API_KEY_FAILED = "UPDATE_API_KEY_FAILED";
export const LOADING_EVENTS = "LOADING_EVENTS";
export const DEPOSIT_FAILED = "DEPOSIT_FAILED";
