import React, { useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  bookingReasons, determineInternalApiKey,
  determineIsOnlineBookingDisabled,
  determineIsOnlineBookingUnavailableUntil,
  determineIsStackingStrategyDisabled,
  determineNhsFee,
  determineOnlineBookingStateValue,
  determineStackIncrement,
} from "../state/MiniSiteSelectors";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { isEmpty, isEqual, isNil } from "lodash-es";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { sendAuthorizationCode, setTimezone, updateApiKey, updateApiKeyFailed } from "../state/MiniSiteActions";
import { fetchPerspective, updateDocument } from "../../common/state/CommonActions";
import { MinisiteCard } from "./MinisiteCard";
import { MiniSiteConstants } from "../constants/MiniSiteConstants";
import { parseISO, startOfDay } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { APPOINTMENT_SETTINGS } from "../../onlinebooking/state/OlbConstants";
import { saveDocument } from "../../common/state/DocumentSaving";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { DateField } from "@mui/x-date-pickers";
import { useErrorHandler } from "react-error-boundary";
import { WidgetLink } from "../../common/components/WidgetLink";
import { UTCDate } from "@date-fns/utc";

// @ts-ignore
const timezones = Intl.supportedValuesOf("timeZone");
const stackingValues = [
  { name: "10", value: "TEN_MINUTE" },
  { name: "15", value: "FIFTEEN_MINUTE" },
  { name: "20", value: "TWENTY_MINUTE" },
  { name: "30", value: "THIRTY_MINUTE" },
  { name: "45", value: "FORTYFIVE_MINUTE" },
  { name: "60", value: "SIXTY_MINUTE" },
  { name: "Start / End", value: "START_END" },
  { name: "Reason", value: "VARIABLE" },
];

export function AppointmentSettings(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const isStackingStrategyDisabled = useSelector(determineIsStackingStrategyDisabled);
  const onlineBookingStateValue = useSelector(determineOnlineBookingStateValue);
  const mfaEnabled = useSelector(
    (state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.mfaRequiredForPortal,
  );
  const selfCheckinEnabled = useSelector(
    (state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.portalCheckInEnabled === true,
  );
  const practiceName = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument?.name);
  const practiceAccountingCode = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceJsonDocument?.name);
  const newPatientBase = useSelector((state: ApplicationState) => state.common?.config?.newPatientBase);
  const openRecalls = useSelector(
    (state: ApplicationState) => !state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.useDateFromLink,
  );
  const displayCallback = useSelector(
    (state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.displayCallback,
  );
  const reasons = useSelector(bookingReasons);
  const timezone = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.timeZone);
  const stackIncrement = useSelector(determineStackIncrement);
  const nhsEnabled = useSelector((state: ApplicationState) => state.common?.config?.nhsEnabled);
  const nhsFee = useSelector(determineNhsFee);
  const logoUrl = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.logoUrl);
  const apiKeyMasked = useSelector(determineInternalApiKey);
  const isOnlineBookingDisabled = useSelector(determineIsOnlineBookingDisabled);
  const isOnlineBookingUnavailableUntil = useSelector(determineIsOnlineBookingUnavailableUntil);
  const openDate = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate);
  const [verificationCode, setVerificationCode] = useState("");
  const [newApiKey, setNewApiKey] = useState("");
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const ifChangeApiKeyValid = useMemo(() => {
    return !isEmpty(newApiKey) && !isEmpty(verificationCode);
  }, [newApiKey, verificationCode]);
  const emailAddress = useSelector((state: ApplicationState) => state.session.sessionData?.entityUserCard?.body?.emailAddress);
  const verificationCodeUnauthorized = useSelector((state: ApplicationState) => state.miniSite?.apiKeyData?.verificationCodeUnauthorized);
  const [isSavingApiKey, setIsSavingApiKey] = useState(false);
  const [unavailableUntilDateHasErrors, setUnavailableUntilDateHasErrors] = useState(false);
  const [isSavingDocument, setIsSavingDocument] = useState(false);
  const isUpdateButtonDisabled = useMemo(() => {
    return isSavingDocument || unavailableUntilDateHasErrors;
  }, [isSavingDocument, unavailableUntilDateHasErrors]);
  let isApiKeyDisabled:boolean = false

  const onOnlineBookingChanged = async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let openDate: Date | null = null;
    isApiKeyDisabled = false;
    if (isEqual(value, "disable")) {
      isApiKeyDisabled = true;
    } else {
      if (isEqual(value, "unavailableUntilFurtherNotice")) {
        setUnavailableUntilDateHasErrors(false);
        openDate = new Date(Date.UTC(2050, 0, 1));
      } else if (isEqual(value, "unavailableUntil")) {
        setUnavailableUntilDateHasErrors(false);
        openDate = startOfDay(utcToZonedTime(new Date(), timezone));
      }
    }
    dispatch(updateDocument(APPOINTMENT_SETTINGS, { openDate: openDate }));
    setIsSavingDocument(true);
    await onUpdateSettingsInternal(true);
    await onUpdateApiKey();
    setIsSavingDocument(false);

  };

  const onStartApiKeyChange = () => {
    isApiKeyDisabled = false;
    dispatch(updateApiKeyFailed(false));
    dispatch(sendAuthorizationCode()).catch((error) => {
      handleError(error);
    });
    setVerificationCode("");
    setNewApiKey("");
    setIsSavingApiKey(false);

    setShowApiKeyModal(true);
  };

  const closeApiKeyDialog = () => {
    setShowApiKeyModal(false);
  };
  const onApiKeyChanged = (event) => {
    setNewApiKey(event.target.value);
  };

  const onVerificationCodeChanged = (event) => {
    setVerificationCode(event.target.value);
  };

  const onUpdateApiKey = () => {
    setIsSavingApiKey(true);
    const code = isEmpty(verificationCode) ? undefined : verificationCode;
    const key = isEmpty(newApiKey) ? undefined : newApiKey;
    return dispatch(updateApiKey(code, key, isApiKeyDisabled))
      .then(() => {
        return dispatch(fetchPerspective("1")).then(() => {
          setShowApiKeyModal(false);
          setIsSavingApiKey(false);
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const onMFAChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDocument(APPOINTMENT_SETTINGS, { mfaRequiredForPortal: event.target.checked }));
  };

  const onSelfCheckinChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateDocument(APPOINTMENT_SETTINGS, { portalCheckInEnabled: event.target.checked }));
  };

  const onTimezoneChanged = (event: SelectChangeEvent) => {
    dispatch(updateDocument(APPOINTMENT_SETTINGS, { timeZone: event.target.value }));
  };

  const onUpdateSettingsInternal = async (overrideIsSaving = false) => {
    if (!overrideIsSaving) setIsSavingDocument(true);
    await dispatch(saveDocument(DocumentConstants.APPOINTMENT_SETTINGS)).catch((error) => {
      handleError(error);
    });
    setTimezone(timezone);
    if (!overrideIsSaving) setIsSavingDocument(false);
  }
  const onUpdateSettings = async () => {
    await onUpdateSettingsInternal();
  };

  const onOpenDateChanged = (value) => {
    if (!isNil(value)) {
      // Convert entered date into practice's timezone
      const convertedValue = zonedTimeToUtc(value, timezone);
      // Date needs to be today or in the future
      if (convertedValue.getTime() < utcToZonedTime(new Date(), timezone).getTime()) {
        setUnavailableUntilDateHasErrors(true);
      } else {
        setUnavailableUntilDateHasErrors(false);
        dispatch(updateDocument(APPOINTMENT_SETTINGS, { openDate: convertedValue }));
      }
    } else {
      setUnavailableUntilDateHasErrors(true);
    }
  };

  const onStackingIncrementChange = (event: SelectChangeEvent) => {
    const value = event.target.value === "" ? null : event.target.value;
    dispatch(updateDocument(APPOINTMENT_SETTINGS, { stackIncrement: undefined, stackingStrategy: value }));
  };

  return (
    <>
      <MinisiteCard cardHeader={"Settings"} isSaving={isSavingDocument}>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <FormControl>
              <FormLabel>Online Booking</FormLabel>
              <RadioGroup row name="onlineBooking" value={onlineBookingStateValue} onChange={onOnlineBookingChanged}>
                <FormControlLabel value="enable" control={<Radio />} label="Enabled" disabled={isSavingDocument} data-testid={"t-minisite-booking-buttons-enabled"}/>
                <FormControlLabel value="unavailableUntilFurtherNotice" control={<Radio />} label="Unavailable until further notice" disabled={isSavingDocument} data-testid={"t-minisite-booking-buttons-unavailable-until-further"}/>
                <FormControlLabel value="unavailableUntil" control={<Radio />} label="Unavailable until" disabled={isSavingDocument} data-testid={"t-minisite-booking-buttons-unavailable-until"}/>
                <FormControlLabel value="disable" control={<Radio />} label="Disabled" disabled={isSavingDocument} data-testid={"t-minisite-booking-buttons-disabled"}/>
              </RadioGroup>
            </FormControl>
          </Grid>
          {isOnlineBookingUnavailableUntil && (
            <Grid item sm={12}>
              <FormControl>
                <FormLabel>Unavailable until</FormLabel>
                <DateField onChange={onOpenDateChanged}
                           value={openDate}
                           format={"dd/MM/yyyy"}
                           slotProps={
                             {textField:{
                                error: unavailableUntilDateHasErrors
                               }
                             }
                           }
                           data-testid={"t-minisite-booking-opendate-field"}></DateField>
              </FormControl>
            </Grid>
          )}
          <Grid item sm={12}>
            <FormControl>
              <FormLabel>MFA - Multi-factor authentication for sign in</FormLabel>
              <Switch checked={mfaEnabled || false} onChange={onMFAChanged} name={"mfa"} />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl>
              <FormLabel>Self Checkin - Enable to display on the customer portal</FormLabel>
              <Switch checked={selfCheckinEnabled || false} onChange={onSelfCheckinChanged} name={"self-checkin"} />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl>
              <FormLabel>Widget link</FormLabel>
              <div>
                <WidgetLink name={practiceName} accountingCode={practiceAccountingCode} newPatientBase={newPatientBase} />
              </div>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl>
              <FormLabel>Open recalls</FormLabel>
              <div>{String(openRecalls)}</div>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl>
              <FormLabel>Call back</FormLabel>
              <div>{String(displayCallback)}</div>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl>
              <FormLabel>Booking reasons</FormLabel>
              <div>{reasons}</div>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl>
              <FormLabel>Timezone</FormLabel>
              <Select value={timezone} label="Timezone" onChange={onTimezoneChanged}>
                {timezones.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Requires a re-upload of available slots.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl>
              <FormLabel>Stacking strategy</FormLabel>
              <Select value={stackIncrement} label="Stacking strategy" displayEmpty disabled={isStackingStrategyDisabled} onChange={onStackingIncrementChange}>
                {isStackingStrategyDisabled && <MenuItem value={""}>Off</MenuItem>}
                {stackingValues.map((item) => {
                  return (
                    <MenuItem key={item.name} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {isStackingStrategyDisabled ? (
                <FormHelperText>Configure stacking via the API.</FormHelperText>
              ) : (
                <FormHelperText>To turn off stacking, disable via the API.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {nhsEnabled && (
            <Grid item sm={4}>
              <FormControl>
                <FormLabel>NHS Fees</FormLabel>
                {nhsFee?.name ? <div>{nhsFee.name}</div> : <div>NHS Fees not enabled</div>}
              </FormControl>
            </Grid>
          )}
          <Grid item sm={4}>
            <FormControl>
              <FormLabel>Logo path</FormLabel>
              <div style={{ wordBreak: "break-all" }}>{logoUrl}</div>
            </FormControl>
          </Grid>
          <Grid item sm={4}>
            <FormControl>
              <FormLabel>API key</FormLabel>
              <div>{apiKeyMasked}</div>{" "}
              <Button variant="outlined" disabled={isOnlineBookingDisabled} onClick={onStartApiKeyChange}>
                Change
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={"20px"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Button variant="contained" disabled={isUpdateButtonDisabled} onClick={onUpdateSettings} data-testid={"t-minisite-appointment-settings-save-button"}>
                Save settings
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MinisiteCard>
      <Dialog open={showApiKeyModal} onClose={closeApiKeyDialog}>
        <DialogTitle>Change API Key</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            A verification code has been sent to: <b>{emailAddress}</b>.<br />
            Please enter the code as well as the new API key
            {verificationCodeUnauthorized && <Alert severity="error">Code is not valid. Please check your email and try again.</Alert>}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Code"
            value={verificationCode}
            type="text"
            fullWidth
            variant="standard"
            onChange={onVerificationCodeChanged}
          />
          <TextField autoFocus margin="dense" label="apiKey" value={newApiKey} type="text" fullWidth variant="standard" onChange={onApiKeyChanged} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeApiKeyDialog}>Cancel</Button>
          <Button disabled={!ifChangeApiKeyValid || isSavingApiKey} onClick={onUpdateApiKey}>
            Save
          </Button>
        </DialogActions>
        {isSavingApiKey && <LinearProgress />}
      </Dialog>
    </>
  );
}
