cookieconsent.utils.setCookie = function(name, value, expiryDays, domain, path, secure, sameSite) {
  const exdate = new Date();
  exdate.setHours(exdate.getHours() + ((expiryDays || 365) * 24));
  const cookie = [
    name + '=' + value,
    'expires=' + exdate.toUTCString(),
    'path=' + (path || '/')
  ];
  if (domain) {
    cookie.push('domain=' + domain);
  }
  if (secure) {
    cookie.push('secure');
  }
  if (sameSite) {
    cookie.push('sameSite=' + sameSite );
  }
  document.cookie = cookie.join(';');
};

cookieconsent.Popup.prototype.setStatus = function(status) {
  const c = this.options.cookie;
  const value = cookieconsent.utils.getCookie(c.name);
  const chosenBefore = Object.keys(cookieconsent.status).indexOf(value) >= 0;
  // if `status` is valid
  if (Object.keys(cookieconsent.status).indexOf(status) >= 0) {
    cookieconsent.utils.setCookie(
      c.name,
      status,
      c.expiryDays,
      c.domain,
      c.path,
      c.secure,
      c.sameSite
    );
    this.options.onStatusChange.call(this, status, chosenBefore);
  } else {
    this.clearStatus();
  }
};
