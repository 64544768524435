import React from "react";
import { AppointmentTypeSelectorHenrySchein } from "./AppointmentTypeSelectorHenrySchein";
import { MedicalCardSelectorHenrySchein } from "./MedicalCardSelectorHenrySchein";
import { InsuranceSelector } from "../step/newexisting/configurator/InsuranceSelector";
import { ReasonSelector } from "../step/newexisting/configurator/ReasonSelector";
import { ProviderSelector } from "../step/newexisting/configurator/ProviderSelector";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import EventRequest from "../../state/model/EventRequest";
import { Grid } from "@mui/material";
import ConfiguratorFieldOptions from "../../state/model/ConfiguratorFieldIOptions";

export function EventShapeConfiguratorHenrySchein(): React.JSX.Element {
  const eventRequest = useSelector((state: ApplicationState): EventRequest | undefined => state.olb?.eventRequest);
  // NOTE AH: Replace the {} as ConfiguratorFieldOptions with the `actualFieldOptions as ConfiguratorFieldOptions`
  const { payorType, providerId, reasonExternalId, patientType } = useSelector(
    (state: ApplicationState) => state.olb?.productConfiguration?.options?.fields ?? {},
  );

  return (
    <>
      {eventRequest && (
        <Grid container rowSpacing={3} style={{ margin: 20 }}>
          <AppointmentTypeSelectorHenrySchein fieldOptions={patientType as ConfiguratorFieldOptions} />
          <MedicalCardSelectorHenrySchein fieldOptions={payorType as ConfiguratorFieldOptions} />
          <InsuranceSelector fieldOptions={payorType as ConfiguratorFieldOptions} />
          <ReasonSelector fieldOptions={reasonExternalId as ConfiguratorFieldOptions} />
          <ProviderSelector fieldOptions={providerId as ConfiguratorFieldOptions} />
        </Grid>
      )}
    </>
  );
}
