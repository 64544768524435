import React from "react";
import showdown from "showdown";
import xssFilter from "showdown-xss-filter";

export function MarkdownToHtml({ markdown }: { markdown: string }): React.JSX.Element {
  const converter = new showdown.Converter({ extensions: [xssFilter]});
  converter.setOption('simpleLineBreaks', 'true')
  const source = markdown || "";
  let cleanSource = source.replace(/(\\r\\n|\\n|\\r)/gm, "\n");
  cleanSource = cleanSource.replace(/(\\t)/gm, "\t");
  cleanSource = cleanSource.replace(/(    )/gm, "&nbsp;");
  return <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(cleanSource) }} data-testid={"t-markdown"} ></div>;
}
