import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import stepWatcher from "../utilities/StepWatcher";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { reload } from "../../common/state/CommonActions";

export function AppointmentCardStatePrimary({ currentStepId }: { currentStepId: string }): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isWatching, setIsWatching] = useState(false);

  // Only want this to run once.  Not sure how to satisfy "React Hook useEffect has missing dependencies: 'currentStepId' and 'dispatch'. Either include them or remove the dependency array"
  useEffect(() => {
    stepWatcher.startWatchingStepId(currentStepId, setIsWatching).then(() => {
      dispatch(reload("3"));
    });
  }, [dispatch, currentStepId]);

  return (
    <Alert severity="info" data-testid="t-book-state-primary">
      {isWatching ? (
        <>
          <AlertTitle>
            <FontAwesomeIcon icon={isWatching ? faGear : faRefresh} size={"1x"} spin={true} /> {t("booking.submitted.notConfirmed.text")}
          </AlertTitle>
          {t("booking.submitted.notReceived.text")}
        </>
      ) : (
        <>
          <AlertTitle>
            <FontAwesomeIcon icon={isWatching ? faGear : faRefresh} size={"1x"} pulse={true} /> {t("booking.submitted.notConfirmed.expired.text")}
          </AlertTitle>
          {t("booking.submitted.notReceived.expired.text")}
        </>
      )}
    </Alert>
  );
}
