export function isBrowserSupported() {
  let supported = true;
  const isIe = navigator.userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
  if (isIe) {
    supported = false;
  }
  return supported;
}

export function isFacebookApp() {
  const ua = navigator.userAgent || navigator.vendor;
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
}
