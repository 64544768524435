import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { formatPhone, formatPhoneCountry } from "../../common/utilities/GeneralHelpers";
import { useTranslation } from "react-i18next";
import { countryForE164Number } from "../../common/utilities/PhoneFormatWrapper";

export function PatientValidationChannels({ alwaysShowChannels = false }: { alwaysShowChannels?: boolean }): React.JSX.Element {
  const { t } = useTranslation();
  const patient = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument);
  const showSmsChannel = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.smsChannel) || alwaysShowChannels;
  const showEmailChannel = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.emailChannel) || alwaysShowChannels;
  return (
    <ul>
      {showSmsChannel && (
        <>
          {patient?.mobilenumber && (
            <li>
              <strong>{formatPhone(patient.mobilenumber)}</strong>
              <small>
                {" " + formatPhoneCountry(countryForE164Number(patient.mobilenumber || ""))}
                {" " + t("booking.verifyCode.numberCountry.label")}
              </small>
            </li>
          )}
        </>
      )}
      {showEmailChannel && (
        <>
          {patient?.emailaddress && (
            <li>
              <strong>{patient.emailaddress}</strong>
            </li>
          )}
        </>
      )}
    </ul>
  );
}
