import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import OnlineBooking from "./onlinebooking/OnlineBooking";
import MiniSite from "./minisite/MiniSite";
import SoAdmin from "./soadmin/SoAdmin";
import { useTranslation } from "react-i18next";
import { CookiesDisabled } from "./common/components/CookiesDisabled";
import { BrowserUnsupported } from "./common/components/BrowserUnsupported";

const Main = (): React.JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t(`browser.page.title.text`);
    if (!_checkCookie()) {
      navigate("/cookies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div className="t-loading">Loading...</div>}>
      <Routes>
        <Route path="/unsupported" element={<BrowserUnsupported />} />
        <Route path="/cookies" element={<CookiesDisabled />} />
        <Route path="/" element={<Navigate to={`/perspectives/${(window as any).ThreePointData.defaultPerspective}`} />} />
        <Route path="/perspectives" element={<Navigate to={`/perspectives/${(window as any).ThreePointData.defaultPerspective}`} />} />
        <Route path="/perspectives/1" element={<MiniSite />} />
        <Route path="/perspectives/3" element={<OnlineBooking />} />
        <Route path="/perspectives/5" element={<SoAdmin />} />
      </Routes>
    </Suspense>
  );
};

function _checkCookie() {
  try {
    // Create cookie
    document.cookie = "cookietest=1; SameSite=None; secure; partitioned";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; SameSite=None; secure; partitioned; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (e) {
    return false;
  }
}

export default Main;
