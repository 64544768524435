import { SoAdminActionTypes } from "./SoAdminActionTypes";
import { cardActionService } from "../../common/state/DocumentSaving";
import { reload } from "../../common/state/CommonActions";
import axios from "axios";
import { CX_API_ADDRESS, CX_V1_BETA_API_ADDRESS } from "../../common/constants/PathConstants";

export function updateSession(params) {
  return {
    type: SoAdminActionTypes.UPDATE_SESSION,
    params,
  };
}

export function saveSession() {
  return (dispatch, getState) => {
    const action = getState().session.sessionData.iEntityCard.actions[0];
    return dispatch(cardActionService.execute({ action, data: undefined }))
      .then((response) => {
        dispatch(updateSession({ verificationChannel: response.data }));
        return dispatch(reload("5"));
      })
      .catch((error) => {
        if (405 === error.response.status) {
          return dispatch(updateSession({ verificationChannel: error.response.data }));
        } else {
          throw error;
        }
      });
  };
}

export function sendVerificationCode(method) {
  return (dispatch) => {
    return axios.post(`/cx/v1/mfa/send/${method}`, {}, { headers: { "Content-Type": "application/json" } }).catch((error) => {
      if (405 === error.response.status) {
        return dispatch(updateSession({ verificationChannel: error.response.data }));
      } else {
        return dispatch(reload("5"));
      }
    });
  };
}

export function cancelVerification(restart: boolean = false) {
  return (dispatch) => {
    return axios.post("/cx/v1/mfa/cancel", {}, { headers: { "Content-Type": "application/json" } }).catch((error) => {
      if (401 === error.response?.status) {
        dispatch(reload("5"));
      } else {
        if (restart) {
          dispatch(saveSession());
        } else {
          dispatch(updateSession({ verificationChannel: error.response.data }));
        }
      }
    });
  };
}

export function verifyCode(code: string, mfaDontAsk: boolean) {
  return (dispatch) => {
    return axios
      .post("/cx/v1/mfa/verifyCode", { code, mfaDontAsk }, { headers: { "Content-Type": "application/json" } })
      .then(async (response) => {
        await dispatch(updateSession({ verificationChannel: response.data }));
        return await dispatch(reload("5"));
      })
      .catch((error) => {
        if (401 === error.response?.status) {
          return dispatch(reload("5"));
        } else {
          return dispatch(updateSession({ verificationChannel: { ...error.response.data, invalidCode: true } }));
        }
      });
  };
}

export function deletePractice(practiceDocumentId) {
  return () => {
    return axios.delete(`${CX_API_ADDRESS}/qa/practices/${practiceDocumentId}`, { headers: { "Content-Type": "application/json" } });
  };
}

export function deleteGroups() {
  return () => {
    return axios.delete(`${CX_V1_BETA_API_ADDRESS}/group/instances`, { headers: { "Content-Type": "application/json" } });
  };
}
