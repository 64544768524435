import { useEffect } from "react";
import { isBrowserSupported } from "../utilities/Browser";
import { useNavigate } from "react-router-dom";

export const useBrowserSupported = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isBrowserSupported()) {
      navigate("/unsupported");
    }
  }, []);
};
