import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "./model/ApplicationState";
import { DispatchType } from "../../dispatcher";
import Action from "../models/Action";
import axios, { AxiosResponse } from "axios";
import { isEmpty, isNil } from "lodash-es";
import { DocumentConstants } from "../constants/DocumentConstants";

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, DispatchType>;

export function _findAction(card: { actions?: Action[] }, searchTerm: string): Action | undefined {
  return card?.actions?.find((action) => action?.name?.includes(searchTerm));
}

type stepActionName = { stepActionName: string };
type actionAndData = { action: Action; data: any };
function isActionAndData(executeType: stepActionName | actionAndData): executeType is actionAndData {
  return (executeType as actionAndData).action !== undefined;
}

export const cardActionService = {
  execute(params: actionAndData | stepActionName) {
    return (dispatch, getState) => {
      if (isActionAndData(params)) {
        return this._execute(params.action, params.data);
      } else {
        return this._executeStepAction(params.stepActionName, getState().olb?.stepData?.stepCard);
      }
    };
  },

  _executeStepAction(actionName: string, stepCard: any) {
    const foundAction = _findAction(stepCard, actionName);

    if (foundAction) return this._execute(foundAction);
    else throw new Error("No found action");
  },

  _execute(action: Action, data?: any): Promise<AxiosResponse> {
    const otherData = isEmpty(data) ? action.requirementsMet : data;

    return axios.request({
      url: action.path,
      method: action.verb,
      data: otherData,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export function saveDocument(action: DocumentConstants): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    let xhr: Promise<any> = Promise.resolve();
    switch (action) {
      case DocumentConstants.APPOINTMENT:
        const appointmentAction = _findAction(state.olb?.appointmentData?.appointmentDocumentCard ?? { actions: [] }, "Store");
        if (!isNil(appointmentAction)) {
          xhr = cardActionService._execute(appointmentAction, appointmentAction?.data1);
        }
        break;
      case DocumentConstants.PATIENT:
        const patientAction = _findAction(state.olb?.patientInfoData?.patientDocumentCard ?? { actions: [] }, "Store");
        if (!isNil(patientAction)) {
          xhr = cardActionService._execute(patientAction, patientAction?.data1);
        }
        break;
      case DocumentConstants.APPOINTMENT_SETTINGS:
        const appointmentSettingsAction = _findAction(state.common?.appointmentSettingsData?.appointmentSettingsDocumentCard ?? { actions: [] }, "Store");
        if (!isNil(appointmentSettingsAction)) {
          xhr = cardActionService._execute(appointmentSettingsAction, appointmentSettingsAction?.data1);
        }
        break;
      case DocumentConstants.ADMIN_DATE_SELECTOR:
        const adminDateSelectorAction = _findAction(state.miniSite?.adminDateSelectorData?.adminDateSelectorCard ?? { actions: [] }, "Update Dates");
        if (!isNil(adminDateSelectorAction)) {
          xhr = cardActionService._execute(adminDateSelectorAction, adminDateSelectorAction?.data1);
        }
        break;
      case DocumentConstants.CHARGE:
        console.warn("UNIMPLEMENTED");
        break;
    }
    return xhr;
  };
}
