import { CircularProgress, Typography } from "@mui/material";
import { TimeSelection } from "../../TimeSelection";
import { EventList } from "../../EventList";
import React from "react";
import { NextRangeFunction, PreviousRangeFunction, SelectEventFunction } from "../StepTypes";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import {
  getIsOnlineBookingUnavailableUntil,
  getIsOnlineBookingUnavailableUntilFurtherNotice,
  getProposedEvents,
  getUseDateFromLink,
} from "../../../state/OlbSelectors";
import { getNumOfDays } from "../../../state/OlbActions";

import { isNil } from "lodash-es";
import ProposedEvent from "../../../state/model/ProposedEvent";
import { selectEvent } from "../../../state/TimeSelectionActions";
import { updateAnalytics } from "../../../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../../../common/analytics/AnalyticsConstants";

export function SelectATime({
  showMedicalInformation = false,
  onPreviousRange,
  onNextRange,
}: {
  showMedicalInformation?: boolean;
  onPreviousRange: PreviousRangeFunction;
  onNextRange: NextRangeFunction;
}): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const eventRequest = useSelector((state: ApplicationState) => state.olb?.eventRequest);
  const appointmentSettingsDocument = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument);
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const practiceDocument = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument);
  const eventSelectionData = useSelector((state: ApplicationState) => state.olb?.eventSelectionData);
  const useDateFromLink = useSelector(getUseDateFromLink);
  const numOfDays = useSelector(getNumOfDays);
  const isOnlineBookingUnavailableUntilFurtherNotice = useSelector(getIsOnlineBookingUnavailableUntilFurtherNotice);
  const isOnlineBookingUnavailableUntil = useSelector(getIsOnlineBookingUnavailableUntil);
  const proposedEvents = useSelector(getProposedEvents);
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);
  const hasReceivedTimes = useSelector((state: ApplicationState) => (!isNil(state.olb?.eventData) ? state.olb?.eventData.hasReceivedTimes : false));

  const handlePreviousRange: PreviousRangeFunction = () => {
    onPreviousRange();
  };

  const handleNextRange: NextRangeFunction = () => {
    onNextRange();
  };

  const onSelectEvent: SelectEventFunction = (time: ProposedEvent, skipDocumentUpdate: boolean) => {
    dispatch(selectEvent(time, skipDocumentUpdate));
    updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
      category: "timeSelection",
      action: "select",
      label: time.startTime.toISOString(),
    });
    const element = document.getElementById("EventListButton");
    const pos = element?.offsetTop;
    if (!isNil(pos)) {
      setTimeout(() => document.querySelector("body")?.scrollTo(0, pos), 300);
    }
  };

  return (
    <>
      {isLoadingEvents && (
        <>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
            <CircularProgress size={100} />
          </div>
          <Typography align={"center"} style={{ marginTop: 10 }}>
            {t("booking.loading.text")}
          </Typography>
        </>
      )}
      <TimeSelection
        proposedEvents={proposedEvents}
        eventRequest={eventRequest}
        selectedEvent={eventSelectionData?.selectedEvent}
        startTime={eventSelectionData?.startTime}
        startDate={eventSelectionData?.startDate}
        useDateFromLink={useDateFromLink}
        practicePhone={practiceDocument?.phone}
        practiceName={practiceDocument?.name}
        practiceEmail={practiceDocument?.email}
        numOfDays={numOfDays}
        isLoadingEvents={isLoadingEvents}
        openDate={appointmentSettingsDocument?.additionalSettings.openDate}
        isOnlineBookingUnavailableUntilFurtherNotice={isOnlineBookingUnavailableUntilFurtherNotice}
        isOnlineBookingUnavailableUntil={isOnlineBookingUnavailableUntil}
        previousRange={handlePreviousRange}
        nextRange={handleNextRange}
        selectEvent={onSelectEvent}
        receivedTimes={hasReceivedTimes}
      />
      {((proposedEvents && proposedEvents.length > 0) || hasReceivedTimes) && (
        <EventList
          appointmentDocument={appointmentDocument}
          showMedicalInformation={showMedicalInformation}
          showTermsOfUse={true}
          showMarketingConsent={true}
        />
      )}
    </>
  );
}
