import * as ActionTypes from "../../onlinebooking/state/OlbActionTypes";
import { Dispatch } from "react";
import { DispatchType } from "../../dispatcher";
import axios, { AxiosResponse } from "axios";
import { organizationId } from "../../common/state/CommonActions";
import { AppThunk } from "../../common/state/DocumentSaving";
import { PaymentConfiguratorBody } from "./model/PaymentConfiguration";
import { isNil } from "lodash-es";
import { MiniSiteActionTypes } from "./MiniSiteActionTypes";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { CX_API_ADDRESS, CX_V1_BETA_API_ADDRESS } from "../../common/constants/PathConstants";

export function sendAuthorizationCode() {
  const path = `${CX_V1_BETA_API_ADDRESS}/updateAPIKey/${organizationId}/send`;
  return (): Promise<any> => {
    return axios.put(path, {}).then(({ data: json }) => {
      return json;
    });
  };
}

export function updateApiKey(verificationCode, apiKey, isDisableApiKey) {
  const path = `${CX_V1_BETA_API_ADDRESS}/updateAPIKey/${organizationId}/update`;
  return (dispatch: Dispatch<DispatchType>): Promise<any> => {
    return axios
      .put(path, { submittedCode: verificationCode, newAPIKey: apiKey, disableApiKey: isDisableApiKey })
      .then(({ data: json }) => {
        return json;
      })
      .catch((error) => {
        dispatch(updateApiKeyFailed(true));
        throw error;
      });
  };
}

export function updateApiKeyFailed(failed: boolean) {
  return {
    type: ActionTypes.UPDATE_API_KEY_FAILED,
    failed,
  };
}

export function setTimezone(timezone: string) {
  (window as any).ThreePointData.timeZone = timezone;
}

export function sendReport(reportType) {
  return (dispatch, getState) => {
    const applicationState: ApplicationState = getState();
    const emailAddress = applicationState.session.sessionData?.entityUserCard?.body?.emailAddress;
    const adminDateSelectorData = applicationState.miniSite?.adminDateSelectorData;
    if (!isNil(emailAddress)) {
      let reportUrl;
      switch (reportType) {
        case MiniSiteActionTypes.SEND_REPORT_STATS:
          reportUrl = adminDateSelectorData?.reportUrlStats;
          break;
        case MiniSiteActionTypes.SEND_REPORT_AGGREGATOR:
          reportUrl = adminDateSelectorData?.reportUrlAggregator;
          break;
        case MiniSiteActionTypes.SEND_REPORT_CALLCENTER:
          reportUrl = adminDateSelectorData?.reportUrlCallCenter;
          break;
        case MiniSiteActionTypes.SEND_REPORT_FLYNOTES:
          reportUrl = adminDateSelectorData?.reportUrlFlyNotes;
          break;
        case MiniSiteActionTypes.SEND_REPORT_SELFCHECKIN:
          reportUrl = adminDateSelectorData?.reportUrlSelfCheckin;
          break;
      }
      if (isNil(reportUrl)) {
        window.alert("Unable to find the report.  Please contact support");
      } else {
        return axios.get(reportUrl, { headers: { "Content-Type": "application/json" } }).then(() => {
          window.alert("Generating report...  It will be emailed to " + emailAddress + " when ready.");
        });
      }
    } else {
      window.alert("Your user account does not have an email set.  Please contact support.");
    }
  };
}

export function enrollInPlan(paymentConfigurator: PaymentConfiguratorBody): AppThunk<Promise<AxiosResponse>> {
  const path = `${CX_API_ADDRESS}/payments/enrollment`;
  return (): Promise<any> => {
    const params = {
      processor: paymentConfigurator.processor,
      collectApplicationFee: paymentConfigurator.collectApplicationFee,
      plan: paymentConfigurator?.plan,
      organizationID: organizationId,
      accountEmail: paymentConfigurator.emailAddresses,
    };
    //const data = JSON.stringify(params);
    return axios.post(path, params, { headers: { "Content-Type": "application/json" } }).then(({ data: json }) => {
      return json;
    });
  };
}

export function disenrollInPlan(): AppThunk<Promise<AxiosResponse>> {
  const path = `${CX_API_ADDRESS}/payments/enrollment`;
  return (): Promise<any> => {
    const params = {
      organizationID: organizationId,
      processor: "StripePaymentService",
    };
    //const data = JSON.stringify(params);
    return axios
      .delete(path, {
        headers: { "Content-Type": "application/json" },
        data: params,
      })
      .then(({ data: json }) => {
        return json;
      });
  };
}
