import React from "react";
import { Link } from "@mui/material";

export function MiniSiteLink({ practice }: { practice: any }): React.JSX.Element {
  if (practice?.owningOrganization?.id === false) {
    return <></>;
  }
  const organizationId = practice.owningOrganization.id;
  const href = `/v1/view/organization/${organizationId}/index.html`;
  // TODO: JS Need to convert this to use ENV.APP variables or transition to route
  return <Link href={href}>Go to Mini Site</Link>;
}
