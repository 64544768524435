import { Reducer } from "react";
import Action from "../models/Action";
import { CommonActionTypes } from "./CommonActionTypes";
import AppointmentSettingsData from "./model/AppointmentSettingsData";
import AnalyticsData from "./model/AnalyticsData";
import { _findCardWithType, _findJSONSchemaDocumentCardsWithIdentifierOf } from "./CommonReducerHelpers";
import * as Constants from "../../onlinebooking/state/OlbConstants";
import { forEach, isEmpty, isNil, set } from "lodash-es";
import CommonState from "./model/CommonState";
import PracticeData from "./model/PracticeData";
import Config from "../../onlinebooking/state/model/Config";

export const commonReducer: Reducer<CommonState, Action> = (state: CommonState = {}, action: Action = {} as Action): CommonState => {
  const result: CommonState = { ...state };

  switch (action.type) {
    case CommonActionTypes.RECEIVE_PERSPECTIVE: {
      result.appointmentSettingsData = _reduceAppointmentSettings(action.state.stacks);
      result.analyticsData = _reduceAnalyticsData(action.state.googleAnalytics, action.state.analytics);
      result.practiceData = _reducePracticeData(action.state.stacks);
      result.config = _reduceConfig(action.state.stacks);
      break;
    }
    case CommonActionTypes.UPDATE_DOCUMENT: {
      switch (action.documentType) {
        case Constants.APPOINTMENT_SETTINGS:
          if (!isNil(result.appointmentSettingsData)) {
            _updateAppointmentSettingsDocument(result.appointmentSettingsData, action.params);
          }
          break;
      }
      break;
    }
    case CommonActionTypes.UPDATE_IS_LOADING: {
      result.isLoadingPerspective = action.isLoading;
    }
  }
  return result;
};

function _reduceAnalyticsData(googleAnalytics, analytics) {
  const result: AnalyticsData = {
    providers: [],
    analytics,
  };
  googleAnalytics.forEach((e) => {
    if (!isNil(result.providers)) {
      result.providers.push(e.trackingId);
    } else {
      result.providers = [e.trackingId];
    }
  });
  return result;
}

function _reduceAppointmentSettings(stacks) {
  const result: AppointmentSettingsData = {
    appointmentSettingsDocumentCard: null,
    appointmentSettingsDocument: null,
  };

  _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "8").forEach((card: any) => {
    result.appointmentSettingsDocumentCard = card;
    result.appointmentSettingsDocument = card?.body?.value;
  });

  _normalizeAppointmentSettings(result.appointmentSettingsDocument);
  return result;
}

function _populateAppointmentSettingsUpdateActionWithValue(result: AppointmentSettingsData, field, value) {
  if (isEmpty(result.appointmentSettingsDocumentCard?.actions?.[0].data1)) {
    return;
  }
  const actionValueObject = JSON.parse(result.appointmentSettingsDocumentCard?.actions?.[0].data1?.value);
  actionValueObject.additionalSettings[field] = value;
  result.appointmentSettingsDocumentCard.actions[0].data1.value = JSON.stringify(actionValueObject);
}

function _updateAppointmentSettingsDocument(result: AppointmentSettingsData, params: any) {
  forEach(params, (value, key) => {
    const prop = `appointmentSettingsDocument.additionalSettings.${key}`;
    if (
      key in result.appointmentSettingsDocument?.additionalSettings ||
      key === "stackingStrategy" ||
      key == "portalCheckInEnabled" ||
      key === "mfaRequiredForPortal" ||
      key ==="openDate"
    ) {
      set(result, prop, value);
      _populateAppointmentSettingsUpdateActionWithValue(result, key, value);
    }
  });
}

function _reducePracticeData(stacks) {
  const result: PracticeData = {
    practiceDocumentCards: [],
    practiceJsonDocuments: [],
    practiceDocuments: [],
    practiceDocumentCard: null,
    practiceJsonDocument: null,
    practiceDocument: null,
  };

  _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "2").forEach((card: any) => {
    result.practiceDocumentCards.push(card);
    result.practiceJsonDocuments.push(card.body);
    result.practiceDocuments.push(card.body.value);
  });

  result.practiceDocumentCard = result.practiceDocumentCards.length > 0 ? result.practiceDocumentCards[0] : null;
  result.practiceJsonDocument = result.practiceJsonDocuments.length > 0 ? result.practiceJsonDocuments[0] : null;
  result.practiceDocument = result.practiceDocuments.length > 0 ? result.practiceDocuments[0] : null;

  if (result.practiceJsonDocument?.name) {
    (window as any).ThreePointData.practiceId = result.practiceJsonDocument.name;
  }
  return result;
}

function _reduceConfig(stacks): Config {
  const card = _findCardWithType(stacks, "com_threepointdata_bosv31_Config");
  return card !== null ? card.body : null;
}

function _normalizeAppointmentSettings(appointmentSettings: any) {
  if (appointmentSettings?.additionalSettings?.openDate) {
    appointmentSettings.additionalSettings.openDate = new Date(appointmentSettings.additionalSettings?.openDate);
  }
}
