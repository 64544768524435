import React from "react";
import { Box, CircularProgress, Divider, Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";

export function BookingCard({ children }: { children: React.JSX.Element }): React.JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLoadingPerspective = useSelector((state: ApplicationState) => state.common?.isLoadingPerspective);

  return (
    <Paper variant="outlined">
      <Box style={{ padding: 10 }} sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
        {t("portlet.default.title")}
      </Box>
      <Divider light />
      {isLoadingPerspective ? (
        <div style={{ display: "flex", justifyContent: "center", margin: 20 }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <>{children}</>
      )}
    </Paper>
  );
}
