import React, {useMemo} from "react";
import { MinisiteCard } from "./MinisiteCard";
import { PaymentActivationDisenroll } from "./PaymentActivation/PaymentActivationDisenroll";
import { PaymentActivationEnable } from "./PaymentActivation/PaymentActivationEnable";
import { PaymentActivationEnroll } from "./PaymentActivation/PaymentActivationEnroll";
import { PaymentActivationMode } from "../constants/MiniSiteConstants";
import {Grid, Typography} from "@mui/material";
import {formatCurrency} from "../../common/utilities/GeneralHelpers";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../common/state/model/ApplicationState";
import {PaymentActivationServicePlan} from "./PaymentActivation/PaymentActivationServicePlan";

export function PaymentActivation({ paymentMode }: { paymentMode?: string }): React.JSX.Element {
  const paymentConfigurator = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator);
  const hasBalance = useMemo(() => {
    return paymentConfigurator?.balance
  },[paymentConfigurator]);
  const isPlanEmpty = useMemo(() => {
    return paymentConfigurator?.plan == null
  },[paymentConfigurator]);

  function getPaymentConfiguration() {
    if (paymentMode === PaymentActivationMode.DISENROLL) {
      return <PaymentActivationDisenroll />;
    } else if (paymentMode === PaymentActivationMode.ENABLE) {
      return <PaymentActivationEnable />;
    } else if (paymentMode === PaymentActivationMode.ENROLL) {
      return <PaymentActivationEnroll />;
    }
  }

  return <MinisiteCard cardHeader={"Deposits"}>
    {getPaymentConfiguration()}
    {hasBalance && <Grid container sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Invoices</Typography>
      </Grid>
      <Grid item container xs={12} style={{ margin: 10 }} spacing={2}>
        <Grid item xs={2} className={"center-vert"}>
          <strong className={"pull-right"}>Balance</strong>
        </Grid>
        <Grid item xs={8}>
          {formatCurrency(paymentConfigurator?.balance?.amount, paymentConfigurator?.balance?.currency)}
        </Grid>
      </Grid>
    </Grid>}
    <Grid item xs={12}>
      <PaymentActivationServicePlan />
    </Grid>
  </MinisiteCard>;
}
