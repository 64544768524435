import * as OlbActionTypes from "./OlbActionTypes";
import { Reducer } from "react";
import Action from "../../common/models/Action";
import OlbState from "./OlbState";
import ProviderData from "../../common/state/model/ProviderData";
import StepData from "./model/StepData";
import AppointmentData from "./model/AppointmentData";
import PatientInfoData from "./model/PatientInfoData";
import CancellationPolicyData from "./model/CancellationPolicyData";
import ComplaintsPolicyData from "./model/ComplaintsPolicyData";
import TermsOfUseData from "./model/TermsOfUseData";
import NhsFeeData from "./model/NhsFeeData";
import { forEach, includes, isEmpty, isNil, isNumber, isString, set, sortBy, uniq } from "lodash-es";
import { updateAnalytics } from "../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../common/analytics/AnalyticsConstants";
import EventRequest from "./model/EventRequest";
import { _findCardWithType, _findJSONSchemaDocumentCardsWithIdentifierOf } from "../../common/state/CommonReducerHelpers";
import { CommonActionTypes } from "../../common/state/CommonActionTypes";
import PageData from "./model/PageData";
import MarketingConsentData from "./model/MarketingConsentData";
import { findDocumentByInternalPatientId, findDocumentCardByInternalPatientId } from "../utilities/GeneralOnlineBookingHelpers";
import { PatientConverters, PatientTransients, validatePatientField } from "./model/Patient";
import { parseISO } from "date-fns";
import EventSelectionData from "./model/EventSelectionData";
import ConfiguratorFieldOptions from "./model/ConfiguratorFieldIOptions";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { setDefaultForMobileNumberCountry } from "../utilities/PhoneNumberHelpers";
import ProposedEvent from "./model/ProposedEvent";

export const olbReducer: Reducer<OlbState, Action> = (
  state: OlbState = { reasons: [], eventData: { events: [], hasReceivedTimes: false, isLoadingEvents: false } },
  action: Action = {} as Action,
): OlbState => {
  const result: OlbState = { ...state };

  switch (action.type) {
    case CommonActionTypes.RECEIVE_PERSPECTIVE: {
      result.providerData = _reduceProviderData(action.state.stacks);
      result.stepData = _reduceStep(action.state.stacks);
      result.appointmentData = _reduceAppointment(action.state.stacks);
      result.eventSelectionData = _reduceEventSelectionData(result.appointmentData);
      result.patientInfoData = _reducePatientInfo(action.state.stacks);
      result.cancellationPolicyData = _reduceCancellationPolicy(action.state.stacks);
      result.complaintsPolicyData = _reduceComplaintsPolicy(action.state.stacks);
      result.termsOfUseData = _reduceTermsOfUse(action.state.stacks);
      result.marketingConsentData = _reduceMarketingConsent(action.state.stacks);
      result.nhsFeeData = _reduceNhsFees(action.state.stacks);
      result.pageData = _reducePage(action.state.stacks);

      break;
    }
    case OlbActionTypes.RECEIVE_PRODUCT_CONFIGURATION: {
      result.productConfiguration = undefined;
      if (action.state instanceof Array) {
        const targetFields = {};
        const sourceFields = action.state[0].target.options.fields;
        for (const key in sourceFields) {
          const value = sourceFields[key];
          targetFields[key] = value;
          if (!isNil(value)) {
            targetFields[key] = new ConfiguratorFieldOptions(sourceFields[key]);
          }
        }
        action.state[0].target.options.fields = targetFields;
        result.productConfiguration = action.state[0].target;
      }
      break;
    }
    case OlbActionTypes.RECEIVE_REASONS: {
      result.reasons = action.state.filter((reason) => reason.active);
      break;
    }
    case CommonActionTypes.UPDATE_DOCUMENT: {
      switch (action.documentType) {
        case DocumentConstants.APPOINTMENT:
          if (result.appointmentData != null) {
            result.appointmentData = _updateAppointmentDocument(result.appointmentData, action.params);
          }
          break;
        case DocumentConstants.PATIENT:
          result.patientInfoData = _updatePatientDocument(result.patientInfoData, action.params);
          break;
      }
      break;
    }
    case OlbActionTypes.CREATE_EVENT_REQUEST:
    case OlbActionTypes.UPDATE_EVENT_REQUEST: {
      if (isNil(result.eventRequest)) {
        result.eventRequest = {
          patientId: undefined,
          providerId: undefined,
          reasonId: undefined,
          patientType: undefined,
          payorType: undefined,
          practiceId: undefined,
          promoCode: undefined,
        };
      }

      result.eventRequest = _updateEventRequest(result.eventRequest, action.params);
      break;
    }
    case OlbActionTypes.SELECT_EVENT: {
      if (isNil(result.eventSelectionData)) {
        result.eventSelectionData = {};
      }
      result.eventSelectionData = { ...result.eventSelectionData };
      result.eventSelectionData.selectedEvent = action.params;
      break;
    }
    case OlbActionTypes.UPDATE_STARTTIME: {
      if (isNil(result.eventSelectionData)) {
        result.eventSelectionData = {};
      }
      result.eventSelectionData = { ...result.eventSelectionData };
      result.eventSelectionData.startTime = action.startTime;

      break;
    }
    case OlbActionTypes.UNLOAD_EVENTS: {
      if (!isNil(result.eventData)) {
        result.eventData.events = [];
      }
      break;
    }
    case OlbActionTypes.RECEIVE_EVENTS: {
      // Sort and Normalize the events
      let existingEvents: ProposedEvent[] = [];
      if (!action.firstEventForProvider && !isNil(result.eventData.events)) {
        existingEvents = [...result.eventData.events];
      }
      const newEvents = action.events.map((event) => {
        event.startTime = new Date(event.startTime);
        event.resourceEvents?.forEach((resourceEvent) => {
          resourceEvent.startTime = new Date(resourceEvent.startTime);
        });
        return event;
      });
      result.eventData.events = sortBy([...existingEvents, ...newEvents], ["startTime"]);
      result.eventData.hasReceivedTimes = result.eventData.events.length > 0;
      if (isNil(result.providerData)) {
        result.providerData = {};
      }
      // if (!action.firstEventForProvider) {
      result.providerData.providersWithAvailability = _reduceAvailableProviders(result.eventData.events, result.providerData?.providerDocuments);
      // }
      break;
    }
    case OlbActionTypes.LOADING_EVENTS: {
      result.eventData.isLoadingEvents = action.isLoading;
      break;
    }
    case OlbActionTypes.REMOVE_EVENT: {
      set(result, "eventSelectionData.selectedEvent", undefined);
      set(result, "appointmentData.appointmentDocument.duration", undefined);
      set(result, "appointmentData.appointmentDocument.startTime", undefined);
      result.appointmentData?.appointmentDocument.sources.forEach((source) => {
        source.scheduledEventsProposed = undefined;
      });
      break;
    }
    case OlbActionTypes.DEPOSIT_FAILED: {
      result.paymentError = action.error;
    }
  }
  return result;
};

function _reduceAvailableProviders(events: ProposedEvent[], providerDocuments) {
  const providers: any[] = [];
  if (isEmpty(events)) {
    return providers;
  }

  const providerIds = events.map((window) => {
    const primaryResourceEvent = window.resourceEvents?.find((event) => {
      return event.startTime.getTime() === window.startTime.getTime();
    });
    if (!isNil(primaryResourceEvent)) {
      return primaryResourceEvent.resourceName;
    }
  });

  const uniqueProviderIds = uniq(providerIds);

  if (!isNil(providerDocuments)) {
    uniqueProviderIds.forEach((providerId) => {
      providers.push(providerDocuments.find((document) => providerId === document.id));
    });
  }
  return sortBy(providers, ["name"]);
}

function _reduceProviderData(stacks: [] = []) {
  const result: ProviderData = {
    providerDocumentCards: [],
    providerDocuments: [],
  };

  _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "9").forEach((card: any) => {
    result.providerDocumentCards?.push(card);
    result.providerDocuments?.push(card.body.value);
  });
  return result;
}

function _reduceStep(stacks) {
  const result: StepData = {
    stepCard: null,
    step: null,
  };
  result.stepCard = _findCardWithType(stacks, "com_threepointdata_bosv31_workflow_instance_Step");
  if (result.stepCard !== null) {
    result.step = result.stepCard.body;
  }

  return result;
}

function _reduceAppointment(stacks) {
  const result: AppointmentData = {
    appointmentDocumentCard: null,
    appointmentDocument: null,
  };
  const appointmentDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "5");
  result.appointmentDocumentCard = appointmentDocumentCards.length > 0 ? appointmentDocumentCards[0] : null;
  result.appointmentDocument = result.appointmentDocumentCard !== null ? result.appointmentDocumentCard.body.value : null;
  _normalizeAppointment(result.appointmentDocument);

  return result;
}

function _reduceEventSelectionData(appointmentData: AppointmentData) {
  const result: EventSelectionData = { startTime: undefined };
  result.startDate = appointmentData.appointmentDocument?.properties?.date;
  return result;
}

function _reducePatientInfo(stacks) {
  const result: PatientInfoData = {};
  _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "10").forEach((card: any) => {
    if (isNil(result.patientDocumentCards) || isNil(result.patientDocuments)) {
      result.patientDocumentCards = [];
      result.patientDocuments = [];
    }
    result.patientDocumentCards.push(card);
    result.patientDocuments.push(card.body.value);
  });
  result.patientDocumentCard = result.patientDocumentCards?.[0];
  result.patientDocument = result.patientDocuments?.[0];

  if (!isNil(result.patientDocument)) {
    result.patientDocument.dateofbirth = !isNil(result.patientDocument.dateofbirth) ? new Date(result.patientDocument.dateofbirth) : undefined;
    result.patientDocument.mobileNumberCountry = setDefaultForMobileNumberCountry(result.patientDocument.mobilenumber);
    result.patientDocument.errors = validatePatientField(undefined, undefined, result.patientDocument);
  }

  return result;
}

function _reduceCancellationPolicy(stacks) {
  const result: CancellationPolicyData = {
    cancellationPolicyDocument: null,
    cancellationPolicyDocumentCard: null,
  };
  const cancellationPolicyDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "6");
  result.cancellationPolicyDocumentCard = cancellationPolicyDocumentCards.length > 0 ? cancellationPolicyDocumentCards[0] : null;
  result.cancellationPolicyDocument = result.cancellationPolicyDocumentCard !== null ? result.cancellationPolicyDocumentCard.body.value : null;

  return result;
}

function _reduceComplaintsPolicy(stacks) {
  const result: ComplaintsPolicyData = {
    complaintsPolicyDocument: null,
    complaintsPolicyDocumentCard: null,
  };
  const complaintsPolicyDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "7");
  result.complaintsPolicyDocumentCard = complaintsPolicyDocumentCards.length > 0 ? complaintsPolicyDocumentCards[0] : null;
  result.complaintsPolicyDocument = result.complaintsPolicyDocumentCard !== null ? result.complaintsPolicyDocumentCard.body.value : null;

  return result;
}

function _reduceTermsOfUse(stacks) {
  const result: TermsOfUseData = {
    termsOfUseDocumentCard: null,
    termsOfUseDocument: null,
  };
  const termsOfUseDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "28");
  result.termsOfUseDocumentCard = termsOfUseDocumentCards.length > 0 ? termsOfUseDocumentCards[0] : null;
  result.termsOfUseDocument = result.termsOfUseDocumentCard !== null ? result.termsOfUseDocumentCard.body.value : null;

  return result;
}

function _reduceMarketingConsent(stacks) {
  const result: MarketingConsentData = {
    marketingConsentDocumentCard: null,
    marketingConsentDocument: null,
  };
  const marketingConsentDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "29");
  result.marketingConsentDocumentCard = marketingConsentDocumentCards.length > 0 ? marketingConsentDocumentCards[0] : null;
  result.marketingConsentDocument = result.marketingConsentDocumentCard !== null ? result.marketingConsentDocumentCard.body.value : null;
  return result;
}

function _reducePage(stacks): PageData {
  const result: PageData = {
    pageDocument: null,
    pageDocumentCard: null,
  };

  const pageDocumentCards = _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "21");
  result.pageDocumentCard = pageDocumentCards.length > 0 ? pageDocumentCards[0] : null;
  result.pageDocument = result.pageDocumentCard?.body?.value;

  return result;
}

function _reduceNhsFees(stacks): NhsFeeData {
  const result: NhsFeeData = {
    nhsFeeDocumentCards: [],
    nhsFeeDocuments: [],
    nhsFees: [],
  };
  _findJSONSchemaDocumentCardsWithIdentifierOf(stacks, "14").forEach((card: any) => {
    result.nhsFeeDocumentCards?.push(card);
    result.nhsFeeDocuments?.push(card.body);
    result.nhsFees?.push(card.body.value);
  });

  return result;
}

function _updateEventRequest(eventRequest: EventRequest, params: any): EventRequest {
  const result = { ...eventRequest };
  forEach(params, (value, key) => {
    if (key in result) {
      set(result, key, value);
    }
  });
  return result;
}

function _populateActionWithSources(appointmentData: AppointmentData) {
  const sources = appointmentData?.appointmentDocument?.sources;
  if (isNil(sources) || isEmpty(sources)) {
    _populateAppointmentUpdateActionWithValue(appointmentData, "sources", undefined);
    updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
      event: "gtm.pageError",
      errorLineNumber: 88,
      errorUrl: "services/appointment.js",
      errorMessage: "appointmentDocument.sources is empty, setting it to undefined.  Look into this, it may be okay.",
    });
  } else {
    _populateAppointmentUpdateActionWithValue(appointmentData, "sources", sources);
  }
}

function _populateAppointmentUpdateActionWithValue(appointmentData: AppointmentData, field, value) {
  if (isEmpty(appointmentData.appointmentDocumentCard.actions[0].data1)) {
    updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
      event: "gtm.pageError",
      errorLineNumber: 106,
      errorUrl: "services/appointment.js",
      errorMessage: "Unable to update action with value: appointmentDocumentCard.actions.firstObject.data is empty",
    });
    return;
  } else {
    const data1 = { ...appointmentData.appointmentDocumentCard.actions[0].data1 };
    const actionValueObject = JSON.parse(data1.value);
    actionValueObject[field] = value;
    data1.value = JSON.stringify(actionValueObject);
    appointmentData.appointmentDocumentCard.actions[0].data1 = data1;
  }
}

function _updateAppointmentDocument(appointmentData: AppointmentData, params: any): AppointmentData {
  if (params.source) {
    const sourceFound = appointmentData.appointmentDocument.sources.find((source) => source.sourceId === params.source.sourceId);
    if (sourceFound) {
      const source = { ...sourceFound };
      forEach(params.properties, (value, key) => {
        if (key in source) {
          set(source, key, value);
        }
      });

      // Because a source was changed we need to remove the old instance and set the sources array with the new one
      const index = appointmentData.appointmentDocument.sources.findIndex((source) => source.sourceId === params.source.sourceId);
      const sources = [...appointmentData.appointmentDocument.sources];
      sources.splice(index, 1);
      sources.splice(index, 0, source);
      appointmentData.appointmentDocument.sources = sources;

      _populateActionWithSources(appointmentData);
    } else {
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        event: "gtm.pageError",
        errorLineNumber: 38,
        errorUrl: "services/appointment.js",
        errorMessage: `unable to find source in appointmentDocument.sources: sourceId = ${params.source.sourceId}`,
      });
    }
  } else if (params.transaction) {
    const payment = appointmentData.appointmentDocument.payment;
    const transaction = payment.transactions[0];
    forEach(params.transaction, (value, key) => {
      set(transaction, key, value);
    });
    _populateAppointmentUpdateActionWithValue(appointmentData, "payment", payment);
  } else {
    const appointmentDocument = { ...appointmentData.appointmentDocument };
    forEach(params, (value, key) => {
      if (key in appointmentDocument || key === "startTime") {
        appointmentDocument[key] = value;
        _populateAppointmentUpdateActionWithValue(appointmentData, key, value);
      }
    });
    appointmentData.appointmentDocument = appointmentDocument;
  }

  return appointmentData;
}

function _updatePatientDocument(patientInfoData: PatientInfoData | undefined, params: any) {
  if (params.internalPatientId) {
    const patientDocumentFound = findDocumentByInternalPatientId(params.internalPatientId, patientInfoData?.patientDocuments);
    if (patientDocumentFound) {
      const patientDocument = { ...patientDocumentFound };
      forEach(params.properties, (value, key) => {
        // if (key in patientDocument) {
        // if (Object.getOwnPropertyDescriptor(Object.getPrototypeOf(patientDocument), key)) {
        set(patientDocument, key, value);
        patientDocument.errors = validatePatientField(key, null, patientDocument);

        if (!isNil(patientInfoData) && !isNil(patientInfoData.patientDocuments)) {
          const index = patientInfoData.patientDocuments.findIndex((patient) => patient.internalPatientId === params.internalPatientId);
          const patientDocuments = [...patientInfoData.patientDocuments];
          patientDocuments.splice(index, 1);
          patientDocuments.splice(index, 0, patientDocument);
          patientInfoData.patientDocuments = patientDocuments;
          // Update state for the patientInfoData.patientDocument if the internalPatientId matches.
          if (patientDocument.internalPatientId === patientInfoData.patientDocument?.internalPatientId) {
            patientInfoData.patientDocument = patientDocument;
          }
        }

        // Perform any conversion before adding it to the action
        if (PatientConverters[key]) {
          value = PatientConverters[key](value);
        }
        if (!includes(PatientTransients, key)) {
          _populatePatientUpdateActionWithValue(
            key,
            value,
            findDocumentCardByInternalPatientId(params.internalPatientId, patientInfoData?.patientDocumentCards),
          );
        }
        // }
      });
    } else {
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        event: "gtm.pageError",
        errorLineNumber: 50,
        errorUrl: "services/patient.js",
        errorMessage: `unable to find patientDocument in patientDocumentCards with internalId = ${params.internalPatientId}`,
      });
    }
  } else {
    forEach(params, (value, key) => {
      const patientDocumentFound = patientInfoData?.patientDocument;
      // if (!isNil(patientDocumentFound) && key in patientDocumentFound) {
      const patientDocument = { ...patientDocumentFound };
      set(patientDocument, key, value);
      patientDocument.errors = validatePatientField(key, null, patientDocument);

      if (!isNil(patientInfoData)) {
        patientInfoData.patientDocument = patientDocument;
      }

      // Perform any conversion before adding it to the action
      if (PatientConverters[key]) {
        value = PatientConverters[key](value);
      }

      if (!includes(PatientTransients, key)) {
        _populatePatientUpdateActionWithValue(key, value, patientInfoData?.patientDocumentCard);
      }
      // }
    });
  }

  return patientInfoData;
}

function _normalizeAppointment(appointment: any) {
  if (!isNil(appointment)) {
    appointment.sources.forEach((source) => {
      const startTime = source.scheduledEventsProposed?.startTime;
      if (isNumber(startTime) || isString(startTime)) {
        source.scheduledEventsProposed.startTime = new Date(startTime);
      }
      source.resourceEvents?.forEach((resourceEvent) => {
        const startTime = resourceEvent.startTime;
        if (isNumber(startTime) || isString(startTime)) {
          resourceEvent.startTime = new Date(startTime);
        }
      });
    });
    if (!isNil(appointment.properties) && !isNil(appointment.properties.date)) {
      appointment.properties.date = parseISO(appointment.properties.date);
    }
    // Add in other fields and initialize to undefined
    appointment.startTime = appointment.startTime ? new Date(appointment.startTime) : undefined;
    appointment.subject = appointment.subject ? appointment.subject : undefined;
    appointment.question1 = appointment.question1 ? appointment.question1 : undefined;
    appointment.question2 = appointment.question2 ? appointment.question2 : undefined;
    appointment.question3 = appointment.question3 ? appointment.question3 : undefined;
    appointment.appointmentType = appointment.appointmentType ? appointment.appointmentType : undefined;
    appointment.dueDate = appointment.dueDate ? appointment.dueDate : undefined;
    appointment.reason = appointment.reason ? appointment.reason : undefined;
    appointment.insuranceType = appointment.insuranceType ? appointment.insuranceType : undefined;
    appointment.promoCode = appointment.promoCode ? appointment.promoCode : undefined;
    appointment.payment = appointment.payment ? appointment.payment : undefined;
  }
}

function _populatePatientUpdateActionWithValue(field, value, patientDocumentCard) {
  if (isEmpty(patientDocumentCard)) {
    return;
  }
  const actionValueObject = JSON.parse(patientDocumentCard.actions[0].data1.value);
  actionValueObject[field] = value;
  patientDocumentCard.actions[0].data1.value = JSON.stringify(actionValueObject);
}
