import React from "react";
import "./App.css";
import Main from "./Main";
import { connect } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import BasicLayout from "./common/components/BasicLayout";
import { useCookieConsent } from "./common/hooks/useCookieConsent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { locales } from "./common/utilities/GeneralHelpers";
import { useBrowserSupported } from "./common/hooks/useBrowserSupported";

const App = (): React.JSX.Element => {
  useBrowserSupported();
  useCookieConsent();
  const theme = createTheme(
    (window as any).ThreePointData && (window as any).ThreePointData.color
      ? {
            palette: {
                secondary: {
                    main: (window as any).ThreePointData.color,
                },
            },
        }
      : {},
  );

  return (
    <LocalizationProvider adapterLocale={locales[(window as any).ThreePointData.localeLanguage]} dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <BasicLayout>
          <Main />
        </BasicLayout>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default connect()(App);
