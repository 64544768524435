import { find, isEmpty, isNil } from "lodash-es";
import WeekDay from "../state/model/WeekDay";
import { addDays, isEqual } from "date-fns";
import Row from "../state/model/Row";
import { v4 as uuid } from "uuid";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import ProposedEvent from "../state/model/ProposedEvent";
import Patient from "../state/model/Patient";
import { updateAnalytics } from "../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../common/analytics/AnalyticsConstants";

export function findDocumentByInternalPatientId(internalPatientId: string, patientDocuments?: Patient[]): Patient | undefined {
  let result: Patient | undefined = undefined;
  if (!isNil(patientDocuments)) {
    result = patientDocuments.find((document) => document.internalPatientId == internalPatientId);
  }
  return result;
}

export function findDocumentCardByInternalPatientId(internalPatientId: string, patientDocumentCards?: any[]) {
  let result: any | undefined = undefined;
  if (!isNil(patientDocumentCards)) {
    result = patientDocumentCards.find((card) => card.body.value.internalPatientId === internalPatientId);
  }
  return result;
}

export function isDuringUpcomingWeek(dateToCheck: Date, weekdays: WeekDay[]) {
  let found = false;

  weekdays.forEach((weekday) => {
    if (isOnSameDay(dateToCheck, weekday.startRange(), weekday.endRange())) {
      found = true;
    }
  });
  return found;
}

export function isOnSameDay(sourceDate: Date, startRange?: Date, endRange?: Date) {
  if (isNil(startRange) || isNil(endRange) || isNil(sourceDate)) {
    return false;
  }

  return +sourceDate >= +startRange && +sourceDate <= +endRange;
}

export function addToRowCollection(windowSlot, rows: Row[], selectedEvent, weekDays) {
  let added = false,
    newRow;

  rows.every((row) => {
    row.days.every((day) => {
      if (isOnSameDay(windowSlot.startTime, day.weekday.startRange(), day.weekday.endRange())) {
        if (isNil(day.time)) {
          const selected = isEqual(selectedEvent?.startTime, windowSlot.startTime);
          day.time = windowSlot;
          day.selected = selected;
          added = true;
        }
      }
      return !added;
    });
    return !added;
  });

  if (!added) {
    newRow = _createRowObject(windowSlot, weekDays, selectedEvent);
    rows.push(newRow);
  }
}

export function processWeekDays(timezone: string, numOfDays: number, startTime?: Date) {
  const list: WeekDay[] = [];
  if (isNil(startTime)) {
    return list;
  } else {
    const zonedStartTime = utcToZonedTime(startTime, timezone);

    for (let i = 0; i < numOfDays; i++) {
      let day = addDays(zonedStartTime, i);
      day = zonedTimeToUtc(day, timezone);
      const description = formatInTimeZone(day, timezone, "EEE MMM d");
      list.push(new WeekDay(day, description, [], timezone));
    }
    return list;
  }
}

export function processAvailableEvents(proposedEventsFiltered: ProposedEvent[] | undefined, weekDays: WeekDay[], selectedEvent): Row[] {
  const rows: Row[] = [];

  if (!isEmpty(proposedEventsFiltered)) {
    //// Loop through all windows
    const windowSlots: ProposedEvent[] = [];
    const lastDayOfWeekDays = weekDays && weekDays.length > 0 ? weekDays[weekDays.length - 1].endRange() : undefined;
    const emptySourceCounter: any[] = [];
    proposedEventsFiltered?.every((windowSlot) => {
      // Check for valid startTime
      if (!isNil(windowSlot.startTime)) {
        // Added this check to gather additional information about why this is empty sometimes
        let resourceEventSources = windowSlot.resourceEvents?.map((resourceEvent) => resourceEvent.source);
        resourceEventSources = resourceEventSources.filter((source) => source);
        if (isEmpty(resourceEventSources)) {
          emptySourceCounter.push({
            id: windowSlot.id,
            re: windowSlot.resourceEvents?.length,
            s: resourceEventSources.length,
          });
        }

        if (!isNil(lastDayOfWeekDays) && windowSlot.startTime < lastDayOfWeekDays) {
          // Check if window falls on one of the days in the coming week
          if (isDuringUpcomingWeek(windowSlot.startTime, weekDays)) {
            // Only add 1 event for each startTime
            const alreadyAddedSlot = find(windowSlots, (slot) => {
              return slot.startTime?.getTime() === windowSlot.startTime?.getTime();
            });

            if (!alreadyAddedSlot) {
              windowSlots.push(windowSlot);
            }
          }
          return true;
        } else {
          return false;
        }
      }
    });

    let index, len;
    for (index = 0, len = windowSlots.length; index < len; ++index) {
      addToRowCollection(windowSlots[index], rows, selectedEvent, weekDays);
    }

    if (emptySourceCounter.length > 0) {
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        event: "gtm.pageError",
        errorLineNumber: 165,
        errorUrl: "components/time-selection.js",
        errorMessage: `Some events are invalid: ${JSON.stringify(emptySourceCounter)}`,
      });
    }
  }

  return rows;
}

function _createRowObject(windowSlot, weekDays, selectedEvent): Row {
  const row: Row = {
    id: uuid(),
    days: [],
  };

  weekDays.forEach((weekday) => {
    if (!isNil(windowSlot) && isOnSameDay(windowSlot.startTime, weekday.startRange(), weekday.endRange())) {
      const selected = isEqual(selectedEvent?.startTime, windowSlot.startTime);
      row.days.push({
        weekday: weekday,
        time: windowSlot,
        selected: selected,
      });
    } else {
      row.days.push({
        weekday: weekday,
        time: undefined,
        selected: false,
      });
    }
  });
  return row;
}
