import MultipleGoogleAnalytics4 from "./MultipleGoogleAnalytics4";
import MultipleGoogleTagManager from "./MultipleGoogleTagManager";
import { isPerformanceCookiesEnabled, removeUnauthorizedCookies } from "../../onlinebooking/utilities/CookieConsent";
import ServerAnalyticsLogger from "./ServerAnalyticsLogger";

const multipleGoogleAnalytics = new MultipleGoogleAnalytics4();
const multipleGoogleTagManager = new MultipleGoogleTagManager();
const serverAnalyticsLogger = new ServerAnalyticsLogger();

export function setup(analyticsData) {
  if (isPerformanceCookiesEnabled() || !(window as any).ThreePointData.otCookieConsentId) {
    initialize(analyticsData);
  }
  (window as any).OptanonWrapper = () => {
    if (isPerformanceCookiesEnabled()) {
      initialize(analyticsData);
    } else {
      removeUnauthorizedCookies();
    }
  };
}

function initialize(analyticsData) {
  multipleGoogleAnalytics.init(analyticsData);
  multipleGoogleTagManager.init(analyticsData);
}

export function trackEvent(params: any) {
  serverAnalyticsLogger.trackEvent(params);
  multipleGoogleAnalytics.trackEvent(params);
  multipleGoogleTagManager.trackEvent(params);
}

export function trackPage(params: any) {
  serverAnalyticsLogger.trackPage(params);
  multipleGoogleAnalytics.trackPage(params);
  multipleGoogleTagManager.trackPage(params);
}
