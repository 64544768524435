import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchPerspective, reload } from "../common/state/CommonActions";
import { useAppDispatch } from "../common/hooks/useAppDispatch";
import { PracticeInformation } from "./components/PracticeInformation";
import { ProviderInfoList } from "./components/ProviderInfoList";
import { PaymentActivation } from "./components/PaymentActivation";
import { AppointmentSettings } from "./components/AppointmentSettings";
import { AdminDateSelector } from "./components/AdminDateSelector";
import { ApplicationState } from "../common/state/model/ApplicationState";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { appointmentSettingsHasEditPermissions } from "./state/MiniSiteSelectors";
import * as MetricActions from "../common/metrics/MetricActions";
import { useTranslation } from "react-i18next";
import { isEmpty, isNil } from "lodash-es";

const MiniSite = ({}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const showProviderOnMinisite = useSelector((state: ApplicationState) => state.common?.config?.showProviderOnMinisite);
  const isAuthenticated = useSelector((state: ApplicationState) => state.session.sessionData?.isAuthenticated);
  const appointmentSettingsHasEditPermission = useSelector(appointmentSettingsHasEditPermissions);
  const adminDateSelectorCard = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.adminDateSelectorCard);
  const practiceDocument = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument);
  const paymentMode = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.mode);
  const analyticsData = useSelector((state: ApplicationState) => state.common?.analyticsData);
  const [isPerspectiveLoading, setIsPerspectiveLoading] = useState(false);

  useEffect(() => {
    setIsPerspectiveLoading(true);
    dispatch(reload("1", true)).then(() => {
      setIsPerspectiveLoading(false);
    });
  }, [dispatch]);

  if (isPerspectiveLoading) {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <CircularProgress size={100} />
        </div>
        <Typography variant="h5" align={"center"} style={{ marginTop: 20 }}>
          {t("perspective.loading.minisite.text")}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <PracticeInformation practiceDocument={practiceDocument} />
        </Grid>

        {showProviderOnMinisite && (
          <Grid item xs={12}>
            <ProviderInfoList />
          </Grid>
        )}
        {isAuthenticated && (
          <>
            <Grid item className={"deposits-settings"} sm={6}>
              {!isNil(paymentMode) && !isEmpty(paymentMode) && <PaymentActivation paymentMode={paymentMode}></PaymentActivation>}
              {appointmentSettingsHasEditPermission && (
                <>
                  <AppointmentSettings></AppointmentSettings>
                </>
              )}
            </Grid>

            <Grid item sm={6}>
              {adminDateSelectorCard && <AdminDateSelector></AdminDateSelector>}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default connect()(MiniSite);
