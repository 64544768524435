import { formatCurrency } from "../../../common/utilities/GeneralHelpers";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { isNil } from "lodash-es";
import { useTranslation } from "react-i18next";
import { Money } from "../../../common/models/Money";

export const SalesInformation = ({ price, deposit }: { price: Money; deposit: Money }) => {
  const { t } = useTranslation();
  const hidePriceAndDeposit: boolean | undefined = useSelector((state: ApplicationState) => state.common?.config?.hidePriceAndDeposit);
  const hasSalesInformation: boolean = useMemo(() => !isNil(price) || !isNil(deposit), [price, deposit]);

  return (
    <>
      {!hidePriceAndDeposit && hasSalesInformation ? (
        <div className="appointmentTime pull-left" data-testid={"t-appointmentsummary-salesinformation"}>
          <div className="date">
            {price && (
              <>
                <span className="mon">{t("booking.summary.payment.price.label")}</span>
                <span className="day" style={{ fontSize: 15 }}>
                  {formatCurrency(price.amount, price.currency)}
                </span>
              </>
            )}
            {deposit && (
              <>
                <span className="mon" style={{ marginTop: 5 }}>
                  {t("booking.summary.payment.deposit.label")}
                </span>
                <span className="day" style={{ fontSize: 15 }}>
                  {formatCurrency(deposit.amount, deposit.currency)}
                </span>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
