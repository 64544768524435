import React, { useMemo, useState } from "react";
import { Button, Grid, Menu, MenuItem, TextField } from "@mui/material";
import { debounce, isNil } from "lodash-es";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TEXTFIELD_DEBOUNCE_WAIT } from "../../../common/constants/HelperConstants";
import { useTranslation } from "react-i18next";
import { formatE164 } from "../../../common/utilities/PhoneFormatWrapper";

export function PhoneNumber({
  countries,
  selectedCountry,
  phoneNumber,
  error,
}: {
  countries: any[];
  selectedCountry: any;
  phoneNumber: any;
  error: string;
}): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [phoneNumberInternal, setPhoneNumberInternal] = useState(phoneNumber || "");
  const [hasBeenTouched, setBeenTouched] = useState(!!phoneNumber);
  const hasError = !isNil(error) && hasBeenTouched;
  const debouncedOnChanged = useMemo(() => debounce(onChanged, TEXTFIELD_DEBOUNCE_WAIT), []);

  function onChangedInternal(event) {
    setPhoneNumberInternal(event.target.value);
    debouncedOnChanged(event.target.value, selectedCountry);
  }

  function onChanged(value, selectedCountryInput) {
    let result = value;
    if (!isNil(selectedCountryInput)) {
      result = formatE164(selectedCountryInput, value);
      dispatch(updateDocument(DocumentConstants.PATIENT, { mobilenumber: result }));
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function countrySelected(event) {
    dispatch(updateDocument(DocumentConstants.PATIENT, { mobileNumberCountry: event.currentTarget.dataset.myValue }));
    setPhoneNumberInternal(phoneNumber);
    handleClose();
  }

  function onBlur() {
    if (!hasBeenTouched) {
      const result = formatE164(selectedCountry, phoneNumberInternal);
      dispatch(updateDocument(DocumentConstants.PATIENT, { mobilenumber: result }));
      setBeenTouched(true);
    }
  }

  return (
    <>
      <Grid container sx={{ "& .MuiFormControl-root": { m: 0, width: 245 }, "ml": 2 }}>
        <Grid item alignItems={"stretch"} style={{ display: "flex" }}>
          <Button
            id="phonenumber-country-button"
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 80 }}
            aria-controls={open ? "phonenumber-country-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            size="large"
            disableElevation
            // sx={{  }}
            onClick={handleClick}
            endIcon={<FontAwesomeIcon icon={faCaretDown} data-testid={"t-patient-country"} />}
          >
            {selectedCountry}
          </Button>

          <Menu
            id="phonenumber-country-menu"
            MenuListProps={{
              "aria-labelledby": "phonenumber-country-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            data-testid={"t-patient-country-menu"}
          >
            {countries.map((country: any) => {
              return (
                <MenuItem key={country.code} data-my-value={country.code} onClick={countrySelected} data-testid={"t-patient-country-item-" + country.code}>
                  {country.code} - {country.name}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
        <Grid item>
          <TextField
            sx={{ "& .MuiInputBase-root": { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
            type="tel"
            name="phone"
            label={t("booking.patientInformation.contactInformation.phone.label")}
            value={phoneNumberInternal}
            inputProps={{ maxLength: 19 }}
            error={hasError}
            required
            onChange={onChangedInternal}
            onBlur={onBlur}
            data-testid={"t-patient-phonenumber"}
          />
        </Grid>
      </Grid>
    </>
  );
}
