import React from "react";
import { MinisiteCard } from "./MinisiteCard";
import { ProviderInfo } from "./ProviderInfo";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { Grid } from "@mui/material";

export function ProviderInfoList(): React.JSX.Element {
  const providers = useSelector((state: ApplicationState) => state.olb?.providerData?.providerDocuments);
  return (
    <MinisiteCard cardHeader={"Providers"}>
      <Grid container style={{ margin: 10 }}>
        {providers?.map((provider) => (
          <ProviderInfo key={provider.id} provider={provider} />
        ))}
      </Grid>
    </MinisiteCard>
  );
}
