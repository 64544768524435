import React from "react";
import { AppointmentCards } from "../../AppointmentCards";
import { CancellationComplaints } from "../../CancellationComplaints";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { Box } from "@mui/material";
import { AppointmentCardStateSuccess } from "../../AppointmentCardStateSuccess";
import { isCallCenterActive } from "../../../../common/utilities/CallCenter";

export function Synchronized({ showMedicalInformation = false }: { showMedicalInformation?: boolean }): React.JSX.Element {
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const callCenterActive = isCallCenterActive();

  return (
    <Box>
      <AppointmentCardStateSuccess />
      {appointmentDocument && <AppointmentCards showAddToCalendar={!callCenterActive} readonly={true} showMedicalInformation={showMedicalInformation} />}
      <CancellationComplaints />
    </Box>
  );
}
