import { isEmpty, isNil } from "lodash-es";
import { Iso5218Sex } from "../../constants/Iso5218Sex";
import { subYears } from "date-fns";
import { isValidNumber } from "../../../common/utilities/PhoneFormatWrapper";

export const PatientTransients: string[] = ["mobileNumberCountry", "errors"];

export const PatientConverters = {
  dateofbirth: (value) => {
    if (isNil(value)) {
      return undefined;
    } else {
      return value.getTime();
    }
  },
};

export default interface Patient {
  title?: string;
  firstname?: string;
  lastname?: string;
  sex?: string;
  iso5218Sex?: string;
  dateofbirth?: Date;
  landlinenumber?: string;
  mobilenumber?: string;
  emailaddress?: string;
  postcode?: string;
  verificationCode?: string;
  hasEnteredVerificationCode?: boolean;
  internalPatientId?: string;
  externalPatientId?: string;
  smsChannel?: boolean;
  emailChannel?: boolean;
  acceptedTermsOfUseChangeDate?: Date;
  acceptedTermsOfUse?: boolean;
  acceptedMarketingConsentChangeDate?: Date;
  acceptedMarketingConsent?: boolean;
  mobileNumberCountry?: string;
  errors?: any;
}

export const validatePatientField = (field?: string, value?: any, sourcePatient?: Patient) => {
  const errors = { ...sourcePatient?.errors };

  if (isNil(field)) {
    errors["iso5218Sex"] = _validators.iso5218Sex.call(this, sourcePatient?.iso5218Sex);
    errors["lastname"] = _validators.lastname.call(this, sourcePatient?.lastname);
    errors["firstname"] = _validators.firstname.call(this, sourcePatient?.firstname);
    errors["dateofbirth"] = _validators.dateofbirth.call(this, sourcePatient?.dateofbirth);
    errors["mobilenumber"] = _validators.mobilenumber.call(this, sourcePatient?.mobilenumber, sourcePatient?.mobileNumberCountry);
    errors["emailaddress"] = _validators.emailaddress.call(this, sourcePatient?.emailaddress);
  } else {
    const valueToTest = isNil(value) && !isNil(sourcePatient) ? sourcePatient[field] : value;
    let validator = _validators[field];
    if ("mobileNumberCountry" === field) {
      validator = _validators["mobilenumber"];
    }
    if (validator) {
      if ("mobilenumber" === field) {
        errors[field] = validator.call(this, valueToTest, sourcePatient?.mobileNumberCountry);
      } else if ("mobileNumberCountry" === field) {
        errors["mobilenumber"] = validator.call(this, sourcePatient?.mobilenumber, valueToTest);
      } else {
        errors[field] = validator.call(this, valueToTest);
      }
    }
  }
  return errors;
};

const _validators = {
  iso5218Sex: function (value) {
    const { FEMALE, MALE, NOT_APPLICABLE } = Iso5218Sex;

    if (![FEMALE, MALE, NOT_APPLICABLE].includes(value)) {
      return "Please enter an ISO sex";
    }
  },

  sex: function (value) {
    if (isEmpty(value)) {
      return "Please enter a sex.";
    }
  },

  firstname: function (value) {
    if (isEmpty(value)) {
      return "Please enter a first name.";
    }
  },

  lastname: function (value) {
    if (isEmpty(value)) {
      return "Please enter a last name.";
    }
  },

  dateofbirth: function (value) {
    if (isNil(value) || isNaN(value) || isNil(value.getTime)) {
      return "Please enter a valid date of birth.";
    }
    if (value.getTime() > new Date().getTime()) {
      return "Please enter a date that is earlier than today.";
    }
    if (value.getTime() < subYears(new Date().setHours(0, 0, 0, 0), 120).getTime()) {
      return "Are you really older than a 120?";
    }
  },

  mobilenumber: function (phone, mobileNumberCountry) {
    if (isEmpty(phone) || isEmpty(mobileNumberCountry) || !isValidNumber(phone, mobileNumberCountry)) {
      return "Please enter a valid mobile number.";
    }
  },

  emailaddress: function (email) {
    const re =
      /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    if (isEmpty(email) || !re.test(email)) {
      return "Please enter a valid email address";
    }
  },
};
