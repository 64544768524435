import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { countryCode } from "../../../../common/utilities/PracticeInfoAware";
import isCMSActive from "../../../utilities/CmsChecker";
import { PhoneNumber } from "../../patientinformation/PhoneNumber";
import { Countries } from "../../../constants/Countries";
import { updateDocument } from "../../../../common/state/CommonActions";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";
import { GivenName } from "../../patientinformation/GivenName";
import { FamilyName } from "../../patientinformation/FamilyName";
import { DateOfBirth } from "../../patientinformation/DateOfBirth";
import { Sex } from "../../patientinformation/Sex";
import { Email } from "../../patientinformation/Email";
import { Gms } from "./Gms";
import { isNil } from "lodash-es";

const countries = _.sortBy(Countries, ["code"]);

export function PatientInformationHenrySchein({ source }: { source: any }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const config = useSelector((state: ApplicationState) => state.common?.config);
  const isCountryCodeAT = countryCode() === "AT";
  const isCountryCodeIE = countryCode() === "IE";
  const isPracticeCountryCodeEIRE = countryCode() == "EIRE";
  const isPracticeCountryCodeIE = countryCode() == "IE";
  const isPracticeCountryCodeAT = countryCode() == "AT";
  const supportsGMS = isCountryCodeIE || isPracticeCountryCodeEIRE || isPracticeCountryCodeIE;
  const supportsInsuranceNumber = isCountryCodeAT || isPracticeCountryCodeAT || supportsGMS;

  const displayCallback =
    useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings.displayCallback) || false;
  const callBack = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument.callBack);
  const question3 = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument.question3);
  const mobileNumberCountry = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.mobileNumberCountry);
  const mobileNumber = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.mobilenumber);
  const patientErrors = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.errors);
  const insuranceType = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument.insuranceType);

  const numberLabel =
    source.patientType === "Health Insurance" ? t("booking.patientInformation.cardNumber.label") : t("booking.patientInformation.insuranceNumber.label");

  const medicalConsiderationsLabel = isCMSActive()
    ? t("booking.patientInformation.medicalConsiderations.sales.label")
    : t("booking.patientInformation.medicalConsiderations.label");

  const isMedicalCardInsuranceType = "MedicalCard" === insuranceType;

  useEffect(() => {
    // Set patientType from insuranceType
    if (isMedicalCardInsuranceType && "Medical Card" !== source.patientType) {
      dispatch(
        updateDocument(DocumentConstants.APPOINTMENT, {
          source: { sourceId: source.sourceId },
          properties: {
            patientType: "Medical Card",
            insuranceNumber: null,
            insuranceProvider: null,
            insuranceProviderOther: null,
          },
        }),
      );
    } else if (supportsGMS && isNil(insuranceType) && "Medical Card" === source.patientType) {
      dispatch(
        updateDocument(DocumentConstants.APPOINTMENT, {
          source: { sourceId: source.sourceId },
          properties: {
            patientType: null,
            insuranceNumber: null,
            insuranceProvider: null,
            insuranceProviderOther: null,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onInsuranceNumberChanged(event) {
    dispatch(
      updateDocument(DocumentConstants.APPOINTMENT, {
        source: { sourceId: source.sourceId },
        properties: { insuranceNumber: event.target.value },
      }),
    );
  }

  function onCallBackChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(updateDocument(DocumentConstants.APPOINTMENT, { callBack: event.target.checked }));
  }

  function onAdditionalInformationChanged(event) {
    dispatch(updateDocument(DocumentConstants.APPOINTMENT, { question3: event.target.value }));
  }

  return (
    <>
      <Box sx={{ "& .MuiFormControl-root": { m: 2, width: 325 } }}>
        <Grid container>
          <Grid item xs={12}>
            <GivenName sourceId={source.sourceId} error={patientErrors?.firstname} />
            <FamilyName sourceId={source.sourceId} error={patientErrors?.lastname} />
          </Grid>
        </Grid>
        {!isCMSActive() && (
          <Grid container>
            <Grid item xs={12}>
              <DateOfBirth />
              <Sex error={patientErrors?.iso5218Sex} />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <PhoneNumber countries={countries} selectedCountry={mobileNumberCountry} phoneNumber={mobileNumber} error={patientErrors?.mobilenumber} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Email error={patientErrors?.emailaddress} />
          </Grid>
        </Grid>
        {supportsGMS && (
          <Grid container>
            <Grid item>
              <Gms source={source} isMedicalCardInsuranceType={isMedicalCardInsuranceType} />
            </Grid>
          </Grid>
        )}
        {supportsInsuranceNumber && (
          <Grid container>
            <Grid item>
              <TextField
                value={source.insuranceNumber || ""}
                inputProps={{ maxLength: 50 }}
                onChange={onInsuranceNumberChanged}
                label={numberLabel}
                data-testid={"t-patient-insurancenumber"}
              />
            </Grid>
          </Grid>
        )}
        {displayCallback && (
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{ ml: 1 }}
                label={t("booking.patientInformation.contactInformation.callBack.label")}
                control={<Checkbox name={"callBack"} checked={callBack} onChange={onCallBackChanged} data-testid={"t-patient-callback"} />}
              />
            </Grid>
          </Grid>
        )}
        {config?.allowBookingNote && (
          <Grid item sx={{ "& .MuiFormControl-root": { width: { xs: 325, sm: 682 } } }} xs={12}>
            <TextField
              multiline
              rows={3}
              label={medicalConsiderationsLabel}
              inputProps={{ maxLength: 500 }}
              value={question3}
              onChange={onAdditionalInformationChanged}
              data-testid={"t-patient-question3"}
            />
          </Grid>
        )}
      </Box>
    </>
  );
}
