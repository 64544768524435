import * as OlbActionTypes from "./OlbActionTypes";
import * as Constants from "./OlbConstants";
import { DispatchType } from "../../dispatcher";
import axios, { AxiosResponse } from "axios";
import { Dispatch } from "react";
import { assign, isEmpty, isEqual, isNil } from "lodash-es";
import { AnalyticsConstants } from "../../common/analytics/AnalyticsConstants";
import { updateAnalytics } from "../../common/analytics/AnalyticsActions";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { EventConstants } from "../constants/EventConstants";
import { organizationId } from "../../common/state/CommonActions";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { isSmallFormFactor } from "../../common/utilities/GeneralHelpers";
import {getIsOnlineBookingUnavailableUntil, getProposedEvents, getUseDateFromLink} from "./OlbSelectors";
import { CX_API_ADDRESS, HCPORTAL_V1_API_ADDRESS, HCPORTAL_V1_PRACTICES_API_ADDRESS } from "../../common/constants/PathConstants";

export function setIsLoadingEvents(isLoading: boolean) {
  return {
    type: OlbActionTypes.LOADING_EVENTS,
    isLoading,
  };
}

export function loadEvents(params) {
  return (dispatch: Dispatch<DispatchType>, getState): Promise<any> => {
    if (params.clearStore) {
      dispatch(unloadEvents());
    }

    let numOfDays: number | null;
    if (params.firstEventForProvider) {
      numOfDays = EventConstants.DEFAULT_SCHEDULE_DAYS_IN_ADVANCE;
    }
    // For Recall and Planned we need to find the first available appointment, so we need to load all appointments within the range.
    else if (isEqual(params.patientType, Constants.AppointmentType.RECALL) || isEqual(params.patientType, Constants.AppointmentType.PLANNED)) {
      numOfDays = EventConstants.DEFAULT_SCHEDULE_DAYS_IN_ADVANCE;
    } else {
      numOfDays = getNumOfDays();
    }

    const unavailableUntilDate = getIsOnlineBookingUnavailableUntil(getState());

    const timezone = getState().session.timezone;
    let startTimeUtc;
    if (!(unavailableUntilDate && params.isShapeChange)) {
      startTimeUtc = getState().olb?.eventSelectionData?.startTime;
    } else {
      startTimeUtc = getState().common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate;
    }
    startTimeUtc = isNil(startTimeUtc) ? new Date() : startTimeUtc;
    const startTimeZoned = utcToZonedTime(startTimeUtc, timezone);
    const startDayZoned = startOfDay(startTimeZoned);
    const startDayUtc = zonedTimeToUtc(startDayZoned, timezone);
    const startDayWithDaysAdded = addDays(startDayZoned, numOfDays - 1);
    const endDayZoned = endOfDay(startDayWithDaysAdded);
    const endDayUtc = zonedTimeToUtc(endDayZoned, timezone);

    const paramsAdd = {
      startDay: startDayUtc.getTime(),
      endDay: endDayUtc.getTime(),
      eventType: "Proposed",
    };
    delete params.startTime;
    const allEventsParams = assign({}, paramsAdd, params);
    return _internalLoadEvents(allEventsParams).then((windows) => {
      if ((allEventsParams.firstEventForProvider && windows.length === 0) || !allEventsParams.firstEventForProvider) {
        // Remove sensitive data like ap1...ap[n] and patientId
        const safeEventsParams = Object.keys(allEventsParams)
          .filter((key) => {
            return key.indexOf("ap") == -1;
          })
          .reduce((memo, k) => {
            memo[k] = allEventsParams[k];
            return memo;
          }, {});
        // delete safeEventsParams.patientId;
        updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
          category: "timeSelection",
          action: "load",
          label: JSON.stringify(safeEventsParams),
          value: windows.length,
        });
      }
      dispatch({
        type: OlbActionTypes.RECEIVE_EVENTS,
        events: windows,
        firstEventForProvider: params.firstEventForProvider,
      });
      return windows;
    });
  };
}

export function updateEventRequest(params) {
  return {
    type: OlbActionTypes.UPDATE_EVENT_REQUEST,
    params,
  };
}

export function createEventRequest() {
  return (dispatch, getState) => {
    const appointmentDocument = getState().olb.appointmentData?.appointmentDocument;
    const params = {
      promoCode: appointmentDocument.promoCode,
      providerId: appointmentDocument?.sources?.[0].scheduledEventsProposed?.resourceEvents?.[0].resourceName,
      patientId: getState().olb?.patientInfoData?.patientDocument?.internalPatientId,
      practiceId: getState().common?.practiceData?.practiceJsonDocument?.name,
      patientType: appointmentDocument?.appointmentType,
      payorType: appointmentDocument?.insuranceType,
      reasonId: appointmentDocument?.reason,
    };
    return dispatch({
      type: OlbActionTypes.CREATE_EVENT_REQUEST,
      params,
    });
  };
}

export function previousRange() {
  return (dispatch: Dispatch<DispatchType>, getState): void => {
    const state: ApplicationState = getState();
    const startTime = state?.olb?.eventSelectionData?.startTime;
    if (!isNil(startTime)) {
      let newStartTime: Date = subDays(startTime, getNumOfDays());
      if (getUseDateFromLink(state) && !isNil(state.olb?.eventSelectionData?.startDate)) {
        const startDate = state?.olb?.eventSelectionData?.startDate;
        if (!isNil(startDate) && newStartTime < startDate) {
          newStartTime = startDate;
        }
      }
      dispatch(updateStartTime(newStartTime));
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "timeSelection",
        action: "update",
        label: "previous",
      });
    }
  };
}

export function nextRange() {
  return (dispatch: Dispatch<DispatchType>, getState): void => {
    const startTime = getState().olb?.eventSelectionData?.startTime;
    dispatch(updateStartTime(addDays(startTime, getNumOfDays())));
    updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, { category: "timeSelection", action: "update", label: "next" });
  };
}

export function unloadEvents() {
  return {
    type: OlbActionTypes.UNLOAD_EVENTS,
  };
}

export function updateStartTime(startTime) {
  return {
    type: OlbActionTypes.UPDATE_STARTTIME,
    startTime,
  };
}

export const fetchReasons = (): ((dispatch: Dispatch<DispatchType>) => Promise<AxiosResponse>) => {
  const timestamp = new Date().getTime();
  const params = {
    timestamp,
  };
  const path = `${HCPORTAL_V1_PRACTICES_API_ADDRESS}/${organizationId}/scheduling/reasons`;
  // eslint-disable-next-line
  return (dispatch: Dispatch<DispatchType>): Promise<any> => {
    return axios.get(path, { params }).then(({ data: json }) => {
      dispatch(receiveReasons(json));
      return json;
    });
  };
};

export const receiveReasons = (json: any) => {
  return (dispatch: Dispatch<DispatchType>): void => {
    return dispatch({
      type: OlbActionTypes.RECEIVE_REASONS,
      state: json || {},
    });
  };
};

export function getNumOfDays() {
  if (isSmallFormFactor()) {
    return EventConstants.SMALL_NUM_OF_DAYS;
  } else {
    return EventConstants.LARGE_NUM_OF_DAYS;
  }
}

export function updateStartTimeForShapeChange() {
  return (dispatch, getState) => {
    const proposedEvents = getProposedEvents(getState());
    if (!isNil(proposedEvents) && !isEmpty(proposedEvents)) {
      const startTime = proposedEvents[0].startTime;
      dispatch(updateStartTime(startTime));
    }
  };
}

function _internalLoadEvents(params) {
  const timestamp = new Date().getTime();
  params = assign({}, { timestamp }, params);
  const path = CX_API_ADDRESS + "/events";

  return axios.get(path, { params }).then(({ data: json }) => {
    return json;
  });
}

export function deleteProvider(providerId) {
  return (dispatch: Dispatch<DispatchType>, getState): Promise<any> => {
    const applicationState: ApplicationState = getState();
    const providerCards = applicationState.olb?.providerData?.providerDocumentCards || [];

    const providerToDelete = providerCards.filter((provider) => {
      console.log(provider.body.id);
      return provider.header === providerId;
    });
    console.log(providerToDelete);

    const path = `${HCPORTAL_V1_PRACTICES_API_ADDRESS}/${organizationId}/providers/${providerToDelete[0].body.id}`;
    return axios.delete(path, { headers: { "Content-Type": "application/json" } }).then(({ data: json }) => {
      return json;
    });
  };
}

export function executeDepositCheckout() {
  return async (): Promise<any> => {
    const path = `${HCPORTAL_V1_API_ADDRESS}/payments/checkout`;
    const response = await axios.post(path, {}, { headers: { "Content-Type": "application/json" } });
    window.location.href = response?.data?.paymentUrl;
  };
}

export function depositFailed(error?: string) {
  return {
    type: OlbActionTypes.DEPOSIT_FAILED,
    error,
  };
}
