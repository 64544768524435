import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { disenrollInPlan } from "../../state/MiniSiteActions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { isNil } from "lodash-es";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonActionTypes } from "../../../common/state/CommonActionTypes";
import * as Constants from "../../../onlinebooking/state/OlbConstants";
import { PaymentActivationMode } from "../../constants/MiniSiteConstants";

export function PaymentActivationEnable(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const paymentConfigurator = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator);

  const [accountId, setAccountId] = useState("");
  const [sharedSecret, setSharedSecret] = useState("");
  const isStripe = true; //computed.equal('paymentConfigurator.processor', "StripePaymentService"),
  const [isProcessing, setIsProcessing] = useState(false);

  function onAccountIdChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setAccountId(event.target.value);
  }

  function onSharedSecretChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setSharedSecret(event.target.value);
  }

  const onEnable = async () => {
    //this.attrs.enable(get(this, 'accountId'), get(this, 'sharedSecret'));
  };

  const onDisenroll = async () => {
    setIsProcessing(true);
    if (!isNil(paymentConfigurator)) {
      await dispatch(disenrollInPlan());
    }
    setIsProcessing(false);
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: Constants.PAYMENT_CONFIGURATOR,
      params: { mode: PaymentActivationMode.ENROLL },
    });
  };

  return (
    <Grid container>
      {isStripe && (
        <>
          <Grid item xs={12}>
            <Typography>An email has been sent to start the enrollment process.</Typography>
          </Grid>

          <Grid item xs={12}>
            <Box marginTop={"20px"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Button type="submit" className="btn btn-danger pull-right" disabled={isProcessing} onClick={onDisenroll}>
                {isProcessing && <FontAwesomeIcon spin icon={faCog} />}
                Cancel Enrollment
              </Button>
            </Box>
          </Grid>
        </>
      )}
      {!isStripe && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <TextField type="text" label={"Account ID"} value={accountId} onChange={onAccountIdChanged} />
              <TextField type="text" label={"Shared Secret"} value={sharedSecret} onChange={onSharedSecretChanged} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Button className="btn btn-primary pull-right" variant={"contained"} disabled={isProcessing} onClick={onEnable}>
                {isProcessing && <FontAwesomeIcon spin icon={faCog} />}
                Enable Deposits
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
