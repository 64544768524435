import { EventShapeConfiguratorTpd } from "./tpd/EventShapeConfiguratorTpd";
import { EventShapeConfiguratorHenrySchein } from "./henryschein/EventShapeConfiguratorHenrySchein";
import { NoAvailableTimeTpd } from "./tpd/NoAvailableTimeTpd";
import { PatientInformationTpd } from "./tpd/PatientInformationTpd";
import { NoAvailableTimeHenrySchein } from "./henryschein/NoAvailableTimeHenrySchein";
import { PatientInformationHenrySchein } from "./henryschein/patientinformation/PatientInformationHenrySchein";
import { AddToCalendarHenrySchein } from "./henryschein/appointmentcard/AddToCalendarHenrySchein";
import { AddToCalendarTpd } from "./tpd/appointmentcard/AddToCalendarTpd";

const PartnerComponent = {
  tpd: {
    noAvailableTime: NoAvailableTimeTpd,
    patientInformation: PatientInformationTpd,
    eventShapeConfigurator: EventShapeConfiguratorTpd,
    addToCalendar: AddToCalendarTpd,
  },
  henryschein: {
    noAvailableTime: NoAvailableTimeHenrySchein,
    patientInformation: PatientInformationHenrySchein,
    eventShapeConfigurator: EventShapeConfiguratorHenrySchein,
    addToCalendar: AddToCalendarHenrySchein,
  },
};

export default PartnerComponent;
