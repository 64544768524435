import React from "react";
import { NotSynchronized } from "../common/NotSynchronized";
import { BookingCard } from "../common/BookingCard";

export function Step44(): React.JSX.Element {
  return (
    <BookingCard>
      <NotSynchronized />
    </BookingCard>
  );
}
