import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";

export function AppointmentCardStateFailure(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Alert severity="error" data-testid="t-book-state-failure">
      <AlertTitle>{t("booking.failed.occurred.text")}</AlertTitle>
      {t("booking.failed.contactUs.text")}
    </Alert>
  );
}
