import { useSelector } from "react-redux";
import React, { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { createEventRequest, nextRange, previousRange } from "../../../state/OlbActions";
import { isNil } from "lodash-es";
import { selectEvent } from "../../../state/TimeSelectionActions";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProposedEvent from "../../../state/model/ProposedEvent";
import { NextRangeFunction, PreviousRangeFunction } from "../StepTypes";
import { SelectATime } from "./SelectATime";
import { loadEventsForNextRangeRecallTreatmentPlan, loadEventsForShapeChangeRecallTreatmentPlan } from "../../../state/OlbActionsForRecallTreatmentPlan";
import { cardActionService, saveDocument } from "../../../../common/state/DocumentSaving";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";
import { reload, setIsLoading } from "../../../../common/state/CommonActions";
import { determineIsTermsOfUseAccepted } from "../../../state/OlbSelectors";
import { useErrorHandler } from "react-error-boundary";

export function SelectATimeForRecallTreatmentPlan({ showMedicalInformation = false }: { showMedicalInformation?: boolean }): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const patientDocuments = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocuments);
  // JS TODO - This is used in multiple components.  Factor this out
  const isTermsOfUseAccepted = useSelector(determineIsTermsOfUseAccepted);
  const isTpdGdprNotCompleted = useMemo(() => {
    return !isTermsOfUseAccepted;
  }, [isTermsOfUseAccepted]);
  const isTimeMissing = useSelector((state: ApplicationState) => isNil(state.olb?.appointmentData?.appointmentDocument?.startTime));
  const isTimeSelectionCompleted = useMemo(() => {
    return isTimeMissing === false;
  }, [isTimeMissing]);
  const isTimeSelectionNotCompleted = useMemo(() => {
    return !isTimeSelectionCompleted;
  }, [isTimeSelectionCompleted]);
  const nextButtonLabel = useMemo(() => {
    return isTimeSelectionCompleted ? t("booking.button.bookNow.label") : t("booking.button.selectTime.label");
  }, [isTimeSelectionCompleted, t]);

  useEffect(() => {
    if (!isNil(appointmentDocument) && !isNil(patientDocuments)) {
      const initialProposedEvent: ProposedEvent = {
        id: appointmentDocument.appointmentId,
        duration: appointmentDocument.duration,
        startTime: appointmentDocument.startTime,
        windowType: appointmentDocument.appointmentType,
        resourceEvents: [],
      };

      dispatch(selectEvent(initialProposedEvent, true));

      const fetchData = async () => {
        await dispatch(createEventRequest());
        await dispatch(loadEventsForShapeChangeRecallTreatmentPlan());
      };

      fetchData().catch((error) => {
        handleError(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviousRange: PreviousRangeFunction = async () => {
    await dispatch(previousRange());
    await dispatch(loadEventsForNextRangeRecallTreatmentPlan()).catch((error) => {
      handleError(error);
    });
  };

  const handleNextRange: NextRangeFunction = async () => {
    await dispatch(nextRange());
    await dispatch(loadEventsForNextRangeRecallTreatmentPlan()).catch((error) => {
      handleError(error);
    });
  };

  const handleNext = async () => {
    const update = async () => {
      await dispatch(setIsLoading(true));
      await dispatch(saveDocument(DocumentConstants.PATIENT));
      await dispatch(saveDocument(DocumentConstants.APPOINTMENT));
      await dispatch(cardActionService.execute({ stepActionName: "Book" }));
      document.querySelector("body")?.scrollTo(0, 0);
      await dispatch(reload("3"));
      await dispatch(setIsLoading(false));
    };

    update().catch((error) => {
      handleError(error);
    });
  };

  return (
    <>
      <SelectATime showMedicalInformation={showMedicalInformation} onPreviousRange={handlePreviousRange} onNextRange={handleNextRange} />
      <Grid container direction="row-reverse">
        <Button
          data-testid="t-book-next"
          type="submit"
          size="large"
          style={{ marginRight: 20, marginBottom: 20 }}
          variant="contained"
          disabled={isTimeSelectionNotCompleted || isTpdGdprNotCompleted}
          onClick={handleNext}
          id={"EventListButton"}
        >
          {nextButtonLabel} <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </Button>
      </Grid>
    </>
  );
}
