import React, { useState } from "react";
import { SoAdminCard } from "./SoAdminCard";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { VerifyCode } from "./mfa/VerifyCode";
import { PickChannel } from "./mfa/PickChannel";

export function TpdMFA(): React.JSX.Element {
  const { t } = useTranslation();
  const verificationChannel = useSelector((state: ApplicationState) => state.session?.sessionData.verificationChannel);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <SoAdminCard header={"Multi Factor Authentication"} isLoading={isSaving}>
      <>
        {("VERIFY_CODE" === verificationChannel.verifyChannelState || "VERIFIED" === verificationChannel.verifyChannelState) && (
          <VerifyCode verificationChannel={verificationChannel} onIsSaving={setIsSaving} isSaving={isSaving} />
        )}
        {"PICK_CHANNEL" === verificationChannel.verifyChannelState && (
          <PickChannel verificationChannel={verificationChannel} onIsSaving={setIsSaving} isSaving={isSaving} />
        )}
        {"CANT_SEND_CODE" === verificationChannel.verifyChannelState && <p>{t("signin.howToGetInfo")}</p>}
      </>
    </SoAdminCard>
  );
}
