import React from "react";
import { AppointmentTime } from "./AppointmentTime";
import { SalesInformation } from "./SalesInformation";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { ProviderImageInformation } from "./ProviderImageInformation";
import { AppointmentSummaryInformation } from "./AppointmentSummaryInformation";
import { getPrimaryProvider, getSecondaryProvider } from "../../state/OlbSelectors";

export function AppointmentCardSummary({ source }: { source: any }): React.JSX.Element {
  const combinedAppointment = source.scheduledEventsProposed?.resourceEvents?.length > 1;
  const primaryProvider = useSelector((state: ApplicationState) => getPrimaryProvider(state, source));
  const secondaryProvider = useSelector((state: ApplicationState) => getSecondaryProvider(state, source));

  return (
    <>
      <AppointmentTime startTime={source?.scheduledEventsProposed?.startTime} />
      <SalesInformation
        price={source.scheduledEventsProposed?.resourceEvents?.[0].salesInformation?.price}
        deposit={source.scheduledEventsProposed?.resourceEvents?.[0].salesInformation?.deposit}
      />
      <ProviderImageInformation primaryProvider={primaryProvider} secondaryProvider={secondaryProvider} combinedAppointment={combinedAppointment} />
      <AppointmentSummaryInformation
        primaryProvider={primaryProvider}
        secondaryProvider={secondaryProvider}
        duration={source.scheduledEventsProposed?.duration}
        combinedAppointment={combinedAppointment}
      />
    </>
  );
}
