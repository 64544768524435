import React from "react";
import { updateEventRequest } from "../../state/OlbActions";
import { updateDocument } from "../../../common/state/CommonActions";
import { updateAnalytics } from "../../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../../common/analytics/AnalyticsConstants";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { PatientType } from "../../state/model/ProductConfiguration";
import { PatientTypes } from "../../constants/OnlineBookingConstants";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { shapeChanged } from "../../state/OlbActionsForNewExisting";
import ConfiguratorFieldOptions from "../../state/model/ConfiguratorFieldIOptions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import EventRequest from "../../state/model/EventRequest";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { isNil } from "lodash-es";

export function AppointmentTypeSelectorTpd({ fieldOptions }: { fieldOptions: ConfiguratorFieldOptions }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { defaultValue, isHidden } = fieldOptions || {};
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const eventRequest = useSelector((state: ApplicationState): EventRequest | undefined => state.olb?.eventRequest);
  const appointmentType = eventRequest?.patientType ?? PatientTypes.NEW_PATIENT;
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);

  const internalAppointmentType: PatientType = appointmentType || defaultValue;

  const appointmentTypeChanged = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isNil(value) && value !== internalAppointmentType) {
      dispatch(updateEventRequest({ patientType: value, providerId: null }));
      dispatch(updateDocument(DocumentConstants.APPOINTMENT, { appointmentType: value }));
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "EventRequest",
        action: "patientType",
        label: value,
      });
      dispatch(shapeChanged());
    }
  };

  return (
    <>
      {!isHidden && (
        <Grid container item alignItems={"center"} xs={12} spacing={2}>
          <Grid item xs={12} sm={2} md={12} className={"event-shape-option-label"}>
            {t(`booking.patientType.label.${uiComponentRoot}`)}&nbsp;
          </Grid>
          <Grid item xs={12} sm={10}>
            <ToggleButtonGroup
              color={"primary"}
              value={internalAppointmentType}
              exclusive
              onChange={appointmentTypeChanged}
              data-testid={"t-appointmenttype-selector"}
              disabled={isLoadingEvents}
            >
              <ToggleButton value={PatientTypes.NEW_PATIENT}>{t("booking.newPatient.title")}</ToggleButton>
              <ToggleButton value={PatientTypes.EXISTING_PATIENT}>{t("booking.existingPatient.title")}</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      )}
    </>
  );
}
