import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import cz from "./locales/cz.json";
import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";

const local = (window as any).ThreePointData.localeLanguage;
let i18nLocal = "en";
switch (local) {
  case "cz":
  case "de":
  case "es":
  case "fr":
  case "nl":
  case "it":
  case "pl":
  case "en":
    i18nLocal = local;
    break;
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnNull: false,
    lng: i18nLocal,
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      cz,
      de,
      en,
      es,
      fr,
      it,
      nl,
      pl,
    },
  });
export default i18n;
