import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ConfiguratorFieldOptions from "../../state/model/ConfiguratorFieldIOptions";
import { includes, isEmpty, isNil } from "lodash-es";
import { AnalyticsConstants } from "../../../common/analytics/AnalyticsConstants";
import { updateEventRequest } from "../../state/OlbActions";
import { updateDocument } from "../../../common/state/CommonActions";
import { updateAnalytics } from "../../../common/analytics/AnalyticsActions";
import { shapeChanged } from "../../state/OlbActionsForNewExisting";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { PayorTypes } from "../../constants/OnlineBookingConstants";

export function MedicalCardSelectorHenrySchein({ fieldOptions }: { fieldOptions: ConfiguratorFieldOptions }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { readOnly, defaultValue, isHidden, idsToFilter } = fieldOptions || {};
  const insuranceType = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.insuranceType);
  const internalSelectedPayorType = isNil(insuranceType) ? defaultValue : insuranceType;
  const isMedicalCard = PayorTypes.MEDICAL_CARD_PAYOR === internalSelectedPayorType;
  const isMedicalCardHidden = isHidden || !includes(idsToFilter, PayorTypes.MEDICAL_CARD_PAYOR);
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);

  const medicalCardChanged = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isNil(value) && value !== internalSelectedPayorType) {
      // MUI component likes to use an empty string for value.  Need to convert it to null to send down to the server.
      const adjustedValue = isEmpty(value) ? null : value;
      dispatch(updateEventRequest({ payorType: adjustedValue, providerId: null }));
      dispatch(updateDocument(DocumentConstants.APPOINTMENT, { insuranceType: adjustedValue }));
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "EventRequest",
        action: "payorType",
        label: adjustedValue,
      });
      dispatch(shapeChanged());
    }
  };

  return (
    <>
      {!isMedicalCardHidden && (
        <Grid container item alignItems={"center"} xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={2} className={"event-shape-option-label"}>
            {t("booking.medicalCard.label")}
          </Grid>
          <Grid item xs={12} sm={10}>
            {readOnly && <>{isMedicalCard ? t("booking.medicalCard.yes.label") : t("booking.medicalCard.no.label")}</>}
            <ToggleButtonGroup
              color={"primary"}
              value={isMedicalCard ? PayorTypes.MEDICAL_CARD_PAYOR : ""}
              exclusive
              onChange={medicalCardChanged}
              data-testid="t-medicalcard-selector"
              disabled={isLoadingEvents}
            >
              <ToggleButton value={PayorTypes.MEDICAL_CARD_PAYOR} data-testid="t-medicalcard-selector-yes">
                {t("booking.medicalCard.yes.label")}
              </ToggleButton>
              <ToggleButton value={""} data-testid="t-medicalcard-selector-no">
                {t("booking.medicalCard.no.label")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      )}
    </>
  );
}
