import { debounce, isNil } from "lodash-es";
import { TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { updateFullNameOnAppointment } from "../../state/OlbActionsForNewExisting";
import { TEXTFIELD_DEBOUNCE_WAIT } from "../../../common/constants/HelperConstants";

export function FamilyName({ sourceId, error }: { sourceId: string; error: string }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const patientLastName = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.lastname);
  const [lastName, setLastName] = useState(patientLastName || "");
  const [hasBeenTouched, setBeenTouched] = useState(false);
  const hasError = !isNil(error) && hasBeenTouched;
  const debouncedOnChanged = useMemo(() => debounce(onChanged, TEXTFIELD_DEBOUNCE_WAIT), []);
  function onChangedInternal(event) {
    setLastName(event.target.value);
    debouncedOnChanged(event.target.value, sourceId);
  }
  function onChanged(value, sourceId) {
    dispatch(updateDocument(DocumentConstants.PATIENT, { lastname: value }));
    dispatch(updateFullNameOnAppointment(sourceId));
  }

  function onBlur() {
    if (!hasBeenTouched) {
      dispatch(updateDocument(DocumentConstants.PATIENT, { lastname: lastName }));
      setBeenTouched(true);
    }
  }

  return (
    <TextField
      type="text"
      id="familyname"
      autoComplete="family-name"
      value={lastName}
      className="form-control"
      inputProps={{ maxLength: "35" }}
      required
      error={hasError}
      label={t("booking.patientInformation.lastName.label")}
      data-testid={"t-patient-familyname"}
      onChange={onChangedInternal}
      onBlur={onBlur}
    />
  );
}
