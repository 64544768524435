import React, { useEffect, useMemo, useState } from "react";
import { isNil } from "lodash-es";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../common/state/model/ApplicationState";
import isCMSActive from "../../../../utilities/CmsChecker";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../common/hooks/useAppDispatch";
import { Avatar, FormControl, Grid, InputLabel, ListItemAvatar, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import EventRequest from "../../../../state/model/EventRequest";
import { AnalyticsConstants } from "../../../../../common/analytics/AnalyticsConstants";
import { updateEventRequest } from "../../../../state/OlbActions";
import { updateAnalytics } from "../../../../../common/analytics/AnalyticsActions";
import { shapeChanged } from "../../../../state/OlbActionsForNewExisting";
import { professionalTitleAndQualifications, providerImageURLWithDefault } from "../../../../../common/utilities/ProviderHelpers";
import { getProposedEvents } from "../../../../state/OlbSelectors";

export const ANY_PROVIDER_VALUE = "any";

export function ProviderSelector({ fieldOptions }, { fieldOptions: ConfiguratorFieldOptions }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const readOnly = fieldOptions?.readOnly;
  const defaultValue = fieldOptions?.defaultValue;
  const isHidden = fieldOptions?.isHidden;
  const showProviderImage = useSelector((state: ApplicationState) => state.common?.config?.showProviderImage);
  const showProviderSuffix = useSelector((state: ApplicationState) => state.common?.config?.showProviderSuffix);
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);
  const eventRequest = useSelector((state: ApplicationState): EventRequest | undefined => state.olb?.eventRequest);
  const availableProviders = useSelector((state: ApplicationState) => state.olb?.providerData?.providersWithAvailability);
  const activeProvider = useMemo(() => {
    const resourceName = eventRequest?.providerId;
    if (!isNil(resourceName) && !isNil(availableProviders)) {
      return availableProviders.find((provider) => resourceName === provider.id);
    }
    return null;
  }, [eventRequest?.providerId, availableProviders]);

  const showProviders = !isNil(availableProviders) ? availableProviders.length > 0 : false;
  const onyOneProvider = availableProviders?.length == 1;
  const providerLabel = isCMSActive() ? t("booking.provider.sales.label") : t("booking.provider.label");
  const anyProviderLabel = isCMSActive() ? t("booking.provider.anyProvider.sales.label") : t("booking.provider.anyProvider.label");
  const anyProviderHint = isCMSActive() ? t("booking.provider.anyProvider.sales.hint") : t("booking.provider.anyProvider.hint");
  const internalProvider =
    activeProvider || availableProviders?.find((provider) => defaultValue === provider.id) || (onyOneProvider ? availableProviders[0] : undefined);
  const [selectedProviderIdInternal, setSelectedProviderIdInternal] = useState(internalProvider?.id || ANY_PROVIDER_VALUE);

  useEffect(() => {
    // if (!isLoadingEvents) {
    setSelectedProviderIdInternal(internalProvider?.id || ANY_PROVIDER_VALUE);
    // }
  }, [isLoadingEvents, internalProvider]);

  const onProviderChanged = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedProviderIdInternal(value);
    if (value !== internalProvider?.id) {
      dispatch(updateEventRequest({ providerId: value === ANY_PROVIDER_VALUE ? undefined : value }));
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "EventRequest",
        action: "provider",
        label: value,
      });
      dispatch(shapeChanged());
    }
  };
  return (
    <>
      {!isHidden && (
        <>
          {showProviders && (
            <Grid container item alignItems={"center"} xs={12} sx={{ paddingLeft: 0 }} spacing={2}>
              <Grid item xs={12} sm={12} md={2} className={"event-shape-option-label"}>
                {providerLabel}
              </Grid>
              <Grid item xs={12} sm={10} sx={{ pr: 5 }}>
                {readOnly ? (
                  <p>{internalProvider.name}</p>
                ) : (
                  <FormControl sx={{ minWidth: 250, width: "100%" }} disabled={isLoadingEvents}>
                    <InputLabel>{anyProviderHint}</InputLabel>
                    <Select
                      value={selectedProviderIdInternal}
                      label={anyProviderHint}
                      onChange={onProviderChanged}
                      data-testid={"t-provider-selector"}
                      sx={{
                        "& .MuiSelect-select": {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                    >
                      {!onyOneProvider && (
                        <MenuItem value={ANY_PROVIDER_VALUE} data-testid={"t-provider-selector-item-any"}>
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText primary={anyProviderLabel} />
                        </MenuItem>
                      )}
                      {availableProviders?.map((provider) => {
                        return (
                          <MenuItem value={provider.id} key={provider.id} data-testid={"t-provider-selector-item-" + provider.id}>
                            {showProviderImage && (
                              <ListItemAvatar>
                                <Avatar src={providerImageURLWithDefault(provider)} alt={provider.name}>
                                  {provider?.name?.charAt(0)}
                                </Avatar>
                              </ListItemAvatar>
                            )}
                            <ListItemText
                              primary={provider.name}
                              secondary={showProviderSuffix ? professionalTitleAndQualifications(provider) : ""}
                              sx={{ "& .MuiListItemText-secondary": { textOverflow: "ellipsis", overflow: "hidden" } }}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
