import React, { useMemo } from "react";
import { Box, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { isNil } from "lodash-es";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { MedicalInformation } from "./MedicalInformation";
import PartnerComponent from "./PartnerComponent";
import { AddToCalendar } from "./AddToCalendar";
import { Mode } from "../state/OlbConstants";
import { PatientValidationEnterCode } from "./PatientValidationEnterCode";
import { AppointmentCardSummary } from "./appointmentcard/AppointmentCardSummary";
import { TermsOfUseOrMarketingConsent } from "./appointmentcard/TermsOfUseOrMarketingConsent";
import { PatientValidation } from "./PatientValidation";
import { Payment } from "./Payment";

export function AppointmentCard({
  showPatientInformation = false,
  showTermsOfUse = false,
  showMarketingConsent = false,
  showAddToCalendar = false,
  appointmentNotConfirmed = false,
  showPatientValidation = false,
  showMedicalInformation = false,
  readonly = false,
  currentStepId = "",
  validationMode = -1,
  source,
}: {
  showPatientInformation?: boolean;
  showTermsOfUse?: boolean;
  showMarketingConsent?: boolean;
  showAddToCalendar?: boolean;
  appointmentNotConfirmed?: boolean;
  showPatientValidation?: boolean;
  showMedicalInformation?: boolean;
  readonly?: boolean;
  currentStepId?: string;
  validationMode?: number;
  source: any;
}): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const startTime: Date = useMemo(() => source?.scheduledEventsProposed?.startTime, [source?.scheduledEventsProposed?.startTime]);
  const appointmentTimeAvailable: boolean = useMemo(() => !isNil(startTime), [startTime]);
  const patientName: string = source.sourceName;
  const inCodeVerificationMode = validationMode == Mode.CODE_VERIFICATION;

  // *** Rendering logic ***
  const AddToCalendar = useMemo(() => {
    return !isNil(uiComponentRoot) && PartnerComponent[uiComponentRoot] ? PartnerComponent[uiComponentRoot].addToCalendar : PartnerComponent.tpd.addToCalendar;
  }, [uiComponentRoot]);

  const PatientInformation = useMemo(() => {
    return !isNil(uiComponentRoot) && PartnerComponent[uiComponentRoot]
      ? PartnerComponent[uiComponentRoot].patientInformation
      : PartnerComponent.tpd.patientInformation;
  }, [uiComponentRoot]);

  const PatientValidate = () => {
    return (
      <>
        {showPatientValidation && <PatientValidation currentStepId={currentStepId} />}
        {inCodeVerificationMode && <PatientValidationEnterCode />}
      </>
    );
  };

  return (
    <Paper variant="outlined" sx={{ m: 1 }}>
      <Box style={{ padding: 10 }} sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
        {patientName}&nbsp;
      </Box>
      {appointmentTimeAvailable ? (
        <>
          <Grid container style={{ margin: 20, flexDirection: "row", gap: 10 }} alignItems={"center"}>
            <AppointmentCardSummary source={source} />
          </Grid>
          <Divider light sx={{ m: 2 }} />
          <Box>
            {showMedicalInformation && (
              <>
                <Grid container sx={{ m: 2 }}>
                  <MedicalInformation source={source} readonly={readonly} />
                </Grid>
                <Divider light sx={{ m: 2 }} />
              </>
            )}
            {showPatientInformation && (
              <>
                <PatientInformation source={source} />
              </>
            )}
            {Mode.DEPOSIT === validationMode && <Payment></Payment>}
            <PatientValidate />
            <TermsOfUseOrMarketingConsent sourceId={source.sourceId} showMarketingConsent={showMarketingConsent} showTermsOfUse={showTermsOfUse} />
            <AddToCalendar
              showAddToCalendar={showAddToCalendar}
              startTime={startTime}
              source={source}
              appointmentNotConfirmed={appointmentNotConfirmed}
              patientName={patientName}
            />
          </Box>
        </>
      ) : (
        <Typography sx={{ m: 2 }}>{t("booking.summary.noTime.label")}</Typography>
      )}
    </Paper>
  );
}
