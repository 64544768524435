import React from "react";
import { ReadyForVerification } from "../common/ReadyForVerification";
import { useTranslation } from "react-i18next";
import { reload, setIsLoading } from "../../../../common/state/CommonActions";
import { cardActionService } from "../../../../common/state/DocumentSaving";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { Button, Grid } from "@mui/material";
import { BookingCard } from "../common/BookingCard";
import { useErrorHandler } from "react-error-boundary";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../common/state/model/ApplicationState";

export function Step41(): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const hasEnteredVerificationCode = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.hasEnteredVerificationCode);


  const previous = async () => {
    const update = async () => {
      await dispatch(setIsLoading(true));
      await dispatch(cardActionService.execute({ stepActionName: "Back" }));
      document.querySelector("body")?.scrollTo(0, 0);
      await dispatch(reload("3"));
      await dispatch(setIsLoading(false));
    };

    update().catch((error) => {
      handleError(error);
    });
  };

  return (
    <BookingCard>
      <Grid>
        <ReadyForVerification />
        <Button data-testid="t-book-previous"
                style={{ marginLeft: 20, marginBottom: 20 }}
                variant="contained" onClick={previous}
                disabled={hasEnteredVerificationCode}>
          {t("booking.button.goBackDetails.label")}
        </Button>
      </Grid>
    </BookingCard>
  );
}
