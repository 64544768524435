import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTime } from "../../common/utilities/GeneralHelpers";
import { isNil } from "lodash-es";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { updateAnalytics } from "../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../common/analytics/AnalyticsConstants";
import { Box } from "@mui/material";

const dateFormat = "yyyy-MM-dd";
const timeFormat = "kk:mm";
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export function AddToCalendar({
  startTime,
  duration = 0,
  title,
  description,
  location,
}: {
  startTime: Date;
  duration?: number;
  title: string;
  description: string;
  location: string;
}): React.JSX.Element {
  const { t } = useTranslation();
  const timezone: string = useSelector((state: ApplicationState) => state.session.timezone);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const endTime: Date | undefined = useMemo(() => {
    if (!isNil(startTime)) {
      return new Date(startTime.getTime() + duration * 60 * 1000);
    }
  }, [startTime, duration]);

  //cdn.addevent.com/libs/atc/1.6.1/atc.min.js
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js";
    script.async = true;
    script.addEventListener("load", () => setIsScriptLoaded(true), false);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      //@ts-ignore Add addeventatc to the window type
      window.addeventatc.register("button-dropdown-click", function (obj) {
        updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
          category: "Booked",
          action: "addToCalendar",
          label: obj.service,
        });
      });
      //@ts-ignore Add addeventatc to the window type
      window.addeventatc.settings({
        license: "aScaxeVYJzMdVIByHmEQ15483",
        css: true,
        outlookcom: { show: false },
        yahoo: { show: false },
      });
      //@ts-ignore Add addeventatc to the window type
      window.addeventatc.refresh();
    }
  }, [isScriptLoaded]);

  return (
    <Box title={t("booking.summary.review.addToCalendar.label")} className="addeventatc" data-google-api="false">
      <span>{t("booking.summary.review.addToCalendar.label")}</span>
      <span className="start">{formatTime(dateTimeFormat, timezone, startTime)}</span>
      <span className="end">{formatTime(dateTimeFormat, timezone, endTime)}</span>
      <span className="timezone">{timezone}</span>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <span className="location">{location}</span>
      <span className="all_day_event">false</span>
      <span className="date_format">{dateFormat}</span>
      <span className="alarm_reminder">30</span>
    </Box>
  );
}
