import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { findDocumentByInternalPatientId } from "../../utilities/GeneralOnlineBookingHelpers";
import { TpdGdpr } from "../TpdGdpr";

export const TermsOfUseOrMarketingConsent = ({
  sourceId,
  showTermsOfUse,
  showMarketingConsent,
}: {
  sourceId: string;
  showTermsOfUse: boolean;
  showMarketingConsent: boolean;
}) => {
  const { t } = useTranslation();
  const termsOfUseDocument: any | undefined = useSelector((state: ApplicationState) => state.olb?.termsOfUseData?.termsOfUseDocument);
  const marketingConsentDocument: any | undefined = useSelector((state: ApplicationState) => state.olb?.marketingConsentData?.marketingConsentDocument);
  const termsOfUseChecked: boolean | undefined = useSelector((state: ApplicationState) => {
    const document = findDocumentByInternalPatientId(sourceId, state.olb?.patientInfoData?.patientDocuments);
    return document?.acceptedTermsOfUse;
  });
  const marketingChecked: boolean | undefined = useSelector((state: ApplicationState) => {
    const document = findDocumentByInternalPatientId(sourceId, state.olb?.patientInfoData?.patientDocuments);
    return document?.acceptedMarketingConsent;
  });

  if (showTermsOfUse || showMarketingConsent) {
    return (
      <>
        {termsOfUseDocument && (
          <Box sx={{ ml: 1 }}>
            <TpdGdpr
              required={true}
              form={termsOfUseDocument}
              sourceId={sourceId}
              patientField={"acceptedTermsOfUse"}
              patientFieldChangeDate={"acceptedTermsOfUseChangeDate"}
              text={t("form.termsOfUse.acceptance.text")}
              checked={termsOfUseChecked}
            />
          </Box>
        )}
        {marketingConsentDocument && (
          <Box sx={{ ml: 1 }}>
            <TpdGdpr
              required={false}
              form={marketingConsentDocument}
              sourceId={sourceId}
              patientField={"acceptedMarketingConsent"}
              patientFieldChangeDate={"acceptedMarketingConsentChangeDate"}
              text={t("form.marketing.consent.text")}
              checked={marketingChecked}
            />
          </Box>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
