import Cookies from 'js-cookie'

const getEnabledCookieConsentGroups = () => {
    let activeGroups = ((window as any).OptanonActiveGroups || "").split(",");
    activeGroups = activeGroups.filter(item => !!item);
    return activeGroups;
};

export const isPerformanceCookiesEnabled = () => {
    return getEnabledCookieConsentGroups().indexOf("C0002") >= 0;
};

export const removeUnauthorizedCookies = () => {
    (window as any).OneTrust.GetDomainData().Groups.forEach(group => {
        if (group.Status === "inactive") {
            const currentCookies = Cookies.get();
            group.Cookies.forEach(cookie => {
                Object.keys(currentCookies).forEach((innerCookie) => {
                    if (innerCookie.startsWith(cookie.Name)) {
                        Cookies.remove(innerCookie, {path: "/", domain: window.location.hostname});
                    }
                });
            });
        }
    });
}