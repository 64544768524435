import React from "react";
import { Synchronized } from "../common/Synchronized";
import { BookingCard } from "../common/BookingCard";
import management from "../../../../common/utilities/Management";

export function Step14(): React.JSX.Element {
  return (
    <BookingCard>
      <Synchronized showMedicalInformation={management.partnerAdapter.showMedicalInformation()} />
    </BookingCard>
  );
}
