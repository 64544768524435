import React from "react";
import { BookAppointment } from "../common/BookAppointment";
import { BookingCard } from "../common/BookingCard";

export function Step42(): React.JSX.Element {
  return (
    <BookingCard>
      <BookAppointment currentStepId={"42"}></BookAppointment>
    </BookingCard>
  );
}
