import React, { useEffect, useMemo, useState } from "react";
import { Alert, Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { isEqual, isNil } from "lodash-es";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { formatTime, formatWeekDay } from "../../common/utilities/GeneralHelpers";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { useSelector } from "react-redux";
import ProposedEvent from "../state/model/ProposedEvent";
import { utcToZonedTime } from "date-fns-tz";
import PartnerComponent from "./PartnerComponent";
import WeekDay from "../state/model/WeekDay";
import { processAvailableEvents, processWeekDays } from "../utilities/GeneralOnlineBookingHelpers";
import Row from "../state/model/Row";
import Day from "../state/model/Day";
import { NextRangeFunction, PreviousRangeFunction, SelectEventFunction } from "./step/StepTypes";
import { v4 as uuid } from "uuid";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function TimeSelection({
  proposedEvents,
  eventRequest,
  selectedEvent,
  startTime,
  startDate,
  useDateFromLink,
  practicePhone,
  practiceName,
  practiceEmail,
  numOfDays,
  isLoadingEvents,
  openDate,
  isOnlineBookingUnavailableUntilFurtherNotice,
  isOnlineBookingUnavailableUntil,
  previousRange,
  nextRange,
  selectEvent,
  receivedTimes,
}: {
  proposedEvents?: ProposedEvent[];
  eventRequest?: any;
  selectedEvent: any;
  startTime?: Date;
  startDate?: Date;
  useDateFromLink: boolean;
  practicePhone?: string;
  practiceName: string;
  practiceEmail: string;
  numOfDays: number;
  isLoadingEvents?: boolean;
  openDate: Date;
  isOnlineBookingUnavailableUntilFurtherNotice: boolean;
  isOnlineBookingUnavailableUntil: boolean;
  previousRange: PreviousRangeFunction;
  nextRange: NextRangeFunction;
  selectEvent: SelectEventFunction;
  receivedTimes?: boolean;
}): React.JSX.Element {
  const { t } = useTranslation();
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const timezone: string | undefined = useSelector((state: ApplicationState) => state.session?.timezone);
  const theme = useTheme();
  const isUnavailable = useMemo(() => {
    return isOnlineBookingUnavailableUntilFurtherNotice || isOnlineBookingUnavailableUntil;
  }, [isOnlineBookingUnavailableUntilFurtherNotice, isOnlineBookingUnavailableUntil]);
  const showPrevious = useMemo(() => {
    let result = false;
    if (!isNil(startTime)) {
      let localStartDate = utcToZonedTime(new Date(), timezone);
      if (useDateFromLink) {
        if (!isNil(startDate)) {
          localStartDate = utcToZonedTime(startDate, timezone);
        }
      }
      const localStartTime = utcToZonedTime(startTime, timezone);
      result = differenceInDays(localStartTime, localStartDate) > 0;
    }
    return result;
  }, [timezone, useDateFromLink, startDate, startTime]);

  const practicePhoneLink = useMemo(() => {
    return "tel:" + practicePhone;
  }, [practicePhone]);

  const practiceEmailLink = useMemo(() => {
    return "mailto:" + practiceEmail;
  }, [practiceEmail]);

  const NoAvailableTimeComponent = useMemo(() => {
    return !isNil(uiComponentRoot) && PartnerComponent[uiComponentRoot]
      ? PartnerComponent[uiComponentRoot].noAvailableTime
      : PartnerComponent.tpd.noAvailableTime;
  }, [uiComponentRoot]);
  const proposedEventsFiltered = useMemo(() => {
    return proposedEvents?.filter((proposedEvent) => {
      const providerId = eventRequest?.providerId;
      if (isNil(providerId)) {
        return true;
      }

      return isEqual(providerId, proposedEvent.resourceEvents?.[0].resourceName);
    });
  }, [proposedEvents, eventRequest]);

  const [weekDays, setWeekDays] = useState<WeekDay[]>([]);
  const [weekTimes, setWeekTimes] = useState<Row[]>([]);

  useEffect(() => {
    setWeekDays(processWeekDays(timezone, numOfDays, startTime));
  }, [startTime, timezone, numOfDays]);

  useEffect(() => {
    setWeekTimes(processAvailableEvents(proposedEventsFiltered, weekDays, selectedEvent));
  }, [proposedEventsFiltered, weekDays, selectedEvent]);

  const previousWeekClicked = () => {
    previousRange();
  };

  const nextWeekClicked = () => {
    nextRange();
  };

  const selectTime = (day: Day, time?: ProposedEvent) => {
    if (!isNil(time)) {
      selectEvent(time, false);
    }
  };

  const hasProposedEvents = proposedEvents && proposedEvents.length > 0;

  // Render Component
  return (<>
    {isUnavailable && <>
      <Grid item xs={12} padding={2}>
        <Alert severity="warning" data-testid={"t-timeselection-unavailable-banner"}>
          {isOnlineBookingUnavailableUntilFurtherNotice
            ? t("booking.unavailableUntilFurtherNotice.text")
            : t("booking.unavailableUntil.text", { date: formatTime("dd MMMM yyyy", timezone, openDate) })}
        </Alert>
      </Grid>
    </>}

    {hasProposedEvents || receivedTimes ?
    <>
      <Box style={{ margin: 20 }}>
        {isLoadingEvents ? (
          <></>
        ) : (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {showPrevious && (
                <div className={"pull-left"}>
                  <Button data-testid={"t-availability-previous"} onClick={previousWeekClicked}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    &nbsp;{t("booking.previousWeek.label")}
                  </Button>
                </div>
              )}
              <div className="pull-right">
                <Button data-testid={"t-availability-next"} onClick={nextWeekClicked}>
                  {t("booking.nextWeek.label")}&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {weekDays.map((day: WeekDay, weekDayIndex) => (
                        <TableCell
                          key={day.date.getTime()}
                          align="center"
                          data-testid={"t-weekday-" + formatWeekDay(t("booking.weekday.dayOfWeek.format"), timezone, day.date) + "-" + weekDayIndex}
                        >
                          <Box sx={{ fontWeight: "bold" }} component={"span"}>
                            {formatWeekDay(t("booking.weekday.dayOfWeek.format"), timezone, day.date)}
                          </Box>
                          <br />
                          <Box sx={{ fontWeight: "regular" }} component={"span"}>
                            {formatWeekDay(t("booking.weekday.monthDay.format"), timezone, day.date)}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {weekTimes.length > 0 ? (
                      weekTimes.map((weekTime, rowIndex) => (
                        <TableRow key={weekTime.id} data-testid={"t-availability-" + rowIndex}>
                          {weekTime.days.map((day, colIndex) => (
                            <TableCell
                              key={day.time?.startTime?.getTime() ? day.time?.startTime?.getTime() : uuid()}
                              data-testid={"t-availability-" + rowIndex + "-" + colIndex}
                              align="center"
                              className={`${day.selected ? "success" : "foo"} ${day.time?.startTime ? "available-time" : "no-available-time"}`}
                              onClick={() => selectTime(day, day.time)}
                            >
                              <Typography sx={{ color: theme.palette.primary.main }}>
                                {formatTime(t("booking.weekdayTime.format"), timezone, day.time?.startTime).toLowerCase()}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className="highlight text-center sixteenFont">
                            {t("booking.noAvailableTimes.forPeriod.text")}
                            <br />
                            {t("booking.noAvailableTimes.forPeriod.try.text")}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
    : !isLoadingEvents ?
      <NoAvailableTimeComponent
        className={"no-available-times-margin"}
        practiceName={practiceName}
        practicePhone={practicePhone}
        practicePhoneLink={practicePhoneLink}
        practiceEmail={practiceEmail}
        practiceEmailLink={practiceEmailLink}
      />
    : <></>}
  </>);
}
