import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "./rootReducer";
import thunkMiddleware from "redux-thunk";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(rootReducer, composedEnhancer);
const dispatch = store.dispatch;

export type AppDispatch = typeof store.dispatch | Promise<any>;
export { store as default, dispatch };
