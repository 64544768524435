import React from "react";
import { MarkdownToHtml } from "./MarkdownToHtml";
import { Box, Divider, Paper, useTheme } from "@mui/material";

export function CallCenterPage({ pageDocument }: { pageDocument: any }): React.JSX.Element {
  const theme = useTheme();
  return (
    <Paper variant="outlined">
      <Box style={{ padding: 10 }} sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
        {pageDocument.title}
      </Box>
      <Divider light />
      {pageDocument.content && (
        <>
          {pageDocument.isPlainText ? (
            <span className="tpd-white-space__pre-wrap">{pageDocument.content}</span>
          ) : (
            <Box style={{ padding: 10 }}>
              <MarkdownToHtml markdown={pageDocument.content} />
            </Box>
          )}
        </>
      )}
    </Paper>
  );
}
