import { AppBar, Button, CssBaseline, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash-es";
import { ApplicationState } from "../state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { callCenterHomePath, isCallCenterActive } from "../utilities/CallCenter";
import { useErrorHandler } from "react-error-boundary";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { notEmbeddedInAnIFrame } from "../utilities/Iframe";

const BasicLayout = ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const [anchorEl, setAnchorEl] = useState<null | undefined | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isAuthenticated = useSelector((state: ApplicationState) => state.session.sessionData?.isAuthenticated);
  const onTrustEnabled = !isNil((window as any).ThreePointData.otCookieConsentId);
  const practiceDocument = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument);
  const logoutEndpoint = useSelector((state: ApplicationState) => state.session.sessionData?.entityUserCard?.actions[0]?.path);
  const practiceName = useMemo(() => {
    return !isEmpty(practiceDocument?.name) ? practiceDocument.name : "";
  }, [practiceDocument?.name]);

  const practiceWebsite = useMemo(() => {
    let practiceWebsite = "#";
    if (!isEmpty(practiceDocument?.website) && !isEmpty(practiceName)) {
      practiceWebsite = practiceDocument.website;
      if (practiceWebsite.indexOf("http") < 0) {
        // noinspection HttpUrlsUsage
        practiceWebsite = "http://" + practiceWebsite;
      }
    }
    return practiceWebsite;
  }, [practiceName, practiceDocument?.website]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = async () => {
    try {
      await axios.get(logoutEndpoint.toString(), {});
      if (isCallCenterActive()) {
        window.location = callCenterHomePath();
      } else {
        window.location.reload();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleCallCenterClick = () => {
    window.location = callCenterHomePath();
  };

  return (
    <>
      <CssBaseline />
      {notEmbeddedInAnIFrame() && (
        <AppBar position={"static"} color={"secondary"}>
          <Toolbar>
            <Typography variant={"h6"} component={"div"} sx={{ flexGrow: 1 }}>
              <a
                href={practiceWebsite}
                target={"_blank"}
                rel={"noreferrer"}
                style={{ textDecoration: "none", color: theme.palette.secondary.contrastText }}
                data-testid={"t-header-practice-name"}
              >
                {practiceName}
              </a>
            </Typography>
            {isCallCenterActive() && (
              <Button
                color={"inherit"}
                onClick={handleCallCenterClick}
                data-testid={"t-navbar-button-callcenter"}
                startIcon={<FontAwesomeIcon icon={faHome} />}
              >
                Call Center
              </Button>
            )}
            {(isAuthenticated || onTrustEnabled) && (
              <>
                <IconButton color={"inherit"} onClick={handleClick} data-testid={"t-navbar-button-user"}>
                  <FontAwesomeIcon icon={faUser} />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose} data-testid={"t-navbar-list"}>
                  {onTrustEnabled && (
                    <MenuItem
                      data-testid={"t-navbar-list-cookiesettings"}
                      onClick={() => {
                        (window as any).OneTrust.ToggleInfoDisplay();
                        handleClose();
                      }}
                    >
                      {t("nav.cookie-preferences")}
                    </MenuItem>
                  )}
                  {isAuthenticated && (
                    <MenuItem onClick={logout} data-testid={"t-navbar-list-signout"}>
                      {t("nav.signout")}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      )}
      {children}
    </>
  );
};

export default connect()(BasicLayout);
