import axios, { AxiosResponse } from "axios";

class ServerAnalyticsLogger {
    PATH = "/hcportal/v1/internal/log";

    trackEvent(this: ServerAnalyticsLogger, options: any = {}) {
        const compactedOptions: any = { ...options };
        compactedOptions.hitType = "event";

        if (compactedOptions.nonInteraction) {
            delete compactedOptions.nonInteraction;
        }
        this._sendToServer(compactedOptions);
    }

    trackPage(this: ServerAnalyticsLogger, options: any = {}) {
        const compactedOptions = options;
        compactedOptions.hitType = "pageview";

        this._sendToServer(compactedOptions);
    }

    _sendToServer(data: any = {}) {
        if ((window as any).ThreePointData.clientLoggingEnabled) {
            try {
                axios.post(this.PATH, data, { headers: { "Content-Type": "application/json" } });
            }
            catch(e) {
                // Catch and swallow any error and log to the console.
                console.log("Error while posting log to the server.", e);
            }
        }
    }
}


export default ServerAnalyticsLogger;