/**
 * Used to be the ApplicationSerializer in the ember application
 */
import { capitalize, isEmpty } from "lodash-es";

const GenerateIdMixin = {
  _generateUUID: function () {
    return ("" + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      // @ts-ignore
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  },
};
export const ApplicationNormalizer = {
  _normalizeStacksAndCards: function (stacksPayload: any[]) {
    let cardType;
    stacksPayload.forEach(function (stack) {
      if (isEmpty(stack.id)) {
        stack.id = GenerateIdMixin._generateUUID();
      }
      stack.cards.forEach(function (card) {
        // id
        card.id = card.sourceObjectID;
        delete card.sourceObjectID;

        // TODO: JS Try and refactor these dependencies out
        // card type
        cardType = card.type;
        card.languageType = cardType;
        let cardTypeName = cardType.substring(cardType.lastIndexOf("_") + 1);
        if (cardTypeName.indexOf("JSON") === 0) {
          cardTypeName = cardTypeName.replace("JSON", "Json");
        } else if (cardTypeName.indexOf("IEntity") === 0) {
          capitalize(cardTypeName.toLowerCase());
        }
        card.type = cardTypeName + card.identifier;

        // TODO: JS Try and refactor these dependencies out
        //card body
        if (card.body) {
          if (card.body.value) {
            const value = card.body.value;
            if (typeof value === "string" && value.slice(0, 1) === "{") {
              card.body.value = JSON.parse(card.body.value);
            }
          }
          if (card.body.documentType) {
            if (card.body.documentType.jsonschema) {
              const schema = card.body.documentType.jsonschema;
              if (typeof schema === "string" && schema.slice(0, 1) === "{") {
                card.body.documentType.jsonschema = JSON.parse(card.body.documentType.jsonschema);
              }
            }
          }
        }

        // TODO: JS Try and refactor these dependencies out
        //card actions requirementsMet.appointment
        if (card.actions && card.actions.length > 0) {
          card.actions.forEach(function (action) {
            if (action.requirementsMet && action.requirementsMet.appointment) {
              action.requirementsMet.appointment.value.value = JSON.parse(action.requirementsMet.appointment.value.value);
            }
            if (action.data) {
              action.data1 = action.data;
              delete action.data;
            }
          });
        }
      });
    });
  },
};
