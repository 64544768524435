import React, { useState } from "react";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { CommonActionTypes } from "../../../common/state/CommonActionTypes";
import * as Constants from "../../../onlinebooking/state/OlbConstants";
import { Box, Button, Grid, TextField } from "@mui/material";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { isNil } from "lodash-es";
import { enrollInPlan } from "../../state/MiniSiteActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentActivationMode } from "../../constants/MiniSiteConstants";
import { useErrorHandler } from "react-error-boundary";

export function PaymentActivationEnroll(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const internalEmail = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator.emailAddresses);
  const errors = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.error);
  const [isProcessing, setIsProcessing] = useState(false);
  const paymentConfigurator = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator);

  const invalidEmail = errors && errors["email"];

  function onEmailChanged(emails) {
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: Constants.SERVICE_PLAN,
      params: { email: emails },
    });
  }

  const onEnroll = async () => {
    setIsProcessing(true);
    if (!isNil(paymentConfigurator)) {
      try {
        await dispatch(enrollInPlan(paymentConfigurator));
        setIsProcessing(false);
      } catch (error: any) {
        setIsProcessing(false);
        handleError(error);
      }
    }
    setIsProcessing(false);
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: Constants.PAYMENT_CONFIGURATOR,
      params: { mode: PaymentActivationMode.ENABLE },
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextField type="text" label={"Email"} required value={internalEmail || ""} onChange={(e) => onEmailChanged(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <span id="helpBlock" className="help-block">
            To link the payment processor, enter an email address to send the enrollment email to.
          </span>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Grid container item>
          <Grid item xs={12}>
            <Box marginTop={"20px"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
              <Button className="btn btn-primary" variant={"contained"} disabled={isProcessing || invalidEmail} onClick={onEnroll}>
                {isProcessing && <FontAwesomeIcon spin icon={faCog} />}Enroll
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
