import { isEmpty, isNil } from "lodash-es";

export const websiteLink = (website: string) => {
  let websiteUrl = "";
  if (!isEmpty(website)) {
    websiteUrl = website;
    if (websiteUrl.indexOf("http") < 0) {
      websiteUrl = "http://" + websiteUrl;
    }
  }
  return websiteUrl;
};

export const mailToEmail = (email: string) => {
  if (!isNil(email)) {
    return `mailto:${email}`;
  }
};

export const countryCode = () => {
  return (window as any).ThreePointData.countryCode;
};

const isNL = () => {
  return "NL" === countryCode();
};

const isFR = () => {
  return "FR" === countryCode();
};

export const swapAddressLines = () => {
  return isNL();
};
export const postalCodeOnSameLine = () => {
  return isFR();
};
