import { isNil } from "lodash-es";
import { Countries } from "../constants/Countries";
import { countryForE164Number } from "../../common/utilities/PhoneFormatWrapper";

export function setDefaultForMobileNumberCountry(mobileNumber?: string) {
  const countryCode = !isNil(mobileNumber) ? countryForE164Number(mobileNumber) : undefined;
  const country = Countries.find((country) => country.code === countryCode);
  let code: string;
  if (isNil(country)) {
    code = (window as any).ThreePointData.countryCode;
  } else {
    code = country.code;
  }
  return code;
}
