import { Reducer } from "react";
import Action from "../../common/models/Action";
import MiniSiteState from "./MiniSiteState";
import { CommonActionTypes } from "../../common/state/CommonActionTypes";
import PaymentConfiguration, { validatePaymentConfiguration } from "./model/PaymentConfiguration";
import { forEach, isNil, set } from "lodash-es";
import { MiniSiteActionTypes } from "./MiniSiteActionTypes";
import PivotTableV1Data from "./model/PivotTableV1Data";
import { _findCardWithType } from "../../common/state/CommonReducerHelpers";
import AdminDateSelectorData from "./model/AdminDateSelectorData";
import { _findAction } from "../../common/state/DocumentSaving";
import * as Constants from "../../onlinebooking/state/OlbConstants";
import { MiniSiteConstants, PaymentActivationMode } from "../constants/MiniSiteConstants";

export const miniSiteReducer: Reducer<MiniSiteState, Action> = (state: MiniSiteState = {}, action: Action = {} as Action): MiniSiteState => {
  const result: MiniSiteState = { ...state };
  switch (action.type) {
    case CommonActionTypes.RECEIVE_PERSPECTIVE: {
      result.paymentConfiguration = _reducePaymentConfiguration(action.state.stacks);
      result.adminDateSelectorData = _findAdminDateSelectorData(action.state.stacks);
      result.pivotTableV1Data = _findPivotTableCards(action.state.stacks);
      break;
    }
    case MiniSiteActionTypes.UPDATE_API_KEY_FAILED: {
      if (isNil(result.apiKeyData)) {
        result.apiKeyData = { verificationCodeUnauthorized: false };
      }
      result.apiKeyData.verificationCodeUnauthorized = action.failed;
      break;
    }
    case CommonActionTypes.UPDATE_DOCUMENT: {
      switch (action.documentType) {
        case MiniSiteConstants.ADMIN_DATE_SELECTOR:
          if (result.adminDateSelectorData) {
            _updateAdminDateSelectorDocument(result.adminDateSelectorData, action.params);
          }
          break;
        case Constants.PAYMENT_CONFIGURATOR:
        case Constants.SERVICE_PLAN:
          _updatePaymentConfiguratorDocument(result, action.params);
          break;
      }
      break;
    }
  }
  return result;
};

function _updateAdminDateSelectorDocument(result: AdminDateSelectorData, params: any) {
  forEach(params, (value, key) => {
    const prop = `dateSelector.range.${key}`;
    set(result, prop, value);
    _populateSessionMapUpdateActionWithValue(result, key, value);
  });
}

function _reducePaymentConfiguration(stacks) {
  const result: PaymentConfiguration = {
    isProcessing: false,
    paymentConfiguratorCard: null,
    paymentConfigurator: {
      balance: {
        currency: "",
        amount: 0,
      },
      collectApplicationFee: false,
      emailAddresses: "",
      languageType: "",
      plan: null,
      processor: "",
    },
    inDisenrollMode: false,
    inEnrollMode: false,
    inEnableMode: false,
    mode: "",
    actionName: null,
    error: null,
  };
  _findPaymentConfiguratorCards(stacks).forEach((card: any) => {
    result.paymentConfiguratorCard = card;
    result.paymentConfigurator = card.body;
    if (!isNil(_findAction(card, "Disenroll"))) {
      result.mode = PaymentActivationMode.DISENROLL;
      const action = _findAction(card, "Plan");
      if (!isNil(action)) {
        result.actionName = action.name;
      }
    } else if (!isNil(_findAction(card, "Cancel"))) {
      result.mode = PaymentActivationMode.ENABLE;
    } else if (!isNil(_findAction(card, "Enroll"))) {
      result.mode = PaymentActivationMode.ENROLL;
    }
  });

  return result;
}

function _findAdminDateSelectorData(stacks: [] = []) {
  const result: AdminDateSelectorData = {
    periods: [
      {
        value: "DAY",
        label: "Day",
      },
      {
        value: "WEEK",
        label: "Week",
      },
      {
        value: "MONTH",
        label: "Month",
      },
    ],
    offsets: [
      {
        value: 0,
        label: "Current",
      },
      {
        value: -1,
        label: "1 ago",
      },
      {
        value: -2,
        label: "2 ago",
      },
      {
        value: -3,
        label: "3 ago",
      },
      {
        value: -4,
        label: "4 ago",
      },
      {
        value: -5,
        label: "5 ago",
      },
      {
        value: -6,
        label: "6 ago",
      },
      {
        value: -7,
        label: "7 ago",
      },
      {
        value: -8,
        label: "8 ago",
      },
      {
        value: -9,
        label: "9 ago",
      },
      {
        value: -10,
        label: "10 ago",
      },
      {
        value: -11,
        label: "11 ago",
      },
      {
        value: -12,
        label: "12 ago",
      },
      {
        value: -13,
        label: "13 ago",
      },
    ],
  };
  const card = _findCardWithType(stacks, "com_threepointdata_bosv31_AdminDateSelector");
  result.adminDateSelectorCard = card;
  if (!isNil(card)) {
    result.dateSelector = card.body;
    _setReports(result);
  }

  return result;
}

function _setReports(result: AdminDateSelectorData) {
  const reports: { name: string; downloadUrl?: string }[] = [];
  const reportActionStats = _findAction(result.adminDateSelectorCard, "Stats");
  const reportActionAggregator = _findAction(result.adminDateSelectorCard, "Aggregator");
  const reportActionCallCenter = _findAction(result.adminDateSelectorCard, "Call Center");
  const reportActionFlyNotes = _findAction(result.adminDateSelectorCard, "FlyNotes");
  const reportActionSelfCheckin = _findAction(result.adminDateSelectorCard, "Self Checkin");
  if (!isNil(reportActionStats)) {
    result.reportUrlStats = reportActionStats.path;
  }
  if (!isNil(reportActionAggregator)) {
    result.reportUrlAggregator = reportActionAggregator.path;
  }
  if (!isNil(reportActionCallCenter)) {
    result.reportUrlCallCenter = reportActionCallCenter.path;
  }
  if (!isNil(reportActionFlyNotes)) {
    result.reportUrlFlyNotes = reportActionFlyNotes.path;
  }
  if (!isNil(reportActionSelfCheckin)) {
    result.reportUrlSelfCheckin = reportActionSelfCheckin.path;
  }
  const practiceDetailsReportAction = _findAction(result.adminDateSelectorCard, "Practice");
  if (!isNil(practiceDetailsReportAction)) {
    reports.push({ name: "Download practice details report", downloadUrl: practiceDetailsReportAction.path });
  }
  const pendingReportAction = _findAction(result.adminDateSelectorCard, "Pending");
  if (!isNil(pendingReportAction)) {
    reports.push({ name: "Download pending synchronization report", downloadUrl: pendingReportAction.path });
  }
  result.reports = reports;
}

function _findPivotTableCards(stacks: [] = []): PivotTableV1Data {
  const result: PivotTableV1Data = {
    pivotTableV1Cards: [],
    pivotTables: [],
  };

  _findAllCardsWithType(stacks, "com_threepointdata_bosv31_analysis_PivotTableV1").forEach((card: any) => {
    result.pivotTableV1Cards.push(card);
    result.pivotTables.push(card.body);
  });
  return result;
}

function _findPaymentConfiguratorCards(stacks: [] = []) {
  let result: any[] = [];
  stacks.forEach((stack: any) => {
    result = result.concat(
      stack.cards.filter((card: any) => {
        return "com_threepointdata_bosv31_scheduling_payment_PracticePaymentConfigurator" === (card.languageType as string);
      }),
    );
  });
  return result;
}

function _findAllCardsWithType<T>(stacks: any[], languageType: string) {
  let result: T[] = [];
  stacks.forEach((stack: any) => {
    result = result.concat(
      stack.cards.filter((card: any) => {
        return (card.languageType as string) === languageType;
      }),
    );
  });
  return result;
}

function _populateSessionMapUpdateActionWithValue(result: AdminDateSelectorData, key, value) {
  const action = _findAction(result.adminDateSelectorCard, "Update Dates");
  if (!isNil(action)) {
    let actionValueObject = action.requirementsMet;
    if (isNil(actionValueObject)) {
      actionValueObject = {};
    }
    actionValueObject[key] = value;
    action.requirementsMet = actionValueObject;
  }
}

function _updatePaymentConfiguratorDocument(state: MiniSiteState, params: any) {
  const result: MiniSiteState = state;
  // if (result.paymentConfiguration && result.paymentConfiguration.paymentConfigurator.plan == null) {
  //   result.paymentConfiguration.paymentConfigurator.plan = {
  //     creditOnServicePeriodEnd: false,
  //     currency: "USD",
  //     flat: 0,
  //     flatRateForPeriod: true,
  //     maxAmount: 0,
  //     percentageAsBasisPoints: 0,
  //     period: "MONTH",
  //     periodAmount: 0,
  //     service: "DepositCollection",
  //   }
  // }
  forEach(params, (value, key) => {
    if (result.paymentConfiguration) {
      if (key === "email") {
        result.paymentConfiguration.paymentConfigurator.emailAddresses = value;
        result.paymentConfiguration.error = validatePaymentConfiguration(key, value, result.paymentConfiguration);
      } else if (key === "collectApplicationFee") {
        result.paymentConfiguration.paymentConfigurator.collectApplicationFee = value;
      }
      if(result.paymentConfiguration.paymentConfigurator.plan) {
        if(key === "currency") {
          result.paymentConfiguration.paymentConfigurator.plan.currency = value;
        } else if (key === "period") {
          result.paymentConfiguration.paymentConfigurator.plan.period = value;
        } else if (key === "periodAmount") {
          result.paymentConfiguration.paymentConfigurator.plan.periodAmount = value;
        } else if (key === "maxAmount") {
          result.paymentConfiguration.paymentConfigurator.plan.maxAmount = value;
        } else if (key === "flat") {
          result.paymentConfiguration.paymentConfigurator.plan.flat = value;
        } else if (key === "percentageAsBasisPoints") {
          result.paymentConfiguration.paymentConfigurator.plan.percentageAsBasisPoints = value;
        } else if (key === "creditOnServicePeriodEnd") {
          result.paymentConfiguration.paymentConfigurator.plan.creditOnServicePeriodEnd = value;
        } else if (key === "flatRateForPeriod") {
          result.paymentConfiguration.paymentConfigurator.plan.flatRateForPeriod = value;
        }
      }
      if (key === "mode") {
        result.paymentConfiguration.mode = value;
      }
    }
  });

  /*if ('emails' in params) {
      set(this, 'paymentConfigurator.emailAddresses', params.emails);
      delete params.emails;
    }
    else if ('collectApplicationFee' in params) {
      set(this, 'paymentConfigurator.collectApplicationFee', params.collectApplicationFee);
      delete params.collectApplicationFee;
    }
    else if ('error' in params) {
      set(this, 'error', params.error);
      delete params.error;
    }

    if (!isEmpty(params) && !isEmpty(get(this, 'paymentConfigurator.plan'))) {
      _.forEach(params, (value, key) => {
        const prop = `paymentConfigurator.plan.${key}`;
        if (key in get(this, 'paymentConfigurator.plan')) {
          set(this, prop, value);
        }
      });
    }*/
}
