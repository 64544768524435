import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { isNil } from "lodash-es";
import { ApplicationState } from "../common/state/model/ApplicationState";
import { SignIn } from "./components/SignIn";
import { TpdMFA } from "./components/TpdMFA";
import { useAppDispatch } from "../common/hooks/useAppDispatch";
import { deleteGroups, deletePractice } from "./state/SoAdminActions";
import { reload } from "../common/state/CommonActions";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "react-error-boundary";
import { MiniSiteLink } from "./components/MiniSiteLink";
import { WidgetLink } from "../common/components/WidgetLink";
import { AdminDateSelector } from "../minisite/components/AdminDateSelector";

const SoAdmin = () => {
  const handleError = useErrorHandler();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isPerspectiveLoading, setIsPerspectiveLoading] = useState(false);
  const practiceJsonDocuments = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceJsonDocuments);
  const iEntityCard = useSelector((state: ApplicationState) => state.session?.sessionData?.iEntityCard);
  const verificationChannel = useSelector((state: ApplicationState) => state.session?.sessionData?.verificationChannel);
  const newPatientBase = useSelector((state: ApplicationState) => state.common?.config?.newPatientBase);
  const adminDateSelectorCard = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.adminDateSelectorCard);
  const [deleteGroupMessage, setDeleteGroupMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const isMFAVisible = isNil(verificationChannel)
    ? false
    : ["PICK_CHANNEL", "VERIFY_CODE", "CANT_SEND_CODE", "VERIFIED"].indexOf(verificationChannel.verifyChannelState) >= 0;

  useEffect(() => {
    setDeleteGroupMessage("");
    setIsPerspectiveLoading(true);
    dispatch(reload("5", true))
      .then(() => {
        setIsPerspectiveLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [setIsPerspectiveLoading, dispatch, handleError]);

  const handleDeleteEmptyGroups = async () => {
    setDeleteGroupMessage("");
    const response = await dispatch(deleteGroups()).catch((error) => {
      handleError(error);
    });
    setDeleteGroupMessage(response.data?.message);
  };

  const handleDeletePractice = async (practiceJsonDocument) => {
    setOpenDialog(true);
    const deletePromise = async () => {
      await dispatch(deletePractice(practiceJsonDocument.owningOrganization?.id));
      await dispatch(reload("5"));
      setOpenDialog(false);
    };
    deletePromise().catch((error) => {
      handleError(error);
    });
    setOpenDialog(true);
  };

  if (isPerspectiveLoading) {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <CircularProgress size={100} />
        </div>
        <Typography variant="h5" align={"center"} style={{ marginTop: 20 }}>
          {t("perspective.loading.olbAdmin.text")}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Box>
        {!isMFAVisible && (
          <>
            {iEntityCard && (
              <Grid container justifyContent={"center"} padding={5}>
                <Grid item minWidth={{ sm: 500 }} maxWidth={{ sm: 500 }}>
                  <SignIn />
                </Grid>
              </Grid>
            )}
          </>
        )}
        {isMFAVisible && (
          <Grid container justifyContent={"center"} padding={5}>
            <Grid item minWidth={{ sm: 500 }} maxWidth={{ sm: 500 }}>
              <TpdMFA />
            </Grid>
          </Grid>
        )}
      </Box>
      <Grid container>
        <Grid item sm={6}>
          {practiceJsonDocuments && practiceJsonDocuments?.length > 0 && (
            <Card sx={{ margin: 3 }}>
              <CardHeader
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                  border: "1px solid #e3e3e3",
                  fontSize: 10,
                }}
                title="Active Practices"
              />
              <CardContent>
                <Grid container direction={"column"}>
                  <Grid item>
                    <Button onClick={handleDeleteEmptyGroups} variant={"outlined"} title="Only available on the sandbox" color="error" sx={{ marginBottom: 2 }}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      &nbsp;Remove empty groups
                    </Button>
                    &nbsp;{deleteGroupMessage}
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container direction={"column"} marginTop={2}>
                  {practiceJsonDocuments?.map((practiceJsonDocument) => {
                    return (
                      <Grid container item key={practiceJsonDocument.id} className={"highlight"} direction={"column"} marginBottom={1}>
                        <Grid item>
                          <Typography variant={"h6"}>
                            {practiceJsonDocument.value?.name}|{practiceJsonDocument.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box>
                            <MiniSiteLink practice={practiceJsonDocument} /> - Widget link:&nbsp;
                            <WidgetLink name={practiceJsonDocument.value?.name} accountingCode={practiceJsonDocument.name} newPatientBase={newPatientBase} />
                          </Box>
                        </Grid>
                        <Grid container item justifyContent={"end"}>
                          <Grid item>
                            <Button
                              onClick={() => handleDeletePractice(practiceJsonDocument)}
                              variant={"outlined"}
                              size="small"
                              color="error"
                              title="Only available on the sandbox"
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              &nbsp;Remove and clear all data
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item sm={6}>
          {adminDateSelectorCard && <AdminDateSelector></AdminDateSelector>}
        </Grid>
      </Grid>
      <Dialog open={openDialog}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Please wait while the practice is deleted. Depending on the number of records it may take several minutes...</DialogContentText>
        </DialogContent>
        <LinearProgress />
      </Dialog>
    </>
  );
};

export default connect()(SoAdmin);
