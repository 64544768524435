import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import { professionalTitleAndQualifications, providerImageURLWithDefault } from "../../common/utilities/ProviderHelpers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { reload } from "../../common/state/CommonActions";
import { deleteProvider } from "../../onlinebooking/state/OlbActions";
import { useErrorHandler } from "react-error-boundary";

export function ProviderInfo({ provider }: { provider: any }) {
  const handleError = useErrorHandler();
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector((state: ApplicationState) => state.session.sessionData?.isAuthenticated);

  const removeProvider = () => {
    const remove = async () => {
      await dispatch(deleteProvider(provider.id));
      await dispatch(reload("3"));
    };
    remove().catch((error) => {
      handleError(error);
    });
  };

  return (
    <Grid container item xs={5} data-testid={"t-provider-" + provider.id}>
      <Grid item style={{ marginRight: 15 }}>
        <Avatar src={providerImageURLWithDefault(provider)} alt={provider.name} sx={{ width: 56, height: 56 }}>
          {provider?.name?.charAt(0)}
        </Avatar>
      </Grid>
      <Grid item xs={8}>
        <Typography style={{ fontSize: 18 }}>{provider.name}</Typography>
        {isAuthenticated && (
          <a className={"remove-provider-link"} onClick={removeProvider}>
            Remove
          </a>
        )}
        <Typography style={{ fontSize: 14 }}>{professionalTitleAndQualifications(provider)}</Typography>
      </Grid>
    </Grid>
  );
}
