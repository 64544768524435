import React from "react";
import { AppointmentCards } from "../../AppointmentCards";
import { Mode } from "../../../state/OlbConstants";

export function ReadyForVerification(): React.JSX.Element {
  return (
    <>
      <AppointmentCards validationMode={Mode.CODE_VERIFICATION}></AppointmentCards>
    </>
  );
}
