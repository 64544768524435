import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MarkdownToHtml } from "../../onlinebooking/components/MarkdownToHtml";

export function AccordionWithToggle({
  title,
  text,
  testname,
  animated = false,
}: {
  title: string;
  text: string;
  testname: string;
  animated?: boolean;
}): React.JSX.Element {
  const [expanded, setExpanded] = useState(false);

  function toggleAccordion() {
    setExpanded(!expanded);
  }

  return (
    <>
      <span className={animated ? "animated accordion-toggle-title accordion-label" : "accordion-toggle-title accordion-label"} onClick={toggleAccordion}>
        {title}
        {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </span>

      <Accordion expanded={expanded} className={"accordion"} disableGutters={true} data-testid={testname}>
        <AccordionSummary className={"hidden"}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={"accordion-details"}>
          <MarkdownToHtml markdown={text} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
