import React from "react";
import { AppointmentCards } from "../../AppointmentCards";
import { CancellationComplaints } from "../../CancellationComplaints";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { Box } from "@mui/material";
import { AppointmentCardStateFailure } from "../../AppointmentCardStateFailure";

export function NotSynchronized({ showMedicalInformation = false }: { showMedicalInformation?: boolean }): React.JSX.Element {
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  return (
    <Box>
      <AppointmentCardStateFailure />
      {appointmentDocument && (
        <AppointmentCards showAddToCalendar={false} appointmentNotConfirmed={true} readonly={true} showMedicalInformation={showMedicalInformation} />
      )}
      <CancellationComplaints />
    </Box>
  );
}
