import { isEmpty, isNil, isNumber } from "lodash-es";
import { formatInTimeZone } from "date-fns-tz";
import { de, es, fr, it, nl, pl } from "date-fns/locale";
import { Countries } from "../../onlinebooking/constants/Countries";
import { countryForE164Number, formatLocal } from "./PhoneFormatWrapper";

export const locales = { de, es, fr, it, nl, pl };

export function isSmallFormFactor() {
  return window.matchMedia("(max-width: 480px)").matches;
}

export function formatPhone(number: string, countryCode?: string): string | undefined {
  // Using google phone library
  const countryCodeValue: string | undefined = isNil(countryCode) ? countryForE164Number(number) : countryCode;
  if (isEmpty(number)) {
    return "";
  }
  if (isEmpty(countryCodeValue)) {
    return number;
  }

  return formatLocal(number, countryCodeValue);
}

export function formatPhoneCountry(countryCode?: string): string {
  const result = Countries.filter((country) => country.code === countryCode);
  return result[0]?.name || "";
}

export function formatTime(format: string, timezone: string, date?: Date) {
  if (isNumber(date)) {
    date = new Date(date);
  }
  if (isNil(date) || date.getTime() === 0) {
    return "";
  }

  let time = formatInTimeZone(date, timezone, format, { locale: locales[(window as any).ThreePointData.localeLanguage] });
  if (isSmallFormFactor()) {
    time = time.replace("m", "");
  }

  return time;
}

export function formatWeekDay(format: string, timezone: string, date?: Date) {
  if (isNumber(date)) {
    date = new Date(date);
  }
  if (isNil(date) || date.getTime() === 0) {
    return "";
  }

  return formatInTimeZone(date, timezone, format, { locale: locales[(window as any).ThreePointData.localeLanguage] });
}

export function formatDay(timezone: string, date?: Date) {
  if (isNumber(date)) {
    date = new Date(date);
  }
  if (isNil(date) || date.getTime() === 0) {
    return "";
  }
  return formatInTimeZone(date, timezone, "dd", { locale: locales[(window as any).ThreePointData.localeLanguage] });
}

export function formatMonth(timezone: string, date?: Date) {
  if (isNumber(date)) {
    date = new Date(date);
  }
  if (isNil(date) || date.getTime() === 0) {
    return "";
  }
  return formatInTimeZone(date, timezone, "MMM", { locale: locales[(window as any).ThreePointData.localeLanguage] });
}

export function formatCurrency(amount, currency) {
  const FORMAT = {
    USD: "$",
    GBP: "£",
    EUR: "€",
    ANG: "ƒ",
    SGD: "$",
    NZD: "$",
    AUD: "$",
  };

  if (!isNil(amount)) {
    const converted = amount / 100;
    const symbol = FORMAT[currency];
    return `${symbol}${converted}`;
  }
  return "";
}
