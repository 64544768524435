import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";

export function PatientInformationLabelIfNeeded(): React.JSX.Element {
  const { t } = useTranslation();
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);

  const isTimeSelectionCompleted = (): boolean => {
    // JS CX1 Ember app: The code was there but never was set.  So I don't believe the message was ever used.
    return false;
  };

  let result: React.JSX.Element = <></>;
  if (isTimeSelectionCompleted()) {
    result = <h5>{t(`booking.summary.review.patientInformation.label.${uiComponentRoot}`)}</h5>;
  }
  return result;
}
