import { Money } from "../../../common/models/Money";
import {isEmpty, isNil} from "lodash-es";
import Patient from "../../../onlinebooking/state/model/Patient";

export default interface PaymentConfiguration {
  isProcessing: boolean;
  paymentConfiguratorCard: null;
  paymentConfigurator: PaymentConfiguratorBody;
  inDisenrollMode: boolean;
  inEnrollMode: boolean;
  inEnableMode: boolean;
  mode: string;
  actionName: null;
  error: any;
}

export type PaymentConfiguratorBody = {
  balance: Money;
  collectApplicationFee: boolean;
  emailAddresses: string;
  languageType: string;
  plan: PaymentConfiguratorPlan | null;
  processor: string;
};

export type PaymentConfiguratorPlan = {
  creditOnServicePeriodEnd: boolean;
  currency: string;
  flat: number;
  flatRateForPeriod: boolean;
  maxAmount: number;
  percentageAsBasisPoints: number;
  period: string;
  periodAmount: number;
  service: string;
};

export const validatePaymentConfiguration = (field?: string, value?: any, sourceConfiguration?: PaymentConfiguration) => {
  const errors = { ...sourceConfiguration?.error };

  if (isNil(field)) {
    errors["email"] = _validators.email.call(this, sourceConfiguration?.paymentConfigurator.emailAddresses);
  } else {
    const valueToTest = isNil(value) && !isNil(sourceConfiguration) ? sourceConfiguration[field] : value;
    const validator = _validators[field];
    if (validator) {
        errors[field] = validator.call(this, valueToTest);
    }
  }
  return errors;
}

const _validators = {
  email: function (email) {
    const re =
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    if (isEmpty(email) || !re.test(email)) {
      return "Please enter a valid email address";
    }
  }
};
