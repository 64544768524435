import React, {useMemo, useState} from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import * as Constants from "../../../onlinebooking/state/OlbConstants";
import { CommonActionTypes } from "../../../common/state/CommonActionTypes";
import { enrollInPlan } from "../../state/MiniSiteActions";
import { isNil } from "lodash-es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../../common/utilities/GeneralHelpers";
import {fetchPerspective} from "../../../common/state/CommonActions";

export function PaymentActivationServicePlan() {
  const paymentConfigurator = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState(paymentConfigurator?.plan?.period || "");
  const [periodAmount, setPeriodAmount] = useState(paymentConfigurator?.plan?.periodAmount || 0);
  const [maxAmount, setMaxAmount] = useState(paymentConfigurator?.plan?.maxAmount);
  const [flat, setFlat] = useState(paymentConfigurator?.plan?.flat || 0);
  const [percentageAsBasisPoints, setPercentageAsBasisPoints] = useState(paymentConfigurator?.plan?.percentageAsBasisPoints || 0);
  const [creditOnServicePeriodEnd, setCreditOnServicePeriodEnd] = useState(paymentConfigurator?.plan?.creditOnServicePeriodEnd || false);
  const [collectApplicationFee, setCollectApplicationFee] = useState(paymentConfigurator?.collectApplicationFee || false);
  const [flatRateForPeriod, setFlatRateForPeriod] = useState(paymentConfigurator?.plan?.flatRateForPeriod || false);
  const [isProcessing, setIsProcessing] = useState(false);
  const actionName = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.actionName);
  const readyToUpdatePlan = useMemo(() => {
    if(flatRateForPeriod) {
        return (periodAmount != null && !Number.isNaN(periodAmount)) &&
            (period != null && period != "");
    } else {
      return (maxAmount != null && !Number.isNaN(maxAmount))&&
          (flat != null && !Number.isNaN(flat)) &&
          (percentageAsBasisPoints != null && !Number.isNaN(percentageAsBasisPoints)) &&
          (period != null && period != "");
    }
  },[periodAmount, maxAmount, flat, percentageAsBasisPoints, period, flatRateForPeriod]);
  const isPlanEmpty = useMemo(() => {
    return paymentConfigurator?.plan == null
  },[paymentConfigurator]);

  function updatePlanType(event) {
    if (event.target.value === "period") {
      setFlatRateForPeriod(true);
    } else if (event.target.value === "transaction") {
      setFlatRateForPeriod(false);
    }
  }

  async function updatePlan() {
    const params = {
      period: period,
      periodAmount: periodAmount,
      maxAmount: null,
      flat: flat,
      percentageAsBasisPoints: percentageAsBasisPoints,
      creditOnServicePeriodEnd: creditOnServicePeriodEnd,
      collectApplicationFee: collectApplicationFee,
      flatRateForPeriod: flatRateForPeriod,
    };
    if (!Number.isNaN(maxAmount)) {
      (params.maxAmount as any) = maxAmount;
    }
    setIsProcessing(true);
    await dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: Constants.PAYMENT_CONFIGURATOR,
      params: params,
    });
    if (!isNil(paymentConfigurator)) {
      await dispatch(enrollInPlan(paymentConfigurator));
      await dispatch(fetchPerspective("1"));
    }
    setIsProcessing(false);
  }

  return (
    <Grid container sx={{ padding: 2 }}>
      <FormControl>
        {isPlanEmpty && <><Grid item xs={12}>
          <Typography variant={"h5"}>Service Plan</Typography>
        </Grid>
          <div>
            To create or update a plan, you need to have:
            <ul>
              <li>A payment processor linked</li>
              <li>Sales Rules setup</li>
              <li>Deposits turned on</li>
            </ul>
          </div></>}
        {!isPlanEmpty && <>
          <Grid item xs={12}>
            <Typography variant={"h5"}>Service Plan</Typography>
          </Grid>
            <Grid item container xs={12} style={{ margin: 10 }} spacing={2}>
          <Grid item xs={2} className={"center-vert"}>
            <strong className={"pull-right"}>Currency</strong>
          </Grid>
          <Grid item xs={8}>
            {paymentConfigurator?.plan?.currency}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ margin: 10 }} spacing={2}>
          <Grid item xs={2} className={"center-vert"}>
            <strong className={"pull-right"}>Period</strong>
          </Grid>
          <Grid item xs={8}>
            <Select required sx={{ width: 120 }} value={period} onChange={(e) => setPeriod(e.target.value)}>
              <MenuItem value={"DAY"}>Day</MenuItem>
              <MenuItem value={"WEEK"}>Week</MenuItem>
              <MenuItem value={"MONTH"}>Month</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ margin: 10 }} spacing={2}>
          <Grid item xs={2} className={"center-vert"}>
            <strong className={"pull-right"}>Type</strong>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup value={flatRateForPeriod ? "period" : "transaction"} onChange={updatePlanType}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormControlLabel value="period" control={<Radio />} label="Per period" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel value="transaction" control={<Radio />} label="Per transaction" />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        {(!flatRateForPeriod &&
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className={"service-plan-field"}>
              <TextField
                  required
                  label="Flat Amount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={flat}
                  helperText={"Enter the amount in cents."}
                  onChange={(e) => setFlat(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} md={4} className={"service-plan-field"}>
              <TextField
                  required
                  label="Percentage Amount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={percentageAsBasisPoints}
                  helperText={"Enter the amount as bases points."}
                  onChange={(e) => setPercentageAsBasisPoints(parseFloat(e.target.value))}
              />
            </Grid>
            <Grid item xs={12} md={4} className={"service-plan-field"}>
              <TextField
                  required
                  label="Max Amount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={maxAmount}
                  helperText={"Enter the amount in cents."}
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
              />
            </Grid>
          </Grid>)
            || (flatRateForPeriod &&
                <Grid item xs={12} md={4} className={"period-amount"}>
                  <TextField
                    required
                    label="Period Amount"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={periodAmount}
                    helperText={"Enter the amount in cents."}
                    onChange={(e) => setPeriodAmount(parseFloat(e.target.value))}
                  />
            </Grid>)
        }
        <Grid container>
          <Grid item xs={4} className={"pull-right"}>
            <strong>Apply Credit</strong>
          </Grid>
          <Grid item xs={8}>
            <strong>
              <FormControlLabel
                control={<Checkbox checked={creditOnServicePeriodEnd} onChange={(e) => setCreditOnServicePeriodEnd(e.target.checked)} />}
                label="Apply credit on service period end."
              />
            </strong>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} className={"pull-right"}>
            <strong>Collect Application Fee</strong>
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              control={<Checkbox checked={collectApplicationFee} onChange={(e) => setCollectApplicationFee(e.target.checked)} />}
              label="If enabled, the application fee will be collected to pay for service."
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box marginTop={"20px"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
            <Button disabled={isProcessing || !readyToUpdatePlan} variant={"contained"} onClick={updatePlan}>
              {isProcessing && <FontAwesomeIcon spin icon={faCog} />}{actionName}
            </Button>
          </Box>
        </Grid>
        </>}
      </FormControl>
    </Grid>
  );
}
