import React, { useEffect, useMemo } from "react";
import { BookingCard } from "../common/BookingCard";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { createEventRequest, nextRange, previousRange, setIsLoadingEvents } from "../../../state/OlbActions";
import {
  fetchProductConfiguration,
  handleWhenCMSIsActive,
  handleWhenEventsIsEmpty,
  loadEventsForNextRangeNewExisting,
  loadEventsForShapeChangeNewExisting,
  updateAppointmentDocumentWithProductConfiguration,
} from "../../../state/OlbActionsForNewExisting";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { isNil } from "lodash-es";
import { selectEvent } from "../../../state/TimeSelectionActions";
import { NextRangeFunction, PreviousRangeFunction } from "../StepTypes";
import { cardActionService, saveDocument } from "../../../../common/state/DocumentSaving";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";
import { reload, setIsLoading } from "../../../../common/state/CommonActions";
import { Button, Grid } from "@mui/material";
import { SelectATime } from "../common/SelectATime";
import { determineIsTermsOfUseAccepted } from "../../../state/OlbSelectors";
import { useTranslation } from "react-i18next";
import isCMSActive from "../../../utilities/CmsChecker";
import PartnerComponent from "../../PartnerComponent";
import { useErrorHandler } from "react-error-boundary";

export function Step39(): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const patientDocuments = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocuments);
  const isTermsOfUseAccepted = useSelector(determineIsTermsOfUseAccepted);
  const productConfiguration = useSelector((state: ApplicationState) => state.olb?.productConfiguration);
  const isTpdGdprNotCompleted = useMemo(() => {
    return !isTermsOfUseAccepted;
  }, [isTermsOfUseAccepted]);
  const isTimeMissing = useSelector((state: ApplicationState) => isNil(state.olb?.appointmentData?.appointmentDocument?.startTime));
  const isTimeSelectionCompleted = useMemo(() => {
    return isTimeMissing === false;
  }, [isTimeMissing]);
  const isTimeSelectionNotCompleted = useMemo(() => {
    return !isTimeSelectionCompleted;
  }, [isTimeSelectionCompleted]);
  const nextButtonLabel = useMemo(() => {
    let result = t("booking.button.selectTime.label");
    if (isTimeSelectionCompleted) {
      if (isCMSActive()) {
        result = t("booking.button.patientInformation.sales.label");
      } else {
        result = t(`booking.button.patientInformation.label.${uiComponentRoot}`);
      }
    }
    return result;
  }, [isTimeSelectionCompleted, t]);
  const EventShapeConfigurator = useMemo(() => {
    return !isNil(uiComponentRoot) && PartnerComponent[uiComponentRoot]
      ? PartnerComponent[uiComponentRoot].eventShapeConfigurator
      : PartnerComponent.tpd.eventShapeConfigurator;
  }, [uiComponentRoot]);

  useEffect(() => {
    if (!isNil(appointmentDocument) && !isNil(patientDocuments)) {
      dispatch(selectEvent(appointmentDocument, true));
      const fetchData = async () => {
        dispatch(setIsLoadingEvents(true));
        await dispatch(fetchProductConfiguration());
        await dispatch(updateAppointmentDocumentWithProductConfiguration());
        await dispatch(createEventRequest());
        await dispatch(loadEventsForShapeChangeNewExisting());
        await dispatch(handleWhenEventsIsEmpty());
        await dispatch(handleWhenCMSIsActive());
        dispatch(setIsLoadingEvents(false));
      };
      fetchData().catch((error) => {
        handleError(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviousRange: PreviousRangeFunction = async () => {
    await dispatch(previousRange());
    await dispatch(loadEventsForNextRangeNewExisting()).catch((error) => {
      handleError(error);
    });
  };

  const handleNextRange: NextRangeFunction = async () => {
    await dispatch(nextRange());
    await dispatch(loadEventsForNextRangeNewExisting()).catch((error) => {
      handleError(error);
    });
  };

  const handleNext = async () => {
    await dispatch(setIsLoading(true));
    const update = async () => {
      await dispatch(saveDocument(DocumentConstants.PATIENT));
      await dispatch(saveDocument(DocumentConstants.APPOINTMENT));
      await dispatch(cardActionService.execute({ stepActionName: "Book" }));
      document.querySelector("body")?.scrollTo(0, 0);
      await dispatch(reload("3"));
      await dispatch(setIsLoading(false));
    };
    update().catch((error) => {
      handleError(error);
    });
  };

  return (
    <BookingCard>
      <>
        {productConfiguration && <EventShapeConfigurator />}
        <SelectATime onPreviousRange={handlePreviousRange} onNextRange={handleNextRange} />
        <Grid container direction="row-reverse">
          <Button
            data-testid="t-book-next"
            type="submit"
            style={{ marginRight: 20, marginBottom: 20 }}
            variant="contained"
            size="large"
            disabled={isTimeSelectionNotCompleted || isTpdGdprNotCompleted}
            onClick={handleNext}
            id={"EventListButton"}
          >
            {nextButtonLabel} <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </Button>
        </Grid>
      </>
    </BookingCard>
  );
}
