import React from "react";
import { AppointmentCards } from "../../AppointmentCards";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { Box } from "@mui/material";

export function GenerateCode({ currentStepId }: { currentStepId: string }): React.JSX.Element {
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  return (
    <Box>
      {appointmentDocument && <AppointmentCards appointmentNotConfirmed={true} readonly={true} showPatientValidation={true} currentStepId={currentStepId} />}
    </Box>
  );
}
