import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientValidationChannels } from "./PatientValidationChannels";
import { FormControl, Grid, Icon, InputAdornment, OutlinedInput } from "@mui/material";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faGear } from "@fortawesome/free-solid-svg-icons";
import { debounce, isEmpty } from "lodash-es";
import { reload, updateDocument } from "../../common/state/CommonActions";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { cardActionService, saveDocument } from "../../common/state/DocumentSaving";
import { TEXTFIELD_DEBOUNCE_WAIT } from "../../common/constants/HelperConstants";
import { useErrorHandler } from "react-error-boundary";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../common/state/model/ApplicationState";

export function PatientValidationEnterCode(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const hasEnteredVerificationCode = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.hasEnteredVerificationCode);
  const verificationCode = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.verificationCode);
  const [isValidCode, setIsValidCode] = useState(false);
  const [userEnteredCode, setUserEnteredCode] = useState(hasEnteredVerificationCode ? verificationCode : "");
  const [isValidating, setIsValidating] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChanged = useMemo(() => debounce(onChanged, TEXTFIELD_DEBOUNCE_WAIT), []);

  function onChangedInternal(event) {
    setUserEnteredCode(event.target.value);
    debouncedOnChanged(event.target.value);
    if (event.target.value.length > 0) {
      dispatch(updateDocument(DocumentConstants.PATIENT, {
        hasEnteredVerificationCode: true,
        verificationCode: event.target.value
      }));
    } else if(event.target.value.length == 0) {
      dispatch(updateDocument(DocumentConstants.PATIENT, {
        hasEnteredVerificationCode: false,
        verificationCode: event.target.value
      }));
    }
  }

  async function onChanged(value) {
    if (value?.length >= 4) {
      setIsValidating(true);
      try {
        await dispatch(updateDocument(DocumentConstants.PATIENT, { verificationCode: value }));
        await dispatch(saveDocument(DocumentConstants.PATIENT));
        await dispatch(cardActionService.execute({ stepActionName: "Verify" }));
        await dispatch(reload("3"));
        setIsValidating(false);
      } catch (error: any) {
        setIsValidating(false);
        if (422 === error?.response?.status) {
          setIsValidCode(false);
        } else {
          handleError(error);
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      debouncedOnChanged.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={"margin-left-10"}>
      <p className="lead">{t("booking.verifyCode.beforeBooking.text")}</p>
      <PatientValidationChannels />
      <p className="lead">{t("booking.verifyCode.enterCode.text")}</p>
      <Grid container>
        <FormControl>
          <OutlinedInput
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            data-testid={"t-codevalidation-code"}
            value={userEnteredCode}
            required={true}
            autoFocus={true}
            onChange={onChangedInternal}
            error={!isValidCode && !isEmpty(userEnteredCode)}
            endAdornment={
              <InputAdornment position={"end"}>
                {/*<IconButton edge={"end"}>*/}
                {isValidating ? (
                  <FontAwesomeIcon spin={true} icon={faGear} />
                ) : isValidCode || isEmpty(userEnteredCode) ? (
                  <Icon />
                ) : (
                  <FontAwesomeIcon icon={faCircleXmark} />
                )}
                {/*</IconButton>*/}
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <p className="lead">{t("booking.verifyCode.enterCode.help.text", { back: t("booking.button.goBackDetails.label") })}</p>
    </Grid>
  );
}
