import React from "react";
import { NotSynchronized } from "../common/NotSynchronized";
import { BookingCard } from "../common/BookingCard";
import management from "../../../../common/utilities/Management";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";

export function Step37(): React.JSX.Element {
  const allowHealthInformationOnTreatment = useSelector((state: ApplicationState) => state.common?.config?.allowHealthInformationOnTreatment);

  return (
    <BookingCard>
      <NotSynchronized showMedicalInformation={management.partnerAdapter.showMedicalInformationForTreatmentPlan(allowHealthInformationOnTreatment)} />
    </BookingCard>
  );
}
