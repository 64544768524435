import axios, { AxiosResponse } from "axios";
import { CommonActionTypes } from "./CommonActionTypes";
import { ApplicationNormalizer } from "../../onlinebooking/state/ApplicationNormalizer";
import { AppThunk } from "./DocumentSaving";
import * as MetricActions from "../metrics/MetricActions";
import { CX_API_ADDRESS } from "../constants/PathConstants";

export const organizationId = (window as any).ThreePointData.organizationID;
export type perspectives = "1" | "3" | "5";

export const reload = (perspective: perspectives, firstTime: boolean = false) => {
  return async (dispatch) => {
    await dispatch(fetchPerspective(perspective));
    if (firstTime) {
      await dispatch(setupAnalytics());
    }
    return await dispatch(updateAnalyticsForReload());
  };
};

const setupAnalytics = () => {
  return async (dispatch, getState) => {
    return MetricActions.setup(getState().common.analyticsData);
  };
};
const updateAnalyticsForReload = () => {
  return (dispatch, getState) => {
    MetricActions.trackPage(getState().common.analyticsData.analytics);
  };
};

export const doPerspectiveCall = (perspective: perspectives): Promise<AxiosResponse> => {
  const timestamp = new Date().getTime();
  const params = {
    perspective,
    timestamp,
  };
  const path = CX_API_ADDRESS + "/organization/" + organizationId + "/perspectives";
  // eslint-disable-next-line
  return axios.get(path, { params }).then((response) => {
    ApplicationNormalizer._normalizeStacksAndCards(response.data.stacks);
    return response.data;
  });
};

export const fetchPerspective = (perspective: perspectives): AppThunk<Promise<AxiosResponse>> => {
  return async (dispatch) => {
    const data = await doPerspectiveCall(perspective);
    dispatch(receivePerspective(data));
    return data;
  };
};

export const receivePerspective = (json: any) => {
  return {
    type: CommonActionTypes.RECEIVE_PERSPECTIVE,
    state: json || {},
  };
};

export function updateDocument(documentType: string, params: any) {
  return {
    type: CommonActionTypes.UPDATE_DOCUMENT,
    documentType,
    params,
  };
}

export function setIsLoading(isLoading: boolean) {
  return {
    type: CommonActionTypes.UPDATE_IS_LOADING,
    isLoading,
  };
}
