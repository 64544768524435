import React, {useState} from "react";
import { updateEventRequest } from "../../../../state/OlbActions";
import { updateDocument } from "../../../../../common/state/CommonActions";
import { updateAnalytics } from "../../../../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../../../../common/analytics/AnalyticsConstants";
import { shapeChanged } from "../../../../state/OlbActionsForNewExisting";
import { useAppDispatch } from "../../../../../common/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { determineNhsFee } from "../../../../../minisite/state/MiniSiteSelectors";
import { useTranslation } from "react-i18next";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ConfiguratorFieldOptions from "../../../../state/model/ConfiguratorFieldIOptions";
import { ApplicationState } from "../../../../../common/state/model/ApplicationState";
import { DocumentConstants } from "../../../../../common/constants/DocumentConstants";
import { includes, isNil } from "lodash-es";
import { AccordionWithToggle } from "../../../../../common/components/AccordionWithToggle";

export function InsuranceSelector({ fieldOptions }: { fieldOptions: ConfiguratorFieldOptions }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const nhsFeeDocument = useSelector(determineNhsFee);
  const { readOnly, defaultValue, isHidden, idsToFilter } = fieldOptions || {};
  const insuranceType = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.insuranceType);
  const internalPayorType = insuranceType || defaultValue;
  const [isNhs, isPrivate] = [internalPayorType === "NHS", internalPayorType === "Private"];
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);

  const hasNhsFeeMarkdown = (nhsFeeDocument?.value.value || "").length > 0;
  const showNhsFeeLink = isNhs && hasNhsFeeMarkdown;
  const isInsuranceSelectorHidden = isHidden || !includes(idsToFilter, "NHS");

  const onInsuranceTypeChanged = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isNil(value) && value !== internalPayorType) {
      await dispatch(updateEventRequest({ payorType: value, providerId: null }));
      await dispatch(updateDocument(DocumentConstants.APPOINTMENT, { insuranceType: value }));
      await updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "EventRequest",
        action: "payorType",
        label: value,
      });
      await dispatch(shapeChanged());
    }
  };

  let label: React.JSX.Element = <></>;

  if (isNhs) {
    label = <p className="form-control-static">{t("booking.insurance.nhs.label")}</p>;
  } else if (isPrivate) {
    label = <p className="form-control-static">{t("booking.insurance.private.label")}</p>;
  }

  const notReadOnly = (
    <>
      <ToggleButtonGroup disabled={isLoadingEvents} color={"primary"} value={internalPayorType} exclusive onChange={onInsuranceTypeChanged} data-testid={"t-insurance-selector"}>
        <ToggleButton value={"Private"}>{t("booking.insurance.private.label")}</ToggleButton>
        <ToggleButton value={"NHS"}>{t("booking.insurance.nhs.label")}</ToggleButton>
      </ToggleButtonGroup>
    </>
  );

  return (
    <>
      {!isInsuranceSelectorHidden && (
        <Grid container item alignItems={"center"} xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={2} className={"event-shape-option-label"}>
            {t("booking.insurance.label")}
          </Grid>
          <Grid item xs={12} sm={10}>
            {readOnly ? label : notReadOnly}
            {showNhsFeeLink && (
              <AccordionWithToggle
                title={"Show Fees"}
                text={nhsFeeDocument.value.value}
                testname={"t-insurance-selector-nhs-accordion " + nhsFeeDocument.name}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
