import { ProductConfigurationFieldType } from "./ProductConfiguration";

export default class ConfiguratorFieldOptions implements ProductConfigurationFieldType {
  type?: string;
  default?: string;
  readonly?: boolean;
  enum?: any[];

  //
  constructor(toWrap: { type: string; default: string; readonly: boolean; enum?: any[] }) {
    this.type = toWrap.type;
    this.default = toWrap.default;
    this.readonly = toWrap.readonly;
    this.enum = toWrap.enum;
  }

  get fieldType() {
    return (this as any).type;
  }

  get defaultValue() {
    return (this as any).default;
  }

  get readOnly() {
    return (this as any).readonly;
  }

  get idsToFilter(): any[] {
    return (this as any).enum || [];
  }

  get idsToFilterIsEmpty() {
    return (this as any).idsToFilter.length == 0;
  }

  get isHidden() {
    return (this as any).fieldType == "hidden";
  }
}
