import { capitalize, isEmpty, merge, startsWith } from "lodash-es";
import { removeFromDom } from "./utils";

class MultipleGoogleAnalytics4 {
  trackerNames: string[] = [];

  init(config: any = {}) {
    const { providers } = config;

    // TODO: JS Not sure why we need to delete the trackingIds from the config
    // delete config.trackingIds;

    /* jshint ignore:start */
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (i[r].q = i[r].q || []).push(arguments);
        }),
        // @ts-ignore
        (i[r].l = 1 * new Date());
      // @ts-ignore
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      // @ts-ignore
      a.async = 1;
      // @ts-ignore
      a.src = g;
      // @ts-ignore
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
    /* jshint ignore:end */

    if (!isEmpty(providers)) {
      let counter = 0;
      providers.forEach((trackerId) => {
        if (startsWith(trackerId, "UA")) {
          const trackerName = `olb${counter}`;
          this.trackerNames.push(trackerName);
          // @ts-ignore
          window.ga("create", trackerId, "auto", trackerName);
          counter++;
        }
      });
    }
  }

  trackEvent(this: MultipleGoogleAnalytics4, options: any = {}) {
    const compactedOptions: any = options;
    const sendEvent = { hitType: "event" };
    const gaEvent: any = {};

    if (compactedOptions.nonInteraction) {
      gaEvent.nonInteraction = compactedOptions.nonInteraction;
      delete compactedOptions.nonInteraction;
    }

    for (const key in compactedOptions) {
      const capitalizedKey = capitalize(key);
      gaEvent[`event${capitalizedKey}`] = compactedOptions[key];
    }

    const event = merge(sendEvent, gaEvent);

    if (!isEmpty(this.trackerNames)) {
      this.trackerNames.forEach((trackerName) => {
        // @ts-ignore
        window.ga(`${trackerName}.send`, event);
      });
    }

    return event;
  }

  trackPage(this: MultipleGoogleAnalytics4, options: any = {}) {
    const compactedOptions = options;
    const sendEvent = { hitType: "pageview" };

    const event = merge(sendEvent, compactedOptions);

    if (!isEmpty(this.trackerNames)) {
      this.trackerNames.forEach((trackerName) => {
        // @ts-ignore
        window.ga(`${trackerName}.send`, event);
      });
    }

    return event;
  }

  willDestroy(this: MultipleGoogleAnalytics4) {
    this.trackerNames = [];
    removeFromDom(`script[src*="google-analytics"]`);
    // @ts-ignore
    delete window.ga;
  }
}

export default MultipleGoogleAnalytics4;
