import React, { useState } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { PaymentActivationServicePlan } from "./PaymentActivationServicePlan";
import { isNil } from "lodash-es";
import { disenrollInPlan } from "../../state/MiniSiteActions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonActionTypes } from "../../../common/state/CommonActionTypes";
import * as Constants from "../../../onlinebooking/state/OlbConstants";
import { PaymentActivationMode } from "../../constants/MiniSiteConstants";
import {fetchPerspective} from "../../../common/state/CommonActions";

export function PaymentActivationDisenroll() {
  const paymentConfigurator = useSelector((state: ApplicationState) => state.miniSite?.paymentConfiguration?.paymentConfigurator);
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const onDisenroll = async () => {
    setIsProcessing(true);
    if (!isNil(paymentConfigurator)) {
      await dispatch(disenrollInPlan());
      await dispatch(fetchPerspective("1"));
    }
    setIsProcessing(false);
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: Constants.PAYMENT_CONFIGURATOR,
      params: { mode: PaymentActivationMode.ENROLL },
    });
  };
  return (
    <Grid container sx={{ padding: 2 }}>
      <Grid item xs={8}>
        The practice has been linked with the payment processor.
      </Grid>

      <Grid item xs={12}>
        <Box marginTop={"20px"} display={"flex"} justifyContent={"flex-end"} alignItems={"flex-end"}>
          <Button variant={"contained"} disabled={isProcessing} onClick={onDisenroll}>
            {isProcessing && <FontAwesomeIcon spin icon={faCog} />}Disenroll
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Divider />
      </Grid>
    </Grid>
  );
}
