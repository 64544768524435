import React from "react";
import { BookAppointment } from "../common/BookAppointment";
import { BookingCard } from "../common/BookingCard";
import management from "../../../../common/utilities/Management";

export function Step13(): React.JSX.Element {
  return (
    <BookingCard>
      <BookAppointment currentStepId={"13"} showMedicalInformation={management.partnerAdapter.showMedicalInformation()} />
    </BookingCard>
  );
}
