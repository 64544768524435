import HenrySchein from "../partners/henry-schein";

class Management {
  partnerAdapter;

  constructor() {
    this.partnerAdapter = new HenrySchein();
  }
}

const management = new Management();
export default management;
