import React, { useState } from "react";
import { MinisiteCard } from "./MinisiteCard";
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { updateDocument } from "../../common/state/CommonActions";
import { sendReport } from "../state/MiniSiteActions";
import { MiniSiteActionTypes } from "../state/MiniSiteActionTypes";
import { saveDocument } from "../../common/state/DocumentSaving";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { MiniSiteConstants } from "../constants/MiniSiteConstants";
import { useErrorHandler } from "react-error-boundary";

export function AdminDateSelector(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const periods = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.periods);
  const period = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.dateSelector?.range?.period);
  const offsets = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.offsets);
  const offset = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.dateSelector?.range?.offset);
  const reports = useSelector((state: ApplicationState) => state.miniSite?.adminDateSelectorData?.reports);
  const [isSaving, setIsSaving] = useState(false);

  const onPeriodChanged = (event) => {
    dispatch(updateDocument(MiniSiteConstants.ADMIN_DATE_SELECTOR, { period: event.target.value, offset: offset }));
  };

  const onOffsetChanged = (event) => {
    dispatch(
      updateDocument(MiniSiteConstants.ADMIN_DATE_SELECTOR, {
        offset: Number(event.target.value),
        period: period,
      }),
    );
  };

  const emailStatsReport = () => {
    dispatch(sendReport(MiniSiteActionTypes.SEND_REPORT_STATS)).catch((error) => {
      handleError(error);
    });
  };

  const emailAggregatorReport = () => {
    dispatch(sendReport(MiniSiteActionTypes.SEND_REPORT_AGGREGATOR)).catch((error) => {
      handleError(error);
    });
  };

  const emailCallCenterReport = () => {
    dispatch(sendReport(MiniSiteActionTypes.SEND_REPORT_CALLCENTER)).catch((error) => {
      handleError(error);
    });
  };
  const emailFlyNotesReport = () => {
    dispatch(sendReport(MiniSiteActionTypes.SEND_REPORT_FLYNOTES)).catch((error) => {
      handleError(error);
    });
  };
  const emailSelfCheckinReport = () => {
    dispatch(sendReport(MiniSiteActionTypes.SEND_REPORT_SELFCHECKIN)).catch((error) => {
      handleError(error);
    });
  };

  const onUpdateReports = async () => {
    setIsSaving(true);
    await dispatch(saveDocument(DocumentConstants.ADMIN_DATE_SELECTOR)).catch((error) => {
      handleError(error);
    });
    setIsSaving(false);
  };

  return (
    <MinisiteCard cardHeader={"Reports"} isSaving={isSaving}>
      <p>
        The following settings are used by the <strong>stats report</strong> to determine the date range. To update, select a Period and Offset and then click
        the &quot;Update stats reports&quot; button.
      </p>

      <Grid container spacing={2}>
        <Grid item>
          <FormControl>
            <InputLabel>Period</InputLabel>
            {periods && (
              <Select value={period} label="period" onChange={onPeriodChanged}>
                {periods.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel>Offset</InputLabel>
            {offsets && (
              <Select value={offset} label="offset" onChange={onOffsetChanged}>
                {offsets.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid container item xs={12} justifyContent={"end"}>
          <Button variant="contained" disabled={isSaving} onClick={onUpdateReports}>
            Update reports
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container padding={2}>
          <Grid item xs={12}>
            <a href="#" onClick={emailStatsReport}>
              Email Stats report
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="#" onClick={emailAggregatorReport}>
              Email Aggregator report
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="#" onClick={emailCallCenterReport}>
              Email Call Center report
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="#" onClick={emailFlyNotesReport}>
              Email FlyNotes report
            </a>
          </Grid>
          <Grid item xs={12}>
            <a href="#" onClick={emailSelfCheckinReport}>
              Email Self Checkin report
            </a>
          </Grid>
          {reports &&
            reports.map((report) => {
              return (
                <Grid item xs={12} key={report.name}>
                  <a href={report.downloadUrl}>{report.name}</a>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </MinisiteCard>
  );
}
