import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { updateDocument } from "../../common/state/CommonActions";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { AccordionWithToggle } from "../../common/components/AccordionWithToggle";

export function TpdGdpr({
  required,
  form,
  sourceId,
  patientField,
  patientFieldChangeDate,
  text,
  checked,
}: {
  required: boolean;
  form: any;
  sourceId: string;
  patientField: string;
  patientFieldChangeDate: string;
  text: string;
  checked: boolean | undefined;
}): React.JSX.Element {
  const dispatch = useAppDispatch();

  function toggleChecked(event) {
    dispatch(
      updateDocument(DocumentConstants.PATIENT, {
        internalPatientId: sourceId,
        properties: { [patientField]: event.target.checked, [patientFieldChangeDate]: new Date() },
      }),
    );
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox sx={{ ml: 1 }} checked={checked} onClick={toggleChecked} data-testid={"t-tpd-gdpr-" + form.title.replace(/\s+/g, "-").toLowerCase()} />
        }
        label={
          <div className={required && !checked ? "animated required" : ""}>
            {required ? "* " : ""}
            {text}
          </div>
        }
      />
      <AccordionWithToggle title={form.title} text={form.content} animated={required && !checked} testname={"t-tpd-gdpr-accordion"} />
    </>
  );
}
