import React, { useEffect, useState } from "react";
import { BookingCard } from "../common/BookingCard";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { reload, updateDocument } from "../../../../common/state/CommonActions";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";
import { useErrorHandler } from "react-error-boundary";
import { cardActionService, saveDocument } from "../../../../common/state/DocumentSaving";
import { depositFailed, executeDepositCheckout } from "../../../state/OlbActions";
import { Button, Grid } from "@mui/material";
import { AppointmentCards } from "../../AppointmentCards";
import { useTranslation } from "react-i18next";
import { Mode } from "../../../state/OlbConstants";

export function Step46(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const [isTakingDeposit, setIsTakingDeposit] = useState(false);

  // Reset error message
  useEffect(() => {
    dispatch(depositFailed(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNext = async () => {
    setIsTakingDeposit(true);
    try {
      await dispatch(executeDepositCheckout());
    } catch (error: any) {
      setIsTakingDeposit(false);
      if (422 === error.response.status) {
        dispatch(depositFailed(t("booking.payment.error.text")));
      } else {
        handleError(error);
      }
    }
  };

  const goToPrevious = async () => {
    try {
      await dispatch(updateDocument(DocumentConstants.APPOINTMENT, { payment: null }));
      await dispatch(saveDocument(DocumentConstants.APPOINTMENT));
      await dispatch(cardActionService.execute({ stepActionName: "Back" }));
      await dispatch(reload("3"));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <BookingCard>
        <>
          <Grid container>
            <Grid item xs={12}>
              {appointmentDocument && (
                <AppointmentCards validationMode={Mode.DEPOSIT}></AppointmentCards>
                // sources = (readonly appointmentService.appointmentDocument.sources)
                // providers=(readonly providerService.providerDocuments)
                // patientService=(readonly patientService)
                // validationMode=(readonly mode)
                // paymentError=(readonly paymentService.error)
                // onExecuteActions=(action "onExecuteActions")
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent={"space-between"} direction={"row"} alignItems={"center"} sx={{ "& .MuiButton-root": { m: 2 } }}>
            <Grid item>
              <Button data-testid="t-book-previous" variant="outlined" size="large" onClick={goToPrevious}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i> {t("booking.button.goBackDetails.label")}
              </Button>
            </Grid>
            <Grid item>
              <Button data-testid="t-book-next" type="submit" variant="contained" size="large" disabled={isTakingDeposit} onClick={goToNext}>
                {t("booking.button.depositandBookNow.label")} <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </Button>
            </Grid>
          </Grid>
        </>
      </BookingCard>
    </>
  );
}
