export function _findCardWithType(stacks: any[], cardType: string): any {
  let result: any = null;
  stacks.forEach((stack: any) => {
    if (result === null) {
      const foundCard = stack.cards.find((card: any) => {
        return cardType === card.languageType;
      });
      result = foundCard ? foundCard : null;
    }
  });
  return result;
}

export function _findJSONSchemaDocumentCardsWithIdentifierOf(stacks: [] = [], identifier: string | null = null) {
  let result: any[] = [];
  if (identifier !== null) {
    stacks.forEach((stack: any) => {
      result = result.concat(
          stack.cards.filter((card: any) => {
            return (
                "com_threepointdata_bosv31_document_JSONSchemaDocument" === (card.languageType as string) &&
                identifier === card.identifier
            );
          }),
      );
    });
  }
  return result;
}
