import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { MarkdownToHtml } from "./MarkdownToHtml";
import { ContentType } from "../state/OlbState";
import { Box, Typography } from "@mui/material";

export function CancellationComplaints(): React.JSX.Element {
  const showCancellationAndComplaints = useSelector((state: ApplicationState) => state.common?.config?.showCancellationAndComplaints);
  const cancellationPolicyDocument = useSelector((state: ApplicationState) => state.olb?.cancellationPolicyData?.cancellationPolicyDocument);
  const complaintsProcedureDocument = useSelector((state: ApplicationState) => state.olb?.complaintsPolicyData?.complaintsPolicyDocument);

  // *** Rendering logic ***
  let cancellationPolicy;
  let cancellationPolicyPlainTextOrMarkdown;
  let complaintsProcedure;
  let complaintsProcedurePlainTextOrMarkdown;

  if (cancellationPolicyDocument && ContentType.PlainText === cancellationPolicyDocument.contentType) {
    cancellationPolicyPlainTextOrMarkdown = <span className="tpd-white-space__pre-wrap">{cancellationPolicyDocument.content}</span>;
  } else {
    cancellationPolicyPlainTextOrMarkdown = (
      <div className="form-group">
        <MarkdownToHtml markdown={cancellationPolicyDocument?.content} />
      </div>
    );
  }
  if (cancellationPolicyDocument?.content) {
    cancellationPolicy = (
      <>
        <Typography variant="h5">{cancellationPolicyDocument?.title}</Typography>
        <Typography component={"span"} variant="body2" color="text.secondary">
          {cancellationPolicyPlainTextOrMarkdown}
        </Typography>
      </>
    );
  }

  if (complaintsProcedureDocument && ContentType.PlainText === complaintsProcedureDocument.contentType) {
    complaintsProcedurePlainTextOrMarkdown = <span className="tpd-white-space__pre-wrap">{complaintsProcedureDocument?.content}</span>;
  } else {
    complaintsProcedurePlainTextOrMarkdown = (
      <div className="form-group">
        <MarkdownToHtml markdown={complaintsProcedureDocument?.content} />
      </div>
    );
  }
  if (complaintsProcedureDocument?.content) {
    complaintsProcedure = (
      <>
        <Typography variant="h5">{complaintsProcedureDocument?.title}</Typography>
        <Typography component={"span"} variant="body2" color="text.secondary">
          {complaintsProcedurePlainTextOrMarkdown}
        </Typography>
      </>
    );
  }

  return (
    <Box style={{ margin: 20 }}>
      {showCancellationAndComplaints && (
        <>
          {" "}
          {cancellationPolicy} {complaintsProcedure}
        </>
      )}
    </Box>
  );
}
