import React from "react";
import { BookingCard } from "../common/BookingCard";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppointmentCards } from "../../AppointmentCards";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { reload, setIsLoading } from "../../../../common/state/CommonActions";
import { cardActionService, saveDocument } from "../../../../common/state/DocumentSaving";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { isNil } from "lodash-es";
import { useErrorHandler } from "react-error-boundary";

export function Step40(): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const appointmentDocument = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument);
  const patientDocument = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument);
  const patientHasErrors = !isNil(patientDocument?.errors)
    ? Object.keys(patientDocument?.errors).filter((key) => patientDocument?.errors[key]).length > 0
    : true;
  const nextButtonLabel = !patientHasErrors ? t("booking.button.verifyAppointment.label") : t("booking.button.patientInformationContinue.label");

  const goToPrevious = async () => {
    const update = async () => {
      await dispatch(setIsLoading(true));
      await dispatch(saveDocument(DocumentConstants.APPOINTMENT));
      await dispatch(saveDocument(DocumentConstants.PATIENT));
      await dispatch(cardActionService.execute({ stepActionName: "Back" }));
      document.querySelector("body")?.scrollTo(0, 0);
      await dispatch(reload("3"));
      await dispatch(setIsLoading(false));
    };

    update().catch((error) => {
      handleError(error);
    });
  };

  const next = async () => {
    const update = async () => {
      await dispatch(setIsLoading(true));
      await dispatch(saveDocument(DocumentConstants.PATIENT));
      await dispatch(saveDocument(DocumentConstants.APPOINTMENT));
      await dispatch(cardActionService.execute({ stepActionName: "PatientInfo" }));
      document.querySelector("body")?.scrollTo(0, 0);
      await dispatch(reload("3"));
      await dispatch(setIsLoading(false));
    };

    update().catch((error) => {
      handleError(error);
    });
  };

  return (
    <BookingCard>
      <form>
        <Grid container>
          <Grid item sm={12}>
            {appointmentDocument && <AppointmentCards showPatientInformation={true} />}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"space-between"} direction={"row"} alignItems={"center"} sx={{ "& .MuiButton-root": { m: 2 } }}>
          <Grid item>
            <Button data-testid="t-book-previous" variant="outlined" size="large" onClick={goToPrevious}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i> {t("booking.button.differentTime.label")}
            </Button>
          </Grid>
          <Grid item>
            <Button data-testid="t-book-next" type="submit" variant="contained" size="large" disabled={patientHasErrors} onClick={next}>
              {nextButtonLabel} <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </Button>
          </Grid>
        </Grid>
      </form>
    </BookingCard>
  );
}
