import { AnalyticsConstants } from "./AnalyticsConstants";
import { trackEvent } from "../metrics/MetricActions";

export function updateAnalytics(hitType: string, params): void {
  switch (hitType) {
    case AnalyticsConstants.ANALYTICS_EVENT:
      if (!params.value) {
        params.value = 0;
      }
      // TODO: TEST-CASE Make sure this event fires correctly. In Ember it was wrapped with // run.scheduleOnce('afterRender', this, () => {
      trackEvent(params);
      break;
    case "PAGE":
      break;
  }
}
