import React from "react";
import { GenerateCode } from "../common/GenerateCode";
import { BookingCard } from "../common/BookingCard";

export function Step45(): React.JSX.Element {
  return (
    <>
      <BookingCard>
        <GenerateCode currentStepId={"45"}></GenerateCode>
      </BookingCard>
    </>
  );
}
