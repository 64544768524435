import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { Iso5218Sex } from "../../constants/Iso5218Sex";
import { isNil } from "lodash-es";

export function Sex({ error }: { error: string }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sexMapping = [
    { text: t("booking.patientInformation.sex.male.text"), sex: Iso5218Sex.MALE },
    { text: t("booking.patientInformation.sex.female.text"), sex: Iso5218Sex.FEMALE },
    { text: t("booking.patientInformation.sex.not_applicable.text"), sex: Iso5218Sex.NOT_APPLICABLE },
  ];
  const iso5218Sex = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.iso5218Sex);
  const [hasBeenTouched, setBeenTouched] = useState(false);
  const hasError = !isNil(error) && hasBeenTouched;

  function onSexChanged(event) {
    dispatch(updateDocument(DocumentConstants.PATIENT, { iso5218Sex: event.target.value }));
  }

  function onBlur() {
    if (!hasBeenTouched) {
      dispatch(updateDocument(DocumentConstants.PATIENT, { iso5218Sex: iso5218Sex }));
      setBeenTouched(true);
    }
  }

  return (
    <FormControl required sx={{ minWidth: 140 }}>
      <InputLabel>{t("booking.patientInformation.sex.label")}</InputLabel>
      <Select
        name={"sex"}
        value={Iso5218Sex.NOT_KNOWN == iso5218Sex ? "" : iso5218Sex}
        label={t("booking.patientInformation.sex.label")}
        error={hasError}
        onChange={onSexChanged}
        onBlur={onBlur}
        data-testid={"t-patient-sex"}
      >
        {sexMapping.map((entry) => {
          return (
            <MenuItem key={entry.sex} value={entry.sex} data-testid={"t-patient-sex-item-" + entry.sex}>
              {entry.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
