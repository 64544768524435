import { Dispatch } from "react";
import { DispatchType } from "../../dispatcher";
import { isNil, sortBy, uniq } from "lodash-es";
import { updateAnalytics } from "../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../common/analytics/AnalyticsConstants";
import ProposedEvent from "./model/ProposedEvent";
import * as ActionTypes from "./OlbActionTypes";
import { updateDocument } from "../../common/state/CommonActions";
import { DocumentConstants } from "../../common/constants/DocumentConstants";

const translateAndSetSelectedTime = (event: ProposedEvent) => {
  let uniqueSourceIds, filteredResourceEvents, sortedFilteredResourceEvents, startTime, duration, proposedResourceEvent, source;

  return (dispatch: Dispatch<DispatchType>, getState): void => {
    const sources = event.resourceEvents.map((re) => re.source);
    uniqueSourceIds = uniq(sources);
    if (uniqueSourceIds.length === 0) {
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        event: "gtm.pageError",
        errorLineNumber: 85,
        errorUrl: "services/event-selection.js",
        errorMessage: `uniqueSourceIds is empty for eventId: ${JSON.stringify(event)}`,
      });
    }
    uniqueSourceIds.forEach((sourceId) => {
      // Translate to correct format
      filteredResourceEvents = event.resourceEvents.filter((resourceEvent) => resourceEvent.source === sourceId);
      if (filteredResourceEvents.length > 2) {
        throw "Currently the application only supports resourceEvents for 1 or 2 providers";
      }

      sortedFilteredResourceEvents = sortBy(filteredResourceEvents, "startTime");
      // sortedFilteredResourceEvents = filteredResourceEvents.sortBy("startTime");
      duration = sortedFilteredResourceEvents[0].duration;
      if (sortedFilteredResourceEvents.length === 2) {
        duration =
          (sortedFilteredResourceEvents[sortedFilteredResourceEvents.length - 1].startTime.getTime() - sortedFilteredResourceEvents[0].startTime.getTime()) /
            60000 +
          sortedFilteredResourceEvents[sortedFilteredResourceEvents.length - 1].duration;
      }
      startTime = sortedFilteredResourceEvents[0].startTime;

      // Create new proposedResourceEvent
      proposedResourceEvent = {
        startTime: startTime,
        duration: duration,
      };
      sortedFilteredResourceEvents.forEach(function (resourceEvent) {
        if (proposedResourceEvent.resourceEvents) proposedResourceEvent.resourceEvents.push(resourceEvent);
        else proposedResourceEvent.resourceEvents = [resourceEvent];
      });

      // Set proposedResourceEvent on correct appointment source
      const {
        olb: {
          appointmentData: { appointmentDocument },
        },
      } = getState();
      source = appointmentDocument.sources.find((source) => source.sourceId === sourceId);
      if (isNil(source)) {
        updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
          event: "gtm.pageError",
          errorUrl: "services/event-selection.js",
          errorLineNumber: 118,
          errorMessage: `Unable to find source in appointmentService.appointmentDocument.sources for sourceId: ${sourceId}`,
        });
      }
      dispatch(
        updateDocument(DocumentConstants.APPOINTMENT, {
          source: { sourceId: source.sourceId },
          properties: { scheduledEventsProposed: proposedResourceEvent },
        }),
      );
    });
  };
};

export function selectEvent(event: ProposedEvent, skipDocumentUpdate: boolean) {
  return (dispatch: Dispatch<DispatchType>): void => {
    dispatch({ type: ActionTypes.SELECT_EVENT, params: event }); //set(this, 'selectedEvent', event);
    if (!skipDocumentUpdate) {
      dispatch(
        updateDocument(DocumentConstants.APPOINTMENT, {
          duration: event.duration,
          startTime: event.startTime,
        }),
      );
      //Translate and set sources
      dispatch(translateAndSetSelectedTime(event));
    }
  };
}
