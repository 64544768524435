import { combineReducers, ReducersMapObject } from "redux";
import { ApplicationState } from "./common/state/model/ApplicationState";
import Action from "./common/models/Action";
import { olbReducer } from "./onlinebooking/state/OlbReducer";
import { sessionReducer } from "./common/state/SessionReducer";
import { miniSiteReducer } from "./minisite/state/MiniSiteReducer";
import { commonReducer } from "./common/state/CommonReducer";

const rootReducer = combineReducers<ApplicationState>({
  common: commonReducer,
  session: sessionReducer,
  olb: olbReducer,
  miniSite: miniSiteReducer,
} as ReducersMapObject<ApplicationState, Action>);

export { rootReducer };
