import React from "react";
import { NotSynchronized } from "../common/NotSynchronized";
import { BookingCard } from "../common/BookingCard";
import management from "../../../../common/utilities/Management";

export function Step15(): React.JSX.Element {
  return (
    <BookingCard>
      <NotSynchronized showMedicalInformation={management.partnerAdapter.showMedicalInformation()} />
    </BookingCard>
  );
}
