import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./cx1.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import "./i18n";
import { ErrorFallback } from "./common/components/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { updateAnalytics } from "./common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "./common/analytics/AnalyticsConstants";

const handleError = (error, { componentStack }) => {
  updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
    event: "gtm.pageError",
    errorLineNumber: 0,
    errorUrl: "root-" + error?.response?.status + "-" + error?.response?.config?.url,
    errorMessage: componentStack,
  });
};

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <HashRouter>
        <App />
      </HashRouter>
    </ErrorBoundary>
  </Provider>,
  document.querySelector("#root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
