import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";

export function AppointmentCardStateSuccess(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Alert severity="success" data-testid="t-book-state-success">
      <AlertTitle>{t("booking.scheduled.confirmed.text")}</AlertTitle>
      {t("booking.scheduled.seeingYou.text")}
    </Alert>
  );
}
