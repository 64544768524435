import React from "react";
import { SelectATimeForRecallTreatmentPlan } from "../common/SelectATimeForRecallTreatmentPlan";
import { BookingCard } from "../common/BookingCard";
import management from "../../../../common/utilities/Management";

export function Step12(): React.JSX.Element {
  return (
    <BookingCard>
      <SelectATimeForRecallTreatmentPlan showMedicalInformation={management.partnerAdapter.showMedicalInformation()} />
    </BookingCard>
  );
}
