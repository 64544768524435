import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/hooks/useAppDispatch";
import { updateDocument } from "../../../../common/state/CommonActions";
import { DocumentConstants } from "../../../../common/constants/DocumentConstants";

const patientTypes = ["Private", "PRSI", "Health Insurance"];
const insuranceProviders = ["Aviva", "VHI", "Decare", "Laya", "Other Provider"];

export function Gms({ source, isMedicalCardInsuranceType }: { source: any; isMedicalCardInsuranceType: boolean }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isHealthInsuranceSelected = source.patientType === "Health Insurance";
  const isOtherHealthInsuranceSelected = source.insuranceProvider === "Other Provider";

  function onPatientTypeChanged(event) {
    dispatch(
      updateDocument(DocumentConstants.APPOINTMENT, {
        source: { sourceId: source.sourceId },
        properties: {
          patientType: event.target.value,
          insuranceNumber: null,
          insuranceProvider: null,
          insuranceProviderOther: null,
        },
      }),
    );
  }

  function onInsuranceProviderChanged(event) {
    dispatch(
      updateDocument(DocumentConstants.APPOINTMENT, {
        source: { sourceId: source.sourceId },
        properties: { insuranceProvider: event.target.value, insuranceProviderOther: null, insuranceNumber: null },
      }),
    );
  }

  function onInsuranceProviderOtherChanged(event) {
    dispatch(
      updateDocument(DocumentConstants.APPOINTMENT, {
        source: { sourceId: source.sourceId },
        properties: { insuranceProviderOther: event.target.value },
      }),
    );
  }

  return (
    <>
      {!isMedicalCardInsuranceType && (
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel>{t("booking.patientInformation.patientType.label")}</InputLabel>
          <Select
            name={t("booking.patientInformation.patientType.label")}
            value={source.patientType || ""}
            label={t("booking.patientInformation.patientType.label")}
            onChange={onPatientTypeChanged}
            data-testid={"t-patient-gms-patienttype"}
          >
            {patientTypes.map((type) => {
              return (
                <MenuItem key={type} value={type} data-testid={"t-patient-gms-patienttype-item-" + type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {isHealthInsuranceSelected && (
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel>{t("booking.patientInformation.insuranceProvider.label")}</InputLabel>
          <Select
            name={t("booking.patientInformation.insuranceProvider.label")}
            value={source.insuranceProvider || ""}
            label={t("booking.patientInformation.insuranceProvider.label")}
            onChange={onInsuranceProviderChanged}
            data-testid={"t-patient-gms-insuranceprovider"}
          >
            {insuranceProviders.map((provider) => {
              return (
                <MenuItem key={provider} value={provider} data-testid={"t-patient-gms-insuranceprovider-item-" + provider}>
                  {provider}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {isOtherHealthInsuranceSelected && (
        <TextField
          value={source.insuranceProviderOther || ""}
          inputProps={{ maxLength: 75 }}
          onChange={onInsuranceProviderOtherChanged}
          label={t("booking.patientInformation.insuranceProvider.label")}
          data-testid={"t-patient-gms-other"}
        />
      )}
    </>
  );
}
