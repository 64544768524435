import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useAppDispatch } from "../../common/hooks/useAppDispatch";
import { CommonActionTypes } from "../../common/state/CommonActionTypes";
import { DocumentConstants } from "../../common/constants/DocumentConstants";

function MedicalInputs({ sourceId, question1Value, question2Value }: { sourceId?: string; question1Value?: string; question2Value?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  function onQuestion1Changed(event) {
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: DocumentConstants.APPOINTMENT,
      params: { source: { sourceId: sourceId }, properties: { question1: event.target.value } },
    });
  }

  function onQuestion2Changed(event) {
    dispatch({
      type: CommonActionTypes.UPDATE_DOCUMENT,
      documentType: DocumentConstants.APPOINTMENT,
      params: { source: { sourceId: sourceId }, properties: { question2: event.target.value } },
    });
  }

  return (
    <>
      <Grid item>
        <TextField
          multiline
          rows={3}
          sx={{ minWidth: 325 }}
          inputProps={{ maxLength: 500 }}
          label={t("booking.recall.medical.changes.label")}
          value={question1Value || ""}
          onChange={onQuestion1Changed}
        />
      </Grid>
      <Grid item>
        <TextField
          multiline
          rows={3}
          sx={{ minWidth: 325 }}
          inputProps={{ maxLength: 500 }}
          label={t("booking.recall.medical.additional.label")}
          value={question2Value || ""}
          onChange={onQuestion2Changed}
        />
      </Grid>
    </>
  );
}

export function MedicalInformation({ source, readonly }: { source: any; readonly: boolean }): React.JSX.Element {
  const { t } = useTranslation();
  const question1 = source?.question1;
  const question2 = source?.question2;

  const [hasMedicalInformation, setHasMedicalInformation] = useState(!isEmpty(question1) || !isEmpty(question2));

  function onMedicalInformationChanged(e, value) {
    if (value != null) {
      setHasMedicalInformation(value);
    }
  }

  return (
    <>
      <Grid container spacing={2} flexDirection={"row"} alignItems={"center"}>
        <Grid item>{t("booking.recall.medical.changeOption.label")}&nbsp;</Grid>
        <Grid item>
          {readonly ? (
            <>{hasMedicalInformation ? <> {t("booking.recall.medical.changeOption.yes")}</> : <> {t("booking.recall.medical.changeOption.no")}</>}</>
          ) : (
            <ToggleButtonGroup size="small" value={hasMedicalInformation} exclusive onChange={onMedicalInformationChanged}>
              <ToggleButton value={true}>{t("booking.recall.medical.changeOption.yes")}</ToggleButton>
              <ToggleButton value={false}>{t("booking.recall.medical.changeOption.no")}</ToggleButton>
            </ToggleButtonGroup>
          )}
        </Grid>
      </Grid>
      {hasMedicalInformation && (
        <Grid container spacing={2} flexDirection={"row"} sx={{ mt: 1 }}>
          {readonly ? (
            <>
              <Grid item xs={12} sx={{ fontWeight: "bold" }}>
                <label>{t("booking.recall.medical.changes.label")}</label>
              </Grid>
              <Grid item className="highlight" xs={12} sx={{ ml: 2, mr: 4 }}>
                {question1}
              </Grid>
              <Grid item xs={12} sx={{ fontWeight: "bold" }}>
                <label>{t("booking.recall.medical.additional.label")}</label>
              </Grid>
              <Grid item className="highlight" xs={12} sx={{ ml: 2, mr: 4 }}>
                {question2}
              </Grid>
            </>
          ) : (
            <MedicalInputs sourceId={source.sourceId} question1Value={question1} question2Value={question2}></MedicalInputs>
          )}
        </Grid>
      )}
    </>
  );
}
