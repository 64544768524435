import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhone } from "../../../common/utilities/GeneralHelpers";
import { Grid } from "@mui/material";

export function NoAvailableTimeHenrySchein({
  practiceName,
  practicePhone,
  practicePhoneLink,
  practiceEmail,
  practiceEmailLink,
}: {
  practiceName: string;
  practicePhone: string;
  practicePhoneLink: string;
  practiceEmail: string;
  practiceEmailLink: string;
}): React.JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid container item xs={12}>
      <Grid item data-testid={"t-book-no-available-time"} className="highlight" style={{ margin: 20 }} xs={12} textAlign={"center"}>
        {t("booking.noAvailableTimes.text")}&nbsp;<b>{practiceName}</b> <br />
        {practicePhone && <a href={practicePhoneLink}>{formatPhone(practicePhone)}</a>}
        {practiceEmail && (
          <>
            <>&nbsp;</>|<>&nbsp;</>
            <a href={practiceEmailLink}>{practiceEmail}</a>
          </>
        )}
      </Grid>
    </Grid>
  );
}
