import { Dispatch } from "react";
import { DispatchType } from "../../dispatcher";
import axios from "axios";
import { organizationId, updateDocument } from "../../common/state/CommonActions";
import * as OlbActionTypes from "./OlbActionTypes";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { DocumentConstants } from "../../common/constants/DocumentConstants";
import { loadEvents, setIsLoadingEvents, unloadEvents, updateEventRequest, updateStartTime } from "./OlbActions";
import isCMSActive from "../utilities/CmsChecker";
import { saveDocument } from "../../common/state/DocumentSaving";
import { isEmpty, isEqual, isNil, isString } from "lodash-es";
import EventRequest from "./model/EventRequest";
import { HCPORTAL_V1_PRACTICES_API_ADDRESS } from "../../common/constants/PathConstants";

export const fetchProductConfiguration = () => {
  return (dispatch, getState) => {
    const eventRequest: EventRequest = getState().olb.eventRequest;
    const appointmentDocument = getState().olb.appointmentData?.appointmentDocument;
    // JS: Commented out for now, dynamic product configuration rules are not used currently.  This will need to be un-commented if we enable the feature.
    const filterParams = {
      // payorType: eventRequest?.payorType,
      // patientType: eventRequest?.patientType,
      // reasonId: eventRequest?.reasonId,
      // providerId: eventRequest?.providerId,
      promoCode: eventRequest?.promoCode || appointmentDocument?.promoCode,
    };
    const timestamp = new Date().getTime();
    const params = {
      filter: JSON.stringify(filterParams),
      timestamp,
    };
    const path = `${HCPORTAL_V1_PRACTICES_API_ADDRESS}/${organizationId}/ruleset/productConfigurations`;
    return axios.get(path, { params }).then(({ data: json }) => {
      dispatch(receiveProductConfiguration(json));
      return json;
    });
  };
};

const receiveProductConfiguration = (json: any) => {
  return (dispatch: Dispatch<DispatchType>): void => {
    return dispatch({
      type: OlbActionTypes.RECEIVE_PRODUCT_CONFIGURATION,
      state: json || {},
    });
  };
};

export const updateAppointmentDocumentWithProductConfiguration = () => {
  return (dispatch, getState) => {
    const appState: ApplicationState = getState();
    const patientType =
      appState.olb?.appointmentData?.appointmentDocument?.appointmentType || appState.olb?.productConfiguration?.options?.fields?.patientType?.default;
    const payorType =
      appState.olb?.appointmentData?.appointmentDocument?.insuranceType || appState.olb?.productConfiguration?.options?.fields?.payorType?.default;
    const reasonId =
      appState.olb?.appointmentData?.appointmentDocument?.reason || appState.olb?.productConfiguration?.options?.fields?.reasonExternalId?.default;
    return dispatch(
      updateDocument(DocumentConstants.APPOINTMENT, {
        reason: reasonId,
        insuranceType: payorType,
        appointmentType: patientType,
      }),
    );
  };
};

export const handleWhenEventsIsEmpty = () => {
  return (dispatch, getState) => {
    const events = getState().olb.eventData.events;
    if (isEmpty(events)) {
      let nhsDocumentId = getState().common.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.nhsFee;
      nhsDocumentId = isString(nhsDocumentId) ? Number(nhsDocumentId) : nhsDocumentId;
      if (!isNil(nhsDocumentId)) {
        const nhsFeeDocument = getState().olb.nhsFeeData?.nhsFeeDocuments?.find((nhsFeeDocument) => nhsDocumentId === nhsFeeDocument.id);
        const nhsFeeMarkdown = nhsFeeDocument?.value?.value;

        if (!isNil(nhsFeeMarkdown)) {
          dispatch(updateEventRequest({ payorType: "NHS" }));
          dispatch(updateDocument(DocumentConstants.APPOINTMENT, { insuranceType: "NHS" }));
          return dispatch(loadEventsForShapeChangeNewExisting());
        }
      }
    }
  };
};

export const handleWhenCMSIsActive = () => {
  return (dispatch) => {
    if (isCMSActive()) {
      dispatch(
        updateDocument(DocumentConstants.PATIENT, {
          codeAlreadyVerified: true,
          dateofbirth: new Date(Date.UTC(2017, 0, 1, 0, 0, 0)),
        }),
      );
      return dispatch(saveDocument(DocumentConstants.PATIENT));
    }
  };
};

export const loadEventsForShapeChangeNewExisting = () => {
  return async (dispatch, getState: () => ApplicationState) => {
    const appointmentDocument = getState().olb?.appointmentData?.appointmentDocument;
    const eventRequest = getState().olb?.eventRequest;

    dispatch(setIsLoadingEvents(true));
    const appointmentStartTime = appointmentDocument?.startTime;
    const startTime = isNil(appointmentStartTime) ? new Date() : appointmentStartTime;
    dispatch(updateStartTime(startTime));
    dispatch(unloadEvents());

    const params = {
      patientId: eventRequest?.patientId,
      payorType: eventRequest?.payorType || undefined,
      patientType: eventRequest?.patientType,
      reasonId: eventRequest?.reasonId,
      practiceId: eventRequest?.practiceId,
      firstEventForProvider: true,
      promoCode: eventRequest?.promoCode,
      isShapeChange: true,
    };
    await dispatch(loadEvents(params)).catch(() => {
      dispatch(unloadEvents());
      dispatch(setIsLoadingEvents(true));
      //TODO: JS send the error to a logging service);
    });
    return await dispatch(handleWhenEventsIsNotEmpty());
  };
};

function handleWhenEventsIsNotEmpty() {
  return async (dispatch, getState) => {
    const events = getState().olb.eventData.events;
    const eventRequest = getState().olb.eventRequest;

    if (!isEmpty(events)) {
      // Set startTime for first event in the list or the first event for the selected provider
      const providerId = eventRequest.providerId || undefined;
      const event = _firstEventForProvider(events, providerId);
      dispatch(updateStartTime(event.startTime));

      const paramsAll = {
        patientId: eventRequest.patientId,
        payorType: eventRequest.payorType || undefined,
        patientType: eventRequest.patientType,
        reasonId: eventRequest.reasonId,
        practiceId: eventRequest.practiceId,
        providerId: providerId,
        firstEventForProvider: false,
        promoCode: eventRequest.promoCode,
      };

      await dispatch(loadEvents(paramsAll)).catch(() => {
        dispatch(unloadEvents());
        dispatch(setIsLoadingEvents(false));
        //TODO: JS send the error to a logging service);
      });
      return dispatch(setIsLoadingEvents(false));
    } else {
      return dispatch(setIsLoadingEvents(false));
    }
  };
}

export function loadEventsForNextRangeNewExisting() {
  return async (dispatch, getState: () => ApplicationState) => {
    const eventRequest = getState().olb?.eventRequest;
    if (!isNil(eventRequest)) {
      dispatch(setIsLoadingEvents(true));

      const params = {
        patientId: eventRequest.patientId,
        payorType: eventRequest.payorType || undefined,
        patientType: eventRequest.patientType,
        reasonId: eventRequest.reasonId,
        practiceId: eventRequest.practiceId,
        providerId: eventRequest.providerId || undefined,
        firstEventForProvider: false,
        promoCode: eventRequest.promoCode,
      };
      await dispatch(loadEvents(params)).catch(() => {
        dispatch(unloadEvents());
        dispatch(setIsLoadingEvents(false));
        //TODO: JS send the error to a logging service);
      });
      dispatch(setIsLoadingEvents(false));
    } else {
      // TODO: JS send the error to a logging service
    }
  };
}

export function shapeChanged() {
  return (dispatch) => {
    dispatch({ type: OlbActionTypes.REMOVE_EVENT });
    dispatch(loadEventsForShapeChangeNewExisting());
  };
}

export function updateFullNameOnAppointment(sourceId: string) {
  return (dispatch, getState) => {
    if (!isNil(sourceId)) {
      const firstName = getState().olb.patientInfoData.patientDocument.firstname;
      const lastName = getState().olb.patientInfoData.patientDocument.lastname;

      let fullName = !isEmpty(firstName) ? firstName : "";
      fullName += !isEmpty(lastName) ? " " + lastName : "";

      dispatch(
        updateDocument(DocumentConstants.APPOINTMENT, {
          source: { sourceId: sourceId },
          properties: { sourceName: fullName },
        }),
      );
    }
  };
}

const _firstEventForProvider = function (events, providerId) {
  let event = events?.[0];
  if (!isNil(providerId)) {
    event = events.find((event) => {
      return isEqual(providerId, event.resourceEvents?.[0].resourceName);
    });
  }
  return event;
};
