import { Button, Checkbox, Divider, FormControlLabel, Grid, Link, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cancelVerification, verifyCode } from "../../state/SoAdminActions";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { useErrorHandler } from "react-error-boundary";

export function VerifyCode({ verificationChannel, onIsSaving, isSaving }: { verificationChannel: any; onIsSaving: any; isSaving: boolean }): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("");
  const [mfaDontAsk, setMfaDontAsk] = useState(false);

  const handleMfaDontAskChanged = (event) => {
    setMfaDontAsk(event.target.checked);
  };

  const handleVerificationCodeChanged = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleResendCode = async () => {
    onIsSaving(true);
    await dispatch(cancelVerification(true)).catch((error) => {
      handleError(error);
    });
    onIsSaving(false);
  };

  const handleVerificationCancel = async () => {
    onIsSaving(true);
    await dispatch(cancelVerification()).catch((error) => {
      handleError(error);
    });
    onIsSaving(false);
  };

  const handleVerifyCode = async () => {
    onIsSaving(true);
    await dispatch(verifyCode(verificationCode, mfaDontAsk)).catch((error) => {
      handleError(error);
    });
    onIsSaving(false);
  };

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item>
          {"VERIFY_CODE" === verificationChannel.verifyChannelState && (
            <>
              {t("mfa.enterCode")} {verificationChannel.maskedEmail ? verificationChannel.maskedEmail : verificationChannel.maskedSms}{" "}
              {t("mfa.enterCodeSuffix")}
            </>
          )}
          {"VERIFIED" === verificationChannel.verifyChannelState && <>{t("mfa.identityVerified")}</>}
        </Grid>
      </Grid>
      <Grid container direction={"column"} spacing={0} padding={2}>
        <Grid item>
          <TextField
            label={t("signin.2fa.code.label")}
            value={verificationCode}
            error={verificationChannel.invalidCode}
            onChange={handleVerificationCodeChanged}
            helperText={verificationChannel.invalidCode ? t("mfa.invalidCode") : ""}
            data-testid={"t-mfa-enter-code"}
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item>
          <Link href={"#"} onClick={handleResendCode} data-testid={"t-mfa-link-resend"}>
            {t("mfa.resend")}
          </Link>
        </Grid>
      </Grid>
      <Grid container paddingLeft={2} paddingRight={2} paddingBottom={2}>
        <Grid item>
          <FormControlLabel
            control={<Checkbox onChange={handleMfaDontAskChanged} checked={mfaDontAsk} />}
            label={t("mfa.dont.ask.label")}
            data-testid={"t-mfa-dont-ask"}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container padding={2} spacing={2} justifyContent={"space-between"}>
        <Grid item>
          <Button variant={"outlined"} onClick={handleVerificationCancel} disabled={isSaving} data-testid={"t-mfa-button-cancel"}>
            {t("mfa.cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button variant={"contained"} onClick={handleVerifyCode} disabled={isSaving} data-testid={"t-mfa-verify"}>
            {t("mfa.ok")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
