import ProposedEvent from "../../state/model/ProposedEvent";
import { Step12 } from "./recall/Step12";
import { Step13 } from "./recall/Step13";
import { Step14 } from "./recall/Step14";
import { Step15 } from "./recall/Step15";
import { Step34 } from "./treatmentplan/Step34";
import { Step35 } from "./treatmentplan/Step35";
import { Step36 } from "./treatmentplan/Step36";
import { Step37 } from "./treatmentplan/Step37";
import { Step39 } from "./newexisting/Step39";
import { Step40 } from "./newexisting/Step40";
import { Step41 } from "./newexisting/Step41";
import { Step42 } from "./newexisting/Step42";
import { Step43 } from "./newexisting/Step43";
import { Step44 } from "./newexisting/Step44";
import { Step45 } from "./newexisting/Step45";
import { Step46 } from "./newexisting/Step46";

export const StepCardInstances = {
  components: {
    "12": Step12,
    "13": Step13,
    "14": Step14,
    "15": Step15,
    "34": Step34,
    "35": Step35,
    "36": Step36,
    "37": Step37,
    "39": Step39,
    "40": Step40,
    "41": Step41,
    "42": Step42,
    "43": Step43,
    "44": Step44,
    "45": Step45,
    "46": Step46,
  },
};

export type PreviousRangeFunction = () => void;
export type NextRangeFunction = () => void;
export type SelectEventFunction = (time: ProposedEvent, skipDocumentUpdate: boolean) => void;
