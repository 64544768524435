import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../common/state/model/ApplicationState";
import ConfiguratorFieldOptions from "../../../../state/model/ConfiguratorFieldIOptions";
import { useTranslation } from "react-i18next";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAppDispatch } from "../../../../../common/hooks/useAppDispatch";
import { updateEventRequest } from "../../../../state/OlbActions";
import { isNil } from "lodash-es";
import { updateDocument } from "../../../../../common/state/CommonActions";
import { updateAnalytics } from "../../../../../common/analytics/AnalyticsActions";
import { AnalyticsConstants } from "../../../../../common/analytics/AnalyticsConstants";
import { shapeChanged } from "../../../../state/OlbActionsForNewExisting";
import EventRequest from "../../../../state/model/EventRequest";
import { DocumentConstants } from "../../../../../common/constants/DocumentConstants";

export function ReasonSelector({ fieldOptions }: { fieldOptions: ConfiguratorFieldOptions }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { readOnly, defaultValue, idsToFilter, idsToFilterIsEmpty, isHidden } = fieldOptions || {};
  const eventRequest = useSelector((state: ApplicationState): EventRequest | undefined => state.olb?.eventRequest);
  const selectedReasonId = eventRequest?.reasonId;
  const reasons = useSelector((state: ApplicationState) => state.olb?.reasons);
  const selectedReason = reasons?.find((v) => v.externalId === selectedReasonId) || reasons?.find((v) => v.externalId === defaultValue);
  const isLoadingEvents = useSelector((state: ApplicationState) => state.olb?.eventData.isLoadingEvents);

  const filteredReasons = idsToFilterIsEmpty
    ? reasons
    : reasons?.filter((r) => idsToFilter.includes(r.externalId)).sort((a, b) => (a.displayOrder && b.displayOrder ? a.displayOrder - b.displayOrder : 0));

  function onReasonChanged(event: SelectChangeEvent) {
    const reason = reasons?.find((reason) => event.target.value === reason.externalId);
    if (!isNil(reason)) {
      dispatch(updateEventRequest({ reasonId: reason.externalId, providerId: null }));
      dispatch(updateDocument(DocumentConstants.APPOINTMENT, { reason: reason.externalId }));
      updateAnalytics(AnalyticsConstants.ANALYTICS_EVENT, {
        category: "EventRequest",
        action: "reason",
        label: reason.externalId,
      });
      dispatch(shapeChanged());
    } else {
      // TODO: JS log error
    }
  }

  return (
    <>
      {!isHidden && (
        <Grid container item alignItems={"center"} xs={12} spacing={2}>
          <Grid item xs={12} sm={12} md={2} className={"event-shape-option-label"}>
            {t("booking.reason.label")}
          </Grid>
          <Grid item xs={12} sm={10} sx={{ pr: 5 }}>
            {readOnly ? (
              <p>{selectedReason?.displayText}</p>
            ) : (
              <FormControl sx={{ minWidth: 250, width: "100%" }} disabled={isLoadingEvents}>
                <InputLabel>{t("booking.reason.empty.label")}</InputLabel>
                <Select value={selectedReasonId || ""} label={t("booking.reason.empty.label")} onChange={onReasonChanged} data-testid={"t-reason-selector"}>
                  {filteredReasons?.map((r) => {
                    return (
                      <MenuItem value={r.externalId} key={r.externalId} data-testid={"t-reason-selector-item-" + r.externalId}>
                        {r.displayText}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
