import { Box } from "@mui/material";
import { AddToCalendar } from "../../AddToCalendar";
import React, { useMemo } from "react";
import isCMSActive from "../../../utilities/CmsChecker";
import { useTranslation } from "react-i18next";
import { formatPhone } from "../../../../common/utilities/GeneralHelpers";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../common/state/model/ApplicationState";
import { getPrimaryProvider, getSecondaryProvider } from "../../../state/OlbSelectors";
import { swapAddressLines } from "../../../../common/utilities/PracticeInfoAware";
import { AppointmentType } from "../../../state/OlbConstants";
import { PayorTypes } from "../../../constants/OnlineBookingConstants";

export const AddToCalendarHenrySchein = ({
  showAddToCalendar,
  startTime,
  source,
  appointmentNotConfirmed,
  patientName,
}: {
  showAddToCalendar: boolean;
  startTime: Date;
  source: any;
  appointmentNotConfirmed: boolean;
  patientName: string;
}) => {
  const { t } = useTranslation();
  const duration = source.scheduledEventsProposed?.duration;
  const combinedAppointment = source.scheduledEventsProposed?.resourceEvents?.length > 1;
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const practiceInfo = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument);
  const primaryProvider = useSelector((state: ApplicationState) => getPrimaryProvider(state, source));
  const secondaryProvider = useSelector((state: ApplicationState) => getSecondaryProvider(state, source));
  const patientType: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.appointmentType);
  const selectedReasonId: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.reason);
  const payorType: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.insuranceType);
  const reasons = useSelector((state: ApplicationState) => state.olb?.reasons);
  const selectedReason = reasons?.find((v) => v.externalId === selectedReasonId);

  let patientTypeText = "";
  switch (patientType) {
    case AppointmentType.NEW_PATIENT:
      patientTypeText = t("booking.newPatient.title");
      break;
    case AppointmentType.PLANNED:
    case AppointmentType.RECALL:
    case AppointmentType.EXISTING_PATIENT:
      patientTypeText = t("booking.existingPatient.title");
      break;
  }
  let payorTypeText;
  switch (payorType) {
    case PayorTypes.MEDICAL_CARD_PAYOR:
      payorTypeText = t("booking.summary.payortype.medicalcard.text");
      break;
    case PayorTypes.NHS_PAYOR:
    case PayorTypes.PRIVATE_PAYOR:
      payorTypeText = payorType;
      break;
  }

  const addToCalendarTitle: string = useMemo(() => {
    let title = "";
    if (appointmentNotConfirmed && !isCMSActive()) {
      title = t("booking.summary.review.addToCalendar.title.notConfirmed.text");
    }
    if (isCMSActive()) {
      title += `${t("booking.summary.review.addToCalendar.sales.title.text")} ${patientName}`;
    } else {
      title += `${t("booking.summary.review.addToCalendar.title.text")} ${patientName}`;
    }

    return title;
  }, [appointmentNotConfirmed, patientName, t]);

  const addToCalendarDescription: string = useMemo(() => {
    let description;
    if (appointmentNotConfirmed && !isCMSActive()) {
      description = `${t("booking.summary.review.addToCalendar.description.notConfirmed.text")} ${formatPhone(practiceInfo.phone)}.  `;
    } else if (isCMSActive()) {
      description = `${t("booking.summary.review.addToCalendar.description.sales.text")} ${formatPhone(practiceInfo.phone)}.  `;
    } else {
      description = `${practiceInfo.name} ${formatPhone(practiceInfo.phone)}.  `;
    }

    description += `${patientTypeText} ${t(`booking.summary.patienttype.text.${uiComponentRoot}`)} - ${payorTypeText ? payorTypeText + " - " : ""}${
      selectedReason?.displayText || ""
    } ${
      combinedAppointment
        ? t("booking.summary.review.combined.appointment.with.text") + ": " + primaryProvider?.name + ", " + secondaryProvider.name
        : t("booking.summary.review.appointment.with.text") + ": " + primaryProvider?.name
    }`;

    return description;
  }, [
    selectedReason?.displayText,
    payorTypeText,
    patientTypeText,
    appointmentNotConfirmed,
    practiceInfo,
    primaryProvider?.name,
    secondaryProvider?.name,
    combinedAppointment,
    t,
  ]);

  const addToCalendarLocation: string = useMemo(() => {
    let address = "";
    if (swapAddressLines()) {
      if (practiceInfo.address1) {
        address += `${practiceInfo.address1} `;
      }
      if (practiceInfo.postcode) {
        address += `${practiceInfo.postcode} `;
      }
      if (practiceInfo.address2) {
        address += `${practiceInfo.address2} `;
      }
    } else {
      if (practiceInfo.address1) {
        address += `${practiceInfo.address1} `;
      }
      if (practiceInfo.address2) {
        address += `${practiceInfo.address2} `;
      }
      if (practiceInfo.address3) {
        address += `${practiceInfo.address3} `;
      }
      if (practiceInfo.postcode) {
        address += `${practiceInfo.postcode} `;
      }
    }
    return address;
  }, [practiceInfo]);

  return (
    <>
      {showAddToCalendar && (
        <Box sx={{ m: 2 }}>
          <AddToCalendar
            startTime={startTime}
            duration={duration}
            title={addToCalendarTitle}
            description={addToCalendarDescription}
            location={addToCalendarLocation}
          />
        </Box>
      )}
    </>
  );
};
