import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useAppDispatch } from "../common/hooks/useAppDispatch";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { fetchReasons } from "./state/OlbActions";
import { reload } from "../common/state/CommonActions";
import { showInIframe } from "./state/OlbSelectors";
import { isCallCenterActive } from "../common/utilities/CallCenter";
import { ApplicationState } from "../common/state/model/ApplicationState";
import { isFacebookApp } from "../common/utilities/Browser";
import { PracticeInformationSummary } from "./components/PracticeInformationSummary";
import { CallCenterPage } from "./components/CallCenterPage";
import { StepCardInstances } from "./components/step/StepTypes";
import { useTranslation } from "react-i18next";
import { isNil,isEmpty } from "lodash-es";
import { useNavigate } from "react-router-dom";

const OnlineBooking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const stepCardId = useSelector((state: ApplicationState) => state.olb?.stepData?.stepCard?.identifier);
  const showPracticeInfoSummary = useSelector(showInIframe);
  const termsOfUseDocument = useSelector((state: ApplicationState) => state.olb?.termsOfUseData?.termsOfUseDocument);
  const practiceDocument = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceDocument);
  const pageDocument = useSelector((state: ApplicationState) => state.olb?.pageData?.pageDocument);
  const logoUrl = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.logoUrl);
  const callCenterActive = isCallCenterActive();
  const showMap = !isFacebookApp();
  const stepCardGridItemLargeValue = useMemo(() => (callCenterActive ? 7 : 9), [callCenterActive]);
  const practiceInformationSummaryGridItemLargeValue = useMemo(() => (callCenterActive ? 2 : 3), [callCenterActive]);

  const StepCard = useMemo(() => {
    let result;
    if (stepCardId) {
      result = StepCardInstances.components[stepCardId];
    }
    return result;
  }, [stepCardId]);

  useEffect(() => {
    dispatch(fetchReasons());
    dispatch(reload("3", true));
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(practiceDocument) && isNil(stepCardId)) {
      let website = practiceDocument.website;
      if (isEmpty(website)) {
        navigate("/perspectives/1");
      } else {
        if (website.indexOf("http") !== 0) {
          // noinspection HttpUrlsUsage
          website = `http://${website}`;
        }
        window.location = website;
      }
    }
  }, [practiceDocument, stepCardId, navigate]);

  return (
    <>
      {!stepCardId && (
        <>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
            <CircularProgress size={100} />
          </div>
          <Typography variant="h5" align={"center"} style={{ marginTop: 20 }}>
            {t("perspective.loading.booking.text")}
          </Typography>
        </>
      )}
      {stepCardId && (
        <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ padding: 1 }}>
          <Grid item xs={12} md={12} lg={stepCardGridItemLargeValue}>
            <StepCard />
          </Grid>
          {showPracticeInfoSummary && (
            <Grid item xs={12} md={12} lg={practiceInformationSummaryGridItemLargeValue}>
              <PracticeInformationSummary termsOfUseDocument={termsOfUseDocument} practiceDocument={practiceDocument} logoUrl={logoUrl} showMap={showMap} />
            </Grid>
          )}
          {callCenterActive && pageDocument?.content && (
            <Grid item xs={12} md={12} lg={3}>
              <CallCenterPage pageDocument={pageDocument} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default connect()(OnlineBooking);
