import React from "react";
import { Grid } from "@mui/material";
import { AppointmentCard } from "./AppointmentCard";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";

export function AppointmentCards({
  showAddToCalendar = false,
  showTermsOfUse = false,
  showMarketingConsent = false,
  appointmentNotConfirmed = false,
  readonly = false,
  showPatientInformation = false,
  showPatientValidation = false,
  showMedicalInformation = false,
  currentStepId = "",
  validationMode = -1,
}: {
  showAddToCalendar?: boolean;
  showTermsOfUse?: boolean;
  showMarketingConsent?: boolean;
  appointmentNotConfirmed?: boolean;
  readonly?: boolean;
  showPatientInformation?: boolean;
  showPatientValidation?: boolean;
  showMedicalInformation?: boolean;
  currentStepId?: string;
  validationMode?: number;
}): React.JSX.Element {
  const sources = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.sources);
  const isOnlyOneSource: boolean = sources?.length == 1;

  return (
    <Grid container item>
      {isOnlyOneSource ? (
        <Grid item xs={12}>
          <AppointmentCard
            showAddToCalendar={showAddToCalendar}
            showPatientInformation={showPatientInformation}
            appointmentNotConfirmed={appointmentNotConfirmed}
            showTermsOfUse={showTermsOfUse}
            showMarketingConsent={showMarketingConsent}
            readonly={readonly}
            validationMode={validationMode}
            currentStepId={currentStepId}
            showPatientValidation={showPatientValidation}
            showMedicalInformation={showMedicalInformation}
            source={sources[0]}
          />
        </Grid>
      ) : (
        sources.map((source) => (
          <Grid key={source.sourceId} item xs={12} md={6}>
            <AppointmentCard
              showAddToCalendar={showAddToCalendar}
              appointmentNotConfirmed={appointmentNotConfirmed}
              showTermsOfUse={showTermsOfUse}
              showMarketingConsent={showMarketingConsent}
              readonly={readonly}
              validationMode={validationMode}
              showPatientInformation={showPatientInformation}
              showMedicalInformation={showMedicalInformation}
              source={source}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
}
