import React, { useMemo } from "react";
import { isEmpty } from "lodash-es";
import { Box } from "@mui/material";

export function WidgetLink({
  name,
  accountingCode,
  newPatientBase,
}: {
  name: string;
  accountingCode: string;
  newPatientBase?: string | undefined;
}): React.JSX.Element {
  const link = useMemo(() => {
    if (isEmpty(name) || isEmpty(accountingCode)) {
      return "";
    }
    const urlEncodedName = encodeURIComponent(name);
    return `${window.location.origin}/${newPatientBase}${urlEncodedName}?pid=${accountingCode}`;
  }, [name, accountingCode, newPatientBase]);

  return (
    <Box component={"span"} sx={{ overflowWrap: "anywhere", color: "gray" }}>
      {link}
    </Box>
  );
}
