import { isNil } from "lodash-es";
import { endOfDay, startOfDay } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export default class WeekDay {
  date: Date;
  description: string;
  times: any[];
  timezone: string;

  constructor(date: Date, description: string, times: any[], timezone: string) {
    this.date = date;
    this.description = description;
    this.times = times;
    this.timezone = timezone;
  }

  startRange(): Date | undefined {
    if (isNil(this.date) || isNil(this.timezone)) {
      return;
    }
    const zonedStartOfDay = startOfDay(utcToZonedTime(this.date, this.timezone));
    return zonedTimeToUtc(zonedStartOfDay, this.timezone);
  }

  endRange(): Date | undefined {
    if (isNil(this.date) || isNil(this.timezone)) {
      return;
    }
    const zonedEndOfDay = endOfDay(utcToZonedTime(this.date, this.timezone));
    return zonedTimeToUtc(zonedEndOfDay, this.timezone);
  }
}
