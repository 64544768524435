import { ApplicationState } from "../../common/state/model/ApplicationState";
import { isEmpty, isNil, isString } from "lodash-es";
import { isSameDay, parseISO } from "date-fns";
import { notEmbeddedInAnIFrame } from "../../common/utilities/Iframe";
import ProposedEvent from "./model/ProposedEvent";
import { UTCDate } from "@date-fns/utc";

export function getUseDateFromLink(state: ApplicationState): boolean {
  return state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.useDateFromLink;
}

export function getProposedEvents(state: ApplicationState): ProposedEvent[] | undefined {
  return state.olb?.eventData.events;
}

export function showInIframe(state: ApplicationState) {
  return !notEmbeddedInAnIFrame() ? state.common?.config?.showPracticeSummaryInIframe : true;
}

export function getIsOnlineBookingUnavailableUntilFurtherNotice(state: ApplicationState): boolean {
  const openDate = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate;
  const furtherNoticeDate = new Date(Date.UTC(2050, 0, 1));
  let openDateParsed = openDate;
  if (isString(openDate)) {
    openDateParsed = new Date(new UTCDate(openDate).getDate());
  }
  return !isNil(openDate) && isSameDay(openDateParsed, furtherNoticeDate);
}

export function getIsOnlineBookingUnavailableUntil(state: ApplicationState) {
  const openDate = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate;
  return !isNil(openDate) && !getIsOnlineBookingUnavailableUntilFurtherNotice(state);
}

export function determineIsTermsOfUseAccepted(state: ApplicationState) {
  let result = true;
  if (!isNil(state.olb?.termsOfUseData?.termsOfUseDocument)) {
    const patientDocuments = state.olb?.patientInfoData?.patientDocuments;
    result = isEmpty(patientDocuments?.filter((document) => document.acceptedTermsOfUse === false));
  }
  return result;
}

export function getPrimaryProvider(state: ApplicationState, source: any) {
  const providers = state.olb?.providerData?.providerDocuments;
  const primaryResourceName = source.scheduledEventsProposed?.resourceEvents?.[0].resourceName;
  return _getProvider(providers, primaryResourceName);
}

export function getSecondaryProvider(state: ApplicationState, source: any) {
  let result;
  const providers = state.olb?.providerData?.providerDocuments;
  const resourceEvents = source.scheduledEventsProposed?.resourceEvents;
  if (!isNil(resourceEvents) && resourceEvents.length > 1) {
    const secondary = resourceEvents[1];
    if (!isNil(secondary)) {
      result = _getProvider(providers, secondary.resourceName);
    }
  }
  return result;
}

function _getProvider(providers, resourceName) {
  if (!isNil(providers)) {
    return providers.find((provider) => resourceName === provider.id);
  }
}
