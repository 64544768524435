import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import React from "react";

export function CookiesDisabled(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid container direction={"column"} spacing={2} padding={2} alignItems={"center"}>
      <Grid item>
        <Typography variant={"h4"}>{t("browser.cookies.disabled.text")}</Typography>
      </Grid>
      <Grid item>
        <Typography>{t("browser.cookies.disabled.subtext")}</Typography>
      </Grid>
    </Grid>
  );
}
