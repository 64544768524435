import React from "react";
import { postalCodeOnSameLine, swapAddressLines } from "../utilities/PracticeInfoAware";
import { isEmpty } from "lodash-es";

export function PracticeAddress({ practiceDocument }: { practiceDocument: any }): React.JSX.Element {
  const showSwappedAddressLines = () => {
    return (
      <>
        {!isEmpty(practiceDocument?.address1) && (
          <>
            {practiceDocument?.address1}
            <br />
          </>
        )}
        {!isEmpty(practiceDocument?.postcode) && (
          <>
            {" "}
            {practiceDocument?.postcode}
            <br />
          </>
        )}
        {!isEmpty(practiceDocument?.address2) && (
          <>
            {practiceDocument?.address2}
            <br />
          </>
        )}
      </>
    );
  };
  const showPostalCodeOnSameLine = () => {
    return (
      <>
        {!isEmpty(practiceDocument?.address1) && (
          <>
            {practiceDocument?.address1}
            <br />
          </>
        )}
        {!isEmpty(practiceDocument?.address2) &&
          isEmpty(practiceDocument?.address3) &&
          (!isEmpty(practiceDocument?.postcode) ? (
            <>
              {" "}
              {practiceDocument?.postcode} {practiceDocument?.address2}
              <br />
            </>
          ) : (
            <>
              {" "}
              {practiceDocument?.address2}
              <br />
            </>
          ))}
        {!isEmpty(practiceDocument?.address3) &&
          !isEmpty(practiceDocument?.postcode) &&
          (!isEmpty(practiceDocument?.postcode) ? (
            <>
              {" "}
              {practiceDocument?.postcode} {practiceDocument?.address3}
              <br />
            </>
          ) : (
            <>
              {" "}
              {practiceDocument?.address3}
              <br />
            </>
          ))}
      </>
    );
  };
  const showNormalAddress = () => {
    return (
      <>
        {practiceDocument?.address1 && (
          <>
            {practiceDocument?.address1}
            <br />
          </>
        )}

        {practiceDocument?.address2 && (
          <>
            {practiceDocument?.address2}
            <br />
          </>
        )}
        {practiceDocument?.address3 && (
          <>
            {practiceDocument?.address3}
            <br />
          </>
        )}
        {practiceDocument?.postcode && (
          <>
            {" "}
            {practiceDocument?.postcode}
            <br />
          </>
        )}
      </>
    );
  };

  return (
    <address data-testid={"t-practice-information-address"}>
      {swapAddressLines() ? showSwappedAddressLines() : postalCodeOnSameLine() ? showPostalCodeOnSameLine() : showNormalAddress()}
    </address>
  );
}
