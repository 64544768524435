import { Grid } from "@mui/material";
import { formatDay, formatMonth, formatTime } from "../../../common/utilities/GeneralHelpers";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";

export const AppointmentTime = ({ startTime }: { startTime: Date }) => {
  const { t } = useTranslation();
  const timezone: string = useSelector((state: ApplicationState) => state.session.timezone);

  return (
    <Grid item className="appointmentTime pull-left" data-testid={"t-appointmentsummary-time"}>
      <div className="date">
        <span className="mon">{formatMonth(timezone, startTime)}</span>
        <span className="day">{formatDay(timezone, startTime)}</span>
        <span className="time">{formatTime(t("booking.weekdayTime.format"), timezone, startTime)}</span>
      </div>
    </Grid>
  );
};
