export const PatientTypes = {
  NEW_PATIENT: "NewPatient",
  EXISTING_PATIENT: "ExistingPatient",
};

export const PayorTypes = {
  NHS_PAYOR: "NHS",
  PRIVATE_PAYOR: "Private",
  MEDICAL_CARD_PAYOR: "MedicalCard",
};

export type InsuranceType = "NHS" | "Private";
