import { debounce, isNil } from "lodash-es";
import { TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { TEXTFIELD_DEBOUNCE_WAIT } from "../../../common/constants/HelperConstants";

export function Email({ error }: { error: string }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const patientEmailAddress = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.emailaddress);
  const [emailAddress, setEmailAddress] = useState(patientEmailAddress || "");
  const [hasBeenTouched, setBeenTouched] = useState(false);
  const debouncedOnChanged = useMemo(() => debounce(onChanged, TEXTFIELD_DEBOUNCE_WAIT), []);
  const hasError = !isNil(error) && hasBeenTouched;

  function onChangedInternal(event) {
    setEmailAddress(event.target.value);
    debouncedOnChanged(event.target.value);
  }

  function onChanged(value) {
    dispatch(updateDocument(DocumentConstants.PATIENT, { emailaddress: value }));
  }

  function onBlur() {
    if (!hasBeenTouched) {
      dispatch(updateDocument(DocumentConstants.PATIENT, { emailaddress: emailAddress }));
      setBeenTouched(true);
    }
  }

  return (
    <TextField
      type={"email"}
      name={"email"}
      autoComplete={"email"}
      value={emailAddress}
      inputProps={{ maxLength: 49 }}
      onChange={onChangedInternal}
      onBlur={onBlur}
      required
      error={hasError}
      label={t("booking.patientInformation.contactInformation.email.label")}
      data-testid={"t-patient-email"}
    />
  );
}
