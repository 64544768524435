import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationState } from "../state/model/ApplicationState";
import { isEmpty } from "lodash-es";
import "../../vendor/js/cookieconsent.min.js";
import "../../vendor/js/cookieconsent-samesite.js";
import "../../vendor/css/cookieconsent.min.css";

export const useCookieConsent = () => {
  const googleAnalyticsEnable = useSelector((state: ApplicationState) => !isEmpty(state.common?.analyticsData?.providers));
  const { t } = useTranslation();

  useEffect(() => {
    if ((window as any).ThreePointData.otCookieConsentId) {
      const cookieScript = document.createElement("script");
      cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      cookieScript.type = "text/javascript";
      cookieScript.setAttribute("data-domain-script", (window as any).ThreePointData.otCookieConsentId);
      document.head.append(cookieScript);
      const cookieBanner = document.createElement("script");
      cookieBanner.src = "https://cdn.cookielaw.org/scripttemplates/6.22.0/otBannerSdk.js";
      cookieBanner.type = "text/javascript";
      document.head.append(cookieBanner);
      return () => {
        document.head.removeChild(cookieScript);
        document.head.removeChild(cookieBanner);
      };
    }
  }, []);

  useEffect(() => {
    if (!(window as any).ThreePointData.otCookieConsentId) {
      const message = googleAnalyticsEnable ? t("cookie.consent.message.text") : t("cookie.consent.message.analytics.text");
      const linkText = googleAnalyticsEnable ? { string: null } : t("cookie.consent.message.analytics.link.text");
      const linkHref = googleAnalyticsEnable ? null : "https://softwareofexcellence.co.uk/dental-hub-cookie-policy";
      const dismiss = t("cookie.consent.button.label");

      _initializeCookieConsent(message, dismiss, linkText, linkHref);
    }
  }, [googleAnalyticsEnable, t]);
};

function _initializeCookieConsent(message, dismiss, linkText, linkHref) {
  (window as any).cookieconsent.initialise({
    palette: {
      popup: {
        background: "#252e39",
      },
      button: {
        background: "#14a7d0",
      },
    },
    position: "top",
    theme: "classic",
    content: {
      message: message,
      dismiss: dismiss,
      link: linkText,
      href: linkHref,
      target: "_blank",
    },
    cookie: {
      secure: true,
      sameSite: "None",
    },
  });
}
