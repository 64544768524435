import React from "react";
import { Synchronized } from "../common/Synchronized";
import { BookingCard } from "../common/BookingCard";

export function Step43(): React.JSX.Element {
  return (
    <BookingCard>
      <Synchronized />
    </BookingCard>
  );
}
