import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper } from "@mui/material";
import { mailToEmail, websiteLink } from "../../common/utilities/PracticeInfoAware";
import { PracticeLogo } from "./PracticeLogo";
import { formatPhone } from "../../common/utilities/GeneralHelpers";
import { faChevronDown, faChevronUp, faEnvelope, faGlobe, faPhone, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap } from "./GoogleMap";
import { MarkdownToHtml } from "./MarkdownToHtml";
import { PracticeAddress } from "../../common/components/PracticeAddress";

export function PracticeInformationSummary({
  termsOfUseDocument,
  practiceDocument,
  logoUrl,
  showMap,
}: {
  termsOfUseDocument: any;
  practiceDocument: any;
  logoUrl: string;
  showMap: boolean;
}): React.JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const getPracticeLogo = () => {
    if (logoUrl) {
      return <PracticeLogo logoUrl={logoUrl} />;
    }
  };

  const getWebsite = () => {
    let result: React.JSX.Element = <></>;
    if (practiceDocument?.name) {
      if (practiceDocument?.website && practiceDocument?.name) {
        result = (
          <>
            <a href={websiteLink(practiceDocument?.website)} target="_blank" rel="noreferrer" data-testid={"t-practice-information-practice-name"}>
              <h2>{practiceDocument?.name}</h2>
            </a>
            <br />
          </>
        );
      } else {
        result = (
          <>
            <h1 data-testid={"t-practice-information-practice-name"}>{practiceDocument?.name}</h1>
            <br />
          </>
        );
      }
    }

    return result;
  };

  const getPracticePhone = (phone: string) => {
    if (phone) {
      return (
        <>
          <FontAwesomeIcon icon={faPhone} />
          &nbsp;&nbsp;
          <span className="practiceInfo-font" data-testid={"t-practice-information-phone"}>
            {formatPhone(phone)}
          </span>
        </>
      );
    }
  };

  const getPracticeFax = (fax: string) => {
    if (fax) {
      return (
        <Grid container item spacing={12}>
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faPrint} />
            &nbsp;&nbsp;
            <span className="practiceInfo-font" data-testid={"t-practice-information-fax"}>
              {formatPhone(fax)}
            </span>
          </Grid>
        </Grid>
      );
    }
  };

  const getMap = (showMap) => {
    if (showMap) {
      return (
        <Grid item xs={12}>
          <Box className={"flexible-container"}>
            <GoogleMap
              address1={practiceDocument.address1}
              address2={practiceDocument.address2}
              address3={practiceDocument.address3}
              postcode={practiceDocument.postcode}
            ></GoogleMap>
          </Box>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const getTermsOfuse = (termsOfUseDocument: any) => {
    function toggleAccordion() {
      setExpanded(!expanded);
    }

    if (termsOfUseDocument) {
      return (
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <p style={{ fontSize: ".7em" }}>
              &copy; {new Date().getFullYear()} Global Payments Inc. Global Payments Integrated is a service mark of Global Payments Inc. ® All rights reserved.
            </p>
          </Grid>
          <Grid item xs={12}>
            <Accordion expanded={expanded} onClick={toggleAccordion}>
              <AccordionSummary className="terms-of-use-footer">
                {termsOfUseDocument.title}&nbsp;
                <FontAwesomeIcon icon={!expanded ? faChevronDown : faChevronUp} />
              </AccordionSummary>
              <AccordionDetails className={"gdpr-accordion-details"}>
                <MarkdownToHtml markdown={termsOfUseDocument.content} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid style={{ textAlign: "center" }}>
          <p style={{ fontSize: ".7em" }}>
            &copy; {new Date().getFullYear()} Global Payments Inc. Global Payments Integrated is a service mark of Global Payments Inc.&reg; All rights reserved.
            <br />
            <a href="https://www.globalpaymentsintegrated.com/en-us/terms-of-service" style={{ fontSize: ".9em" }}>
              Terms and Conditions
            </a>{" "}
            |{" "}
            <a style={{ fontSize: ".9em" }} href="https://www.globalpaymentsintegrated.com/en-us/privacy-policy">
              Privacy Notice
            </a>
          </p>
        </Grid>
      );
    }
  };

  return (
    <Paper elevation={3} style={{ padding: 20 }} data-testid={"t-practice-information"}>
      <Grid container>
        <Grid item xs={12}>
          {getPracticeLogo()}
        </Grid>
        <Grid item xs={12}>
          {getWebsite()}
        </Grid>

        {practiceDocument?.email && (
          <Grid item xs={1}>
            <a href={mailToEmail(practiceDocument?.email)} data-testid={"t-practice-information-email"}>
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </Grid>
        )}

        {practiceDocument?.website && (
          <Grid item xs={1}>
            <a target="_blank" href={websiteLink(practiceDocument?.website)} rel="noreferrer" data-testid={"t-practice-information-website"}>
              <FontAwesomeIcon icon={faGlobe} />
            </a>
          </Grid>
        )}

        <Grid item xs={12}>
          {getPracticePhone(practiceDocument?.phone)}
        </Grid>
        <Grid item xs={12}>
          {getPracticeFax(practiceDocument?.fax)}
        </Grid>
        <Grid item xs={12}>
          <PracticeAddress practiceDocument={practiceDocument} />
        </Grid>
        <Grid item xs={12} className="practice-blurb" data-testid={"t-practice-information-blurb"}>
          {practiceDocument?.olbwebsite?.practiceBlurb}
        </Grid>
        {getMap(showMap)}
        {getTermsOfuse(termsOfUseDocument)}
      </Grid>
    </Paper>
  );
}
