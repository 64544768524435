import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { useTranslation } from "react-i18next";
import { AppointmentType } from "../../state/OlbConstants";
import { PayorTypes } from "../../constants/OnlineBookingConstants";

export const AppointmentSummaryInformation = ({
  primaryProvider,
  secondaryProvider,
  duration,
  combinedAppointment,
}: {
  primaryProvider: any;
  secondaryProvider: any;
  duration: number;
  combinedAppointment: boolean;
}) => {
  const { t } = useTranslation();
  const uiComponentRoot = useSelector((state: ApplicationState) => state.common?.config?.uiComponentRoot);
  const showProviderSuffix: boolean | undefined = useSelector((state: ApplicationState) => state.common?.config?.showProviderSuffix);
  const showDurationOnAppointment: boolean | undefined = useSelector((state: ApplicationState) => state.common?.config?.showDurationOnAppointment);
  const patientType: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.appointmentType);
  const selectedReasonId: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.reason);
  const payorType: string | undefined = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument?.insuranceType);
  const reasons = useSelector((state: ApplicationState) => state.olb?.reasons);
  const primaryProviderName: string | undefined = useMemo(() => primaryProvider?.name, [primaryProvider]);
  const secondaryProviderName: string | undefined = useMemo(() => secondaryProvider?.name, [secondaryProvider]);
  const primaryProviderTitle: string | undefined = useMemo(
    () =>
      ` (${primaryProvider?.professionalTitle ? primaryProvider.professionalTitle + " - " : ""}${
        primaryProvider?.qualifications ? primaryProvider.qualifications : ""
      })`,
    [primaryProvider],
  );
  const secondaryProviderTitle: string | undefined = useMemo(
    () =>
      ` (${secondaryProvider?.professionalTitle ? secondaryProvider.professionalTitle + " - " : ""}${
        secondaryProvider?.qualifications ? secondaryProvider.qualifications : ""
      })`,
    [secondaryProvider],
  );

  const selectedReason = reasons?.find((v) => v.externalId === selectedReasonId);

  let patientTypeText = "";
  switch (patientType) {
    case AppointmentType.NEW_PATIENT:
      patientTypeText = t("booking.newPatient.title");
      break;
    case AppointmentType.PLANNED:
    case AppointmentType.RECALL:
    case AppointmentType.EXISTING_PATIENT:
      patientTypeText = t("booking.existingPatient.title");
      break;
  }
  let payorTypeText;
  switch (payorType) {
    case PayorTypes.MEDICAL_CARD_PAYOR:
      payorTypeText = t("booking.summary.payortype.medicalcard.text");
      break;
    case PayorTypes.NHS_PAYOR:
    case PayorTypes.PRIVATE_PAYOR:
      payorTypeText = payorType;
      break;
  }
  return (
    <Grid container className="appointment-summary-info" sx={{ width: "auto" }} data-testid={"t-appointmentsummary-summarytext"}>
      <div>
        <Box sx={{ fontWeight: "bold" }} component={"span"}>
          {patientTypeText}
        </Box>{" "}
        {t(`booking.summary.patienttype.text.${uiComponentRoot}`)}
        {" - "}
        {payorTypeText && (
          <>
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {payorTypeText}
            </Box>
            {" - "}
          </>
        )}
        <Box sx={{ fontWeight: "bold" }} component={"span"}>
          {selectedReason?.displayText || ""}
        </Box>{" "}
        {combinedAppointment ? (
          <>
            {t("booking.summary.review.combined.appointment.with.text")} <b>{primaryProviderName}</b>
            {showProviderSuffix && <>{primaryProviderTitle}</>}, <b>{secondaryProviderName}</b> {showProviderSuffix && <>{secondaryProviderTitle}</>}
          </>
        ) : (
          <>
            {t("booking.summary.review.appointment.with.text")} <b>{primaryProviderName}</b>
            {showProviderSuffix && <>{primaryProviderTitle + " "}</>}
          </>
        )}
        {showDurationOnAppointment && duration && (
          <>
            {t("booking.summary.review.before.apptDuration.text")}
            <b> {duration} </b>
            {t("booking.summary.review.apptDuration.text")}
          </>
        )}
      </div>
    </Grid>
  );
};
