import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/max";

export function countryForE164Number(phoneNumber: string | undefined) {
  try {
    const cleanNumber = cleanPhone(phoneNumber);
    const number = parsePhoneNumber(cleanNumber);
    return number?.country;
  } catch (e) {
    return undefined;
  }
}

export function isValidNumber(phoneNumber: string, country: string) {
  try {
    const cleanNumber = cleanPhone(phoneNumber);
    return isValidPhoneNumber(cleanNumber, country as CountryCode);
  } catch (e) {
    return false;
  }
}

export function formatE164(country: string, phoneNumber: string) {
  try {
    const cleanNumber = cleanPhone(phoneNumber);
    const number = parsePhoneNumber(cleanNumber, country as CountryCode);
    return number?.number;
  } catch (e) {
    return undefined;
  }
}

export function formatLocal(phoneNumber: string, country?: CountryCode | string) {
  try {
    const cleanNumber = cleanPhone(phoneNumber);
    const number = parsePhoneNumber(cleanNumber, country as CountryCode);
    return number?.formatNational();
  } catch (e) {
    return undefined;
  }
}

/**
 Remove any non-numeric characters from the phone number but leave any plus sign at the beginning
 phone (String) phone number to clean
 **/
function cleanPhone(phone) {
  phone = phone.replace(/[^\d+]/g, "");
  if (phone.charAt(0) == "+") {
    phone = "+" + phone.replace(/\D/g, "");
  } else {
    phone = phone.replace(/\D/g, "");
  }
  return phone;
}
