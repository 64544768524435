import { Button, Divider, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { cancelVerification, sendVerificationCode } from "../../state/SoAdminActions";
import { reload } from "../../../common/state/CommonActions";
import { useErrorHandler } from "react-error-boundary";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";

export function PickChannel({
  verificationChannel,
  onIsSaving,
  isSaving = false,
}: {
  verificationChannel: any;
  onIsSaving: any;
  isSaving: boolean;
}): React.JSX.Element {
  const dispatch = useAppDispatch();
  const handleError = useErrorHandler();
  const { t } = useTranslation();

  const _internalHandle = async (channel) => {
    onIsSaving(true);
    await dispatch(sendVerificationCode(channel)).catch(async (error) => {
      if (401 === error.response?.status) {
        await dispatch(reload("5")).catch(() => {
          handleError(error);
        });
      }
      onIsSaving(false);
    });
    onIsSaving(false);
  };

  const handleSendVerificationToEmail = () => {
    _internalHandle("email");
  };

  const handleSendVerificationToSms = () => {
    _internalHandle("sms");
  };

  const handleVerificationCancel = async () => {
    onIsSaving(true);
    dispatch(cancelVerification()).catch((error) => {
      handleError(error);
    });
    onIsSaving(false);
  };

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item>{t("mfa.selectChannel")}</Grid>
      </Grid>
      <Grid container direction={"column"} spacing={2} padding={2}>
        {verificationChannel.maskedEmail && (
          <Grid item>
            <Button variant={"contained"} fullWidth onClick={handleSendVerificationToEmail} disabled={isSaving} data-testid={"t-mfa-send-email"}>
              {verificationChannel.maskedEmail}
            </Button>
          </Grid>
        )}
        {verificationChannel.maskedSms && (
          <Grid item>
            <Button variant={"contained"} fullWidth onClick={handleSendVerificationToSms} disabled={isSaving} data-testid={"t-mfa-send-sms"}>
              {verificationChannel.maskedSms}
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid container spacing={2} padding={2}>
        <Grid item>
          <Button variant={"outlined"} onClick={handleVerificationCancel} disabled={isSaving} data-testid={"t-mfa-button-cancel"}>
            {t("mfa.cancel")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
