import React from "react";
import { Box, Button, Grid, Paper } from "@mui/material";
import { mailToEmail, websiteLink } from "../../common/utilities/PracticeInfoAware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { PracticeLogo } from "../../onlinebooking/components/PracticeLogo";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../common/state/model/ApplicationState";
import { formatPhone } from "../../common/utilities/GeneralHelpers";
import { GoogleMap } from "../../onlinebooking/components/GoogleMap";
import { MinisiteCard } from "./MinisiteCard";
import { useTranslation } from "react-i18next";
import { PracticeAddress } from "../../common/components/PracticeAddress";
import {MarkdownToHtml} from "../../onlinebooking/components/MarkdownToHtml";

export function PracticeInformation({ practiceDocument }: { practiceDocument: any }): React.JSX.Element {
  const { t } = useTranslation();
  const logoUrl = useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.logoUrl);
  const urlEncodedName = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceJsonDocument.value.name);
  const practiceId = useSelector((state: ApplicationState) => state.common?.practiceData?.practiceJsonDocument.name);
  const newPatientBase = useSelector((state: ApplicationState) => state.common?.config?.newPatientBase);

  function doBooking() {
    const bookingUrl = `${window.location.origin}/${newPatientBase}${urlEncodedName.toString()}?pid=${practiceId.toString()}`;
    window.location.assign(bookingUrl);
  }

  const getPracticeLogo = () => {
    if (logoUrl) {
      return <PracticeLogo logoUrl={logoUrl} />;
    }
  };
  const getWebsite = () => {
    let result: React.JSX.Element = <></>;
    if (practiceDocument?.name) {
      if (practiceDocument?.website && practiceDocument?.name) {
        result = (
          <>
            <a href={websiteLink(practiceDocument?.website)} target="_blank" rel="noreferrer">
              <h2>{practiceDocument?.name}</h2>
            </a>
            <br />
          </>
        );
      } else {
        result = (
          <>
            <h1>{practiceDocument?.name}</h1>
            <br />
          </>
        );
      }
    }

    return result;
  };
  const getPracticePhone = (phone: string) => {
    if (phone) {
      return (
        <Grid item xs={12}>
          <FontAwesomeIcon icon={faPhone} />
          &nbsp;&nbsp;
          <span className="practiceInfo-font">{formatPhone(phone)}</span>
        </Grid>
      );
    }
  };

  const getMap = () => {
    if (practiceDocument) {
      return (
        <GoogleMap
          address1={practiceDocument.address1}
          address2={practiceDocument.address2}
          address3={practiceDocument.address3}
          postcode={practiceDocument.postcode}
        ></GoogleMap>
      );
    } else {
      return <></>;
    }
  };

  return (
    <MinisiteCard>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} style={{ padding: 20 }} sx={{ bgcolor: "#f5f5f5", margin: 2, boxShadow: 0, border: "1px solid #e3e3e3" }}>
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  {getPracticeLogo()}
                  {getWebsite()}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} xl={4}>
                <PracticeAddress practiceDocument={practiceDocument} />
              </Grid>
              <Grid container item xs={12} lg={6} xl={4}>
                <Grid item xs={12} sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {"email" && (
                    <a href={mailToEmail(practiceDocument?.email)}>
                      <FontAwesomeIcon icon={faEnvelope} /> {practiceDocument?.email}
                    </a>
                  )}
                </Grid>
                <Grid item>
                  {"website" && (
                    <a target="_blank" href={websiteLink(practiceDocument?.website)} rel="noreferrer">
                      <FontAwesomeIcon icon={faGlobe} /> Website
                    </a>
                  )}
                </Grid>
                {getPracticePhone(practiceDocument?.phone)}
              </Grid>
              <Grid item xs={12} xl={4}>
                <Button variant="contained" size={"large"} onClick={doBooking} data-testid={"t-bookappointment-button"}>
                  {t("portal.booknow.button.label")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <MarkdownToHtml markdown={practiceDocument?.olbwebsite?.practiceBlurb} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={"flexible-container practice-information"}>{getMap()}</Box>
        </Grid>
      </Grid>
    </MinisiteCard>
  );
}
