import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { ApplicationState } from "../../../common/state/model/ApplicationState";
import { updateDocument } from "../../../common/state/CommonActions";
import { DocumentConstants } from "../../../common/constants/DocumentConstants";
import { GivenName } from "../patientinformation/GivenName";
import { FamilyName } from "../patientinformation/FamilyName";
import { DateOfBirth } from "../patientinformation/DateOfBirth";
import { Sex } from "../patientinformation/Sex";
import { PhoneNumber } from "../patientinformation/PhoneNumber";
import { Email } from "../patientinformation/Email";
import { Countries } from "../../constants/Countries";

const countries = _.sortBy(Countries, ["code"]);

export function PatientInformationTpd({ source }: { source: any }): React.JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const config = useSelector((state: ApplicationState) => state.common?.config);

  const displayCallback =
    useSelector((state: ApplicationState) => state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings.displayCallback) || false;
  const callBack = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument.callBack);
  const question3 = useSelector((state: ApplicationState) => state.olb?.appointmentData?.appointmentDocument.question3);
  const mobileNumberCountry = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.mobileNumberCountry);
  const mobileNumber = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.mobilenumber);
  const patientErrors = useSelector((state: ApplicationState) => state.olb?.patientInfoData?.patientDocument?.errors);

  const medicalConsiderationsLabel = t("booking.patientInformation.medicalConsiderations.label");

  function onCallBackChanged(event) {
    dispatch(updateDocument(DocumentConstants.APPOINTMENT, { callBack: event.target.value }));
  }

  function onAdditionalInformationChanged(event) {
    dispatch(updateDocument(DocumentConstants.APPOINTMENT, { question3: event.target.value }));
  }

  return (
    <>
      <Box sx={{ "& .MuiFormControl-root": { m: 2, width: 325 } }}>
        <Grid container>
          <Grid item xs={12}>
            <GivenName sourceId={source.sourceId} error={patientErrors?.firstname} />
            <FamilyName sourceId={source.sourceId} error={patientErrors?.lastname} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <DateOfBirth />
            <Sex error={patientErrors?.iso5218Sex} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <PhoneNumber countries={countries} selectedCountry={mobileNumberCountry} phoneNumber={mobileNumber} error={patientErrors?.mobilenumber} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Email error={patientErrors?.emailaddress} />
          </Grid>
        </Grid>
        {displayCallback && (
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{ ml: 1 }}
                label={t("booking.patientInformation.contactInformation.callBack.label")}
                control={<Checkbox name={"callBack"} checked={callBack} onChange={onCallBackChanged} />}
              />
            </Grid>
          </Grid>
        )}
        {config?.allowBookingNote && (
          <Grid item xs={8}>
            <TextField
              multiline
              rows={3}
              label={medicalConsiderationsLabel}
              inputProps={{ maxLength: 500 }}
              value={question3}
              onChange={onAdditionalInformationChanged}
            />
          </Grid>
        )}
      </Box>
    </>
  );
}
