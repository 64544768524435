import { ApplicationState } from "../../common/state/model/ApplicationState";
import { findAction } from "../../common/utilities/FindAction";
import { find, isEmpty, isNil, isString } from "lodash-es";
import { MiniSiteConstants } from "../constants/MiniSiteConstants";
import { isSameDay, parseISO } from "date-fns";
import { maskString } from "../utilities/MaskString";
import { UTCDate } from "@date-fns/utc";

export function bookingReasons(state: ApplicationState): string {
  const bookingReasons = state.common?.practiceData?.practiceDocument?.olbwebsite?.bookingReasons;
  return !isEmpty(bookingReasons) ? bookingReasons.join(", ") : "None available";
}

export function appointmentSettingsHasEditPermissions(state: ApplicationState): boolean {
  const appointmentSettingsDocumentCard = state.common?.appointmentSettingsData?.appointmentSettingsDocumentCard;
  let result = false;
  if (appointmentSettingsDocumentCard) {
    const action = findAction(appointmentSettingsDocumentCard, "Store");
    result = !isNil(action);
  }
  return result;
}

export function determineOnlineBookingStateValue(state: ApplicationState): string {
  let result = "disable";
  if (determineIsOnlineBookingEnabled(state)) {
    result = "enable";
  } else if (determineIsOnlineBookingUnavailableUntilFurtherNotice(state)) {
    result = "unavailableUntilFurtherNotice";
  } else if (determineIsOnlineBookingUnavailableUntil(state)) {
    result = "unavailableUntil";
  } else if (determineIsOnlineBookingDisabled(state)) {
    result = "disable";
  }
  return result;
}

export function determineIsOnlineBookingEnabled(state: ApplicationState): boolean {
  const apiKey = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.apiKey;
  return (
    !isEmpty(apiKey) &&
    !determineIsOnlineBookingDisabled(state) &&
    !determineIsOnlineBookingUnavailableUntilFurtherNotice(state) &&
    !determineIsOnlineBookingUnavailableUntil(state)
  );
}

export function determineIsOnlineBookingDisabled(state: ApplicationState): boolean {
  const apiKey = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.apiKey;
  return isEmpty(apiKey);
}

export function determineIsOnlineBookingUnavailableUntilFurtherNotice(state: ApplicationState): boolean {
  const openDate = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate;
  const furtherNoticeDate = new Date(Date.UTC(2050, 0, 1));
  let openDateParsed = openDate;
  if (isString(openDate)) {
    openDateParsed = new Date(new UTCDate(openDate).getDate());
  }
  const isSame = isSameDay(furtherNoticeDate, openDateParsed);
  return !isNil(openDate) && isSame;
}

export function determineIsOnlineBookingUnavailableUntil(state: ApplicationState): boolean {
  const openDate = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.openDate;
  return !isNil(openDate) && !determineIsOnlineBookingUnavailableUntilFurtherNotice(state);
}

export function determineStackIncrement(state: ApplicationState): string {
  let stackingStrategy = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings.stackingStrategy;
  const stackIncrement = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings.stackIncrement;
  if (isNil(stackingStrategy)) {
    stackingStrategy = stackIncrement === 30 ? "THIRTY_MINUTE" : "";
  }
  return stackingStrategy;
}

export function determineIsStackingStrategyDisabled(state: ApplicationState): boolean {
  const olbwebsite = state.common?.practiceData?.practiceDocument?.olbwebsite;
  return !(!isNil(olbwebsite) && olbwebsite.apptStacking && undefined === olbwebsite.stackingStrategy);
}

export function determineNhsFee(state: ApplicationState) {
  const nhsFee = state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings.nhsFee;
  const nhsFeeDocuments = state.olb?.nhsFeeData?.nhsFeeDocuments;
  if (isEmpty("nhsFeeDocuments") && isEmpty(nhsFee)) {
    return null;
  }
  return find(nhsFeeDocuments, function (doc) {
    return doc.id == nhsFee;
  });
}

export function determineInternalApiKey(state: ApplicationState) {
  return state.common?.appointmentSettingsData?.appointmentSettingsDocument?.additionalSettings?.apiKey;
}
