import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";

export function ErrorFallback({ error }): React.JSX.Element {
  const { t } = useTranslation();
  const statusCode = !isNil(error.response) ? error.response.status : "Unknown";

  const reload = () => {
    window.location.reload();
  };

  // const isDev = process.env.NODE_ENV === "development";
  return (
    <Dialog open={true}>
      <DialogTitle>{t("error.header.text")}</DialogTitle>
      <DialogContent>
        <p>
          <a href="#" onClick={reload}>
            {t("error.reload.link.text")}
          </a>
          &nbsp;
          {t("error.tryAgain.text")}
        </p>
        <div>
          <small>{statusCode}</small>
        </div>
      </DialogContent>
    </Dialog>
  );
}
